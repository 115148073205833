import {
  retrieveBodyTypes,
  retrieveCylinders,
  retrieveMakes,
  retrieveModels,
  retrieveTransmissions,
  retrieveVehiclesWithBodyStyle,
  retrieveVehicleId,
} from '../apis/vehicleSearchData';
import { VEHICLE_SEARCH_PAGE } from '../constants/urlConstants';

export const vehicleSearchInfoInitialState = {
  searchResults: {
    year: '',
    make: '',
    model: '',
    transmission: '',
    cylinder: '',
    bodyType: '',
    vehicleResult: {},
    sameVehicleAsPreviousSelectionStatus: false,
  },
  fetchedData: {
    year: '',
    makes: [],
    models: [],
    transmissions: [],
    cylinders: [],
    bodyTypes: [],
    details: [],
  },
  cachedData: {
    year: '',
    makes: [],
    models: [],
    transmissions: [],
    cylinders: [],
    bodyTypes: [],
    details: [],
  },
};

const handleApiError = (error, dispatch) => {
  dispatch.quoteDetails.handleTechError({
    error,
    prevLocation: VEHICLE_SEARCH_PAGE,
  });
};

export const vehicleSearchInfo = {
  state: vehicleSearchInfoInitialState,
  reducers: {
    setVehicleSearchInfo(state, payload) {
      return { ...state, ...payload };
    },
    setSearchYearData(state, payload) {
      return {
        ...state,
        searchResults: { ...state.searchResults, year: payload },
      };
    },
    setFetchedData(state, payload) {
      return {
        ...state,
        fetchedData: { ...state.fetchedData, ...payload },
      };
    },
    setFetchedYearData(state, payload) {
      return {
        ...state,
        fetchedData: { ...state.fetchedData, year: payload },
      };
    },
    setFetchedMakesData(state, payload) {
      return {
        ...state,
        fetchedData: { ...state.fetchedData, makes: payload },
      };
    },
    setSearchMakeData(state, payload) {
      return {
        ...state,
        searchResults: { ...state.searchResults, make: payload },
      };
    },
    setSearchModelData(state, payload) {
      return {
        ...state,
        searchResults: { ...state.searchResults, model: payload },
      };
    },
    setFetchedModelsData(state, payload) {
      return {
        ...state,
        fetchedData: { ...state.fetchedData, models: payload },
      };
    },
    setSearchTransmissionData(state, payload) {
      return {
        ...state,
        searchResults: { ...state.searchResults, transmission: payload },
      };
    },
    setFetchedTransmissionsData(state, payload) {
      return {
        ...state,
        fetchedData: { ...state.fetchedData, transmissions: payload },
      };
    },
    setSearchCylinderData(state, payload) {
      return {
        ...state,
        searchResults: { ...state.searchResults, cylinder: payload },
      };
    },
    setFetchedCylindersData(state, payload) {
      return {
        ...state,
        fetchedData: { ...state.fetchedData, cylinders: payload },
      };
    },
    setSearchBodyTypeData(state, payload) {
      return {
        ...state,
        searchResults: { ...state.searchResults, bodyType: payload },
      };
    },
    setFetchedBodyTypesData(state, payload) {
      return {
        ...state,
        fetchedData: { ...state.fetchedData, bodyTypes: payload },
      };
    },
    setFetchedDetailsData(state, payload) {
      return {
        ...state,
        fetchedData: { ...state.fetchedData, details: payload },
      };
    },
    setSearchVehicleResultData(state, payload) {
      return {
        ...state,
        searchResults: { ...state.searchResults, vehicleResult: payload },
      };
    },
    setSameVehicleAsPreviousSelectionStatus(state, payload) {
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          sameVehicleAsPreviousSelectionStatus: payload,
        },
      };
    },
    savedCachedData(state) {
      return {
        ...state,
        cachedData: { ...state.fetchedData },
      };
    },
    resetFetchedData(state) {
      return {
        ...state,
        fetchedData: { ...state.cachedData },
      };
    },
    setSearchResultsData(state, payload) {
      return {
        ...state,
        searchResults: { ...state.searchResults, ...payload },
      };
    },
  },
  effects: (dispatch) => ({
    async makeCallToMakes(payload) {
      let response;
      try {
        response = await retrieveMakes(payload.year);
        if (response?.attributes?.makes) {
          dispatch.vehicleSearchInfo.setFetchedMakesData(
            response.attributes.makes,
          );
        }
        return response;
      } catch (error) {
        handleApiError(error, dispatch);
      }
      return response;
    },
    async makeCallToModels(payload) {
      let response;
      try {
        response = await retrieveModels(payload.year, payload.make);
        if (response?.attributes?.models) {
          dispatch.vehicleSearchInfo.setFetchedModelsData(
            response.attributes.models,
          );
        }
      } catch (error) {
        handleApiError(error, dispatch);
      }
      return response;
    },
    async makeCallToTransmissions(payload) {
      let response;
      try {
        response = await retrieveTransmissions(
          payload.year,
          payload.make,
          payload.model,
        );
        if (response?.attributes?.transmissions) {
          dispatch.vehicleSearchInfo.setFetchedTransmissionsData(
            response.attributes.transmissions,
          );
        }
      } catch (error) {
        handleApiError(error, dispatch);
      }
      return response;
    },
    async makeCallToCylinders(payload) {
      let response;
      try {
        response = await retrieveCylinders(
          payload.year,
          payload.make,
          payload.model,
          payload.transmission,
        );
        if (response?.attributes?.cylinders) {
          dispatch.vehicleSearchInfo.setFetchedCylindersData(
            response.attributes.cylinders,
          );
        }
      } catch (error) {
        handleApiError(error, dispatch);
      }
      return response;
    },
    async makeCallToBodyTypes(payload) {
      let response;
      try {
        response = await retrieveBodyTypes(
          payload.year,
          payload.make,
          payload.model,
          payload.transmission,
          payload.cylinder,
        );
        if (response?.attributes?.bodyStyles) {
          dispatch.vehicleSearchInfo.setFetchedBodyTypesData(
            response.attributes.bodyStyles,
          );
        }
      } catch (error) {
        handleApiError(error, dispatch);
      }
      return response;
    },
    async makeCallToVehiclesWithBodyStyle(payload) {
      let response;
      try {
        response = await retrieveVehiclesWithBodyStyle(
          payload.year,
          payload.make,
          payload.model,
          payload.transmission,
          payload.cylinder,
          payload.bodyStyle,
        );
        if (response) {
          dispatch.vehicleSearchInfo.setFetchedDetailsData(
            response?.attributes?.vehicles,
          );
        }
      } catch (error) {
        handleApiError(error, dispatch);
      }
      return response;
    },
    async makeCallToVehicleId(payload) {
      let response;
      try {
        response = await retrieveVehicleId(payload);
        if (response) {
          const { vehicles } = response?.attributes;
          const [vehicleDetails] = vehicles;
          const {
            year,
            make,
            model,
            cylinders,
            transmissionIndicator,
            bodyStyle,
          } = vehicleDetails;
          dispatch.vehicleSearchInfo.setSearchResultsData({
            year,
            make,
            model,
            transmission: transmissionIndicator,
            cylinder: cylinders,
            bodyType: bodyStyle,
            vehicleResult: vehicleDetails,
          });
        }
      } catch (error) {
        handleApiError(error, dispatch);
      }
      return response;
    },
  }),
};
