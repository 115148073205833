import {
  REGISTRATION_NUMBER_PAGE,
  VEHICLE_IDENTIFICATION_PAGE,
  MAIN_DRIVER_EXTRA_DETAILS_PAGE,
  MAIN_DRIVER_SUSPENSIONS_PAGE,
  POLICY_COMPANY_DETAILS_PAGE,
  MARKETING_CONSENT_PAGE,
  CAR_FINANCE_PROVIDER,
  POLICYHOLDER_ADDRESS_PAGE,
  CONTACT_DETAILS_PAGE,
  COMMUNICATION_PREFERENCE_PAGE,
  CONFIRMATION_PAGE,
  ADDITIONAL_DRIVER_EXTRA_PAGES_ROUTE,
  SUNPAY_CALLBACK_PAGE,
  ADDITIONAL_DRIVER_CLAIMS_PAGES_ROUTE,
  ADDITIONAL_DRIVER_SUSPENSIONS_PAGES_ROUTE,
  MAIN_DRIVER_CLAIMS_PAGE,
} from '../constants/urlConstants';

const buyFlowPages = [
  REGISTRATION_NUMBER_PAGE,
  VEHICLE_IDENTIFICATION_PAGE,
  MAIN_DRIVER_EXTRA_DETAILS_PAGE,
  MAIN_DRIVER_SUSPENSIONS_PAGE,
  POLICY_COMPANY_DETAILS_PAGE,
  MARKETING_CONSENT_PAGE,
  CAR_FINANCE_PROVIDER,
  POLICYHOLDER_ADDRESS_PAGE,
  CONTACT_DETAILS_PAGE,
  COMMUNICATION_PREFERENCE_PAGE,
  CONFIRMATION_PAGE,
  ADDITIONAL_DRIVER_EXTRA_PAGES_ROUTE,
  SUNPAY_CALLBACK_PAGE,
  ADDITIONAL_DRIVER_CLAIMS_PAGES_ROUTE,
  ADDITIONAL_DRIVER_SUSPENSIONS_PAGES_ROUTE,
  MAIN_DRIVER_CLAIMS_PAGE,
];

export const isBuyFlowPage = (location) =>
  !!location && buyFlowPages.includes(location);
