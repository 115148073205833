export const enthusiastDriverInitialState = {
  skipEnthusiastDriverPage: 'false',
  isAttendsMotorEvents: '',
  isMotorEnthusiast: '',
  isVehicleMaintainer: '',
  isVintageOwner: '',
  isUnusedVehicleOwner: '',
};

export const enthusiastDriver = {
  state: enthusiastDriverInitialState,

  reducers: {
    setEnthusiastDriver(state, payload) {
      return { ...state, ...payload };
    },
    setSkipEnthusiastDriverPage(state, payload) {
      return { ...state, skipEnthusiastDriverPage: payload };
    },
    setIsAttendsMotorEvents(state, payload) {
      return { ...state, isAttendsMotorEvents: payload };
    },
    setIsMotorEnthusiast(state, payload) {
      return { ...state, isMotorEnthusiast: payload };
    },
    setIsVehicleMaintainer(state, payload) {
      return { ...state, isVehicleMaintainer: payload };
    },
    setIsVintageOwner(state, payload) {
      return { ...state, isVintageOwner: payload };
    },
    setIsUnusedVehicleOwner(state, payload) {
      return { ...state, isUnusedVehicleOwner: payload };
    },
  },
};
