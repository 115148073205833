import { buyQuoteMapper } from './buyQuoteMapper';
import createApiInstance from '../../utils/createApiInstance';

export const callBuyQuoteApi = async (state, responseId) => {
  const requestBody = buyQuoteMapper(state, responseId);
  const response = await createApiInstance({
    baseURL: `${process.env.REACT_APP_QUOTE_URL}/${state.quoteDetails.quoteId}${process.env.REACT_APP_BUY_QUOTE_API_URL}?selfServiceRegistration=true`,
    method: 'POST',
    requestBody,
  });
  return response;
};
