import {
  FlexCell,
  Flextable,
  GridRow,
} from 'suncorp-styleguide-react-components';
import PropTypes from 'prop-types';

const ListedDriversSection = ({ additionalDrivers }) => (
  <GridRow spacing="between" className="sg-u-marginTop sg-u-marginBottom">
    <Flextable>
      <FlexCell className="sg-u-noPadding">
        <div className="sg-Type--heading">Number of listed drivers</div>
        <div style={{ marginTop: '5px' }} className="sg-Type--muted">
          {additionalDrivers.length === 0
            ? '1 listed driver on policy'
            : `${additionalDrivers.length + 1} listed drivers on policy`}
        </div>
      </FlexCell>
    </Flextable>
  </GridRow>
);

ListedDriversSection.propTypes = {
  additionalDrivers: PropTypes.object,
};

export default ListedDriversSection;
