import { quoteDetailsSelectorForBuyQuote } from '../selectors/quoteDetailsSelector';
import { driverDetailsSelectorForBuyQuote } from '../selectors/driverDetailsSelector';
import { locationDetailsSelectorForCreateAndAmendQuote } from '../selectors/locationDetailsSelector';
import { coverDetailsForBuyQuote } from '../selectors/coverDetailsSelector';
import { regoNumberSelector } from '../selectors/vehicleDetailsSelector';
import { policyHolderDetailsSelectorForBuyQuote } from '../selectors/policyHolderDetailsSelector';

export const buyQuoteMapper = (state, responseId) => {
  const { token, publicKey } = state.quoteDetails.paymentContext;
  return {
    data: {
      type: 'CarPayment',
      id: state.quoteDetails.id,
      attributes: {
        quoteDetails: quoteDetailsSelectorForBuyQuote(state),
        paymentContext: {
          token,
          publicKey,
          responseId,
        },
        driverDetails: driverDetailsSelectorForBuyQuote(state),
        locationDetails: locationDetailsSelectorForCreateAndAmendQuote(state),
        coverDetails: coverDetailsForBuyQuote(state),
        vehicleDetails: {
          regoNo: regoNumberSelector(state),
        },
        policyHolderDetails: policyHolderDetailsSelectorForBuyQuote(state),
      },
    },
  };
};
