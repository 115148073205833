import { POLICY_COMPANY_DETAILS_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const policyCompanyDetailsPageAnalytics = {
  urlPattern: POLICY_COMPANY_DETAILS_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      { pageName: 'companypolicy_details', flowType: 'buy' },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    dataLayer.dataModel.isPolicyInCompanyName =
      store.policyHolderDetails.policyInNameOfCompany === 'true' ? 'Yes' : 'No';
  },
};

export default policyCompanyDetailsPageAnalytics;
