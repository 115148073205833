export const ALL_AVAILABLE_EXPERIENCES = {
  mockExperience1: {
    description: 'Mock Experience 1',
    values: ['false', 'true'],
    defaultExpValue: 'false',
  },
  QSPExcessExpB: {
    description: 'QSP Excess Experience B',
    values: ['false', 'true'],
    defaultExpValue: 'false',
  },
  SumInsuredMaxValue: {
    description: 'Sum Insured Max Value',
    values: ['false', 'true'],
    defaultExpValue: 'false',
  },
};
