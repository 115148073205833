import { SUM_INSURED } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const sumInsuredPageAnalytics = {
  urlPattern: SUM_INSURED,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'car_details_suminsured',
        flowType: 'quote',
      },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    let sumInsured;
    if (store.coverDetails?.sumInsured)
      sumInsured = store.coverDetails?.sumInsured.agreedValue;
    dataLayer.dataModel.insuredAmount = sumInsured;
  },
};

export default sumInsuredPageAnalytics;
