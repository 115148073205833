import {
  Main,
  Flextable,
  FlexCell,
  List,
} from 'suncorp-styleguide-react-components';
import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import TestHelpExpSwitcher from '../../molecules/TestHelpExpSwitcher';
import TestHelperPageLinks from './testHelperPageLinks';
import MockAPIHelper from './mockAPIHelper';
import JeopardyTriggers from './jeopardyTriggers';
import JumpButtons from './testHelperJumpButtons';
import { JeopardyContext } from '../../../utils/jeopardyProvider';

const TestHelpers = () => {
  const [showTestHelpers, setShowTestHelpers] = useState(true);
  const { openJeopardyModal, setOpenJeopardyModal } =
    useContext(JeopardyContext);
  const testAndTarget = useSelector((state) => state.testAndTarget);

  const [appDataArr] = useState([]);
  const makeAppDataArr = () => {
    Object.keys(appData).forEach((element) => {
      const { value } = appData[element];
      // eslint-disable-next-line no-unused-expressions
      value &&
        !appDataArr.includes(`${element}: ${value}`) &&
        appDataArr.push(`${element}: ${value}`);
    });
  };
  makeAppDataArr();

  const hideButton = (
    <FlexCell contentAlign="middleRight">
      <button
        type="button"
        onClick={() => setShowTestHelpers(false)}
        className="sg-Btn sg-Btn--small sg-Btn--iconLeft"
      >
        Hide helper
        <i className="sg-Btn-icon Icon-cross--dark Icon--xsmall" />
      </button>
    </FlexCell>
  );

  const helpData = (
    <FlexCell contentAlign="middleCentre" customWidth={{ default: '5of6' }}>
      <List
        appearance="horizontalBar"
        className="sg-Type--bold"
        data={appDataArr}
      />
    </FlexCell>
  );

  return (
    showTestHelpers && (
      <>
        <hr className="sg-u-noMarginBottom" />
        <Main>
          <Flextable columns={6} className="sg-u-marginBottom--x1point5">
            {helpData}
            {hideButton}
          </Flextable>

          <TestHelperPageLinks />
          <MockAPIHelper />
          <JumpButtons />
          <TestHelpExpSwitcher testAndTarget={testAndTarget} />

          <JeopardyTriggers
            openJeopardyModal={openJeopardyModal}
            updateModalIsOpen={(value) => {
              setOpenJeopardyModal(value);
            }}
          />
        </Main>
      </>
    )
  );
};

const TestMockAPI = ({ vehicle, vehicleURL }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async (url) => {
      await axios
        .get(url)
        .then((response) => {
          setData(response.status);
        })
        .catch((error) => {
          setData(error.response.status);
        });
    };
    fetchData(vehicleURL);
  }, [vehicle, vehicleURL]);
  return (
    <div>
      {vehicle} RESPONSE {data}
    </div>
  );
};

TestMockAPI.propTypes = {
  vehicle: PropTypes.string.isRequired,
  vehicleURL: PropTypes.string.isRequired,
};

const appData = {
  'UI env': { value: process.env.REACT_APP_ENV },
  Branch: { value: process.env.REACT_APP_REPOSITORY_BRANCH_NAME },
  'Artefact version': { value: process.env.REACT_APP_ARTEFACT_VERSION },
  'Commit ID': { value: process.env.REACT_APP_REPOSITORY_COMMIT_SHA },
  'API region': {
    value:
      process.env.REACT_APP_MOTOR_API_BASE_URL &&
      process.env.REACT_APP_MOTOR_API_BASE_URL.match('stable\\d'),
  },
};

export default React.memo(TestHelpers);
