export const MAX_NUMBER_OF_OCCURRENCES = 6;
export const CLAIM_OCCURRENCES_IN_MONTHS = 61;

export const MAX_SUSPENSIONS = 1;
export const DelayedSoftJeopardyKeys = [
  'driverLicenceClaim',
  'driverExcessClaim',
];

export const DRIVER_TYPE = {
  MAIN_DRIVER: 'Main Driver',
  ADDITIONAL_DRIVER: 'Additional Driver',
};

export const MAX_ADDITIONAL_DRIVERS = 5;

export const MALE_TITLES = ['Mr', 'Dr'];

export const FEMALE_TITLES = ['Mrs', 'Ms', 'Miss', 'Dr'];

export const MALE = 'Male';
export const FEMALE = 'Female';

export const MAX_NAME_LEN = 30;
export const allowedNameCharacters = /^[a-zA-Z\s\b\-']*$/;
export const GRIDCOL_SPAN_FULL = {
  default: 12,
  large: 12,
  small: 12,
  xlarge: 12,
};
export const GRIDCOL_SPAN_HALF = {
  default: 6,
  large: 6,
  small: 12,
  xlarge: 6,
};

export const EXCESS_CLAIMS_NONE = '0';
export const EXCESS_CLAIMS_ONE = '1';
export const EXCESS_CLAIMS_TWO = '2';
export const EXCESS_CLAIMS_THREE = '3';
export const EXCESS_CLAIMS_JEOPARDY_OPTION = '4+';
export const EXCESS_CLAIMS_OPTIONS = [
  EXCESS_CLAIMS_NONE,
  EXCESS_CLAIMS_ONE,
  EXCESS_CLAIMS_TWO,
  EXCESS_CLAIMS_THREE,
  EXCESS_CLAIMS_JEOPARDY_OPTION,
];

export const AT_FAULT_CLAIMS = {
  AT_FAULT: 'You were at fault and an excess was payable',
  OTHER_CLAIM_PAYABLE: 'Any other claim where an excess was payable',
  THEFT: 'Theft',
};

export const DRIVER_CLAIMS = {
  WINDSCREEN: 'Windscreen claim',
  EXCESS_NOT_PAYABLE: 'Any other claim where an excess was not payable',
  MAX_THEFT: 1,
  MAX_IN_PAST_YEAR: 2,
  MAX_IN_PAST_FIVE_YEARS: 4,
  MAX_TOTAL_LOSS: 2,
};

export const DRIVER_HISTORY = [
  {
    value: AT_FAULT_CLAIMS.AT_FAULT,
    description: AT_FAULT_CLAIMS.AT_FAULT,
  },
  {
    value: DRIVER_CLAIMS.WINDSCREEN,
    description: DRIVER_CLAIMS.WINDSCREEN,
  },
  {
    value: 'Natural Hazard (eq, fire, flood, hail etc.)',
    description: 'Natural Hazard (eq, fire, flood, hail etc.)',
  },
  {
    value: AT_FAULT_CLAIMS.OTHER_CLAIM_PAYABLE,
    description: AT_FAULT_CLAIMS.OTHER_CLAIM_PAYABLE,
  },
  {
    value: DRIVER_CLAIMS.EXCESS_NOT_PAYABLE,
    description: DRIVER_CLAIMS.EXCESS_NOT_PAYABLE,
  },
  {
    value: AT_FAULT_CLAIMS.THEFT,
    description: AT_FAULT_CLAIMS.THEFT,
  },
];
export const AGE_EXCESS = {
  listedDriver: 'Young driver excess - Under 25 years of age, if listed',
  unListedDriver: 'Young driver excess - Under 25 years of age, if not listed',
  unListedDriverValue: 1500,
};
export const COOLING_PERIOD_LABEL = '21 day cooling off period';
export const RACING_COVER_LABEL = 'Racing cover';
export const RACING_EXCESS_LABEL = 'Racing excess';
export const RACING_EXCESS = 500;
export const SALVAGE_RIGHTS = 'Salvage rights';
export const RESTRICTED_DRIVER_POLICY = 'Restricted driver policy';

export const CLAIM_OCCURRENCE_TYPE = {
  'You were at fault and an excess was payable': 'At Fault',
  'Windscreen claim': 'Windscreen',
  'Natural Hazard (eq, fire, flood, hail etc.)': 'Not At Fault',
  'Any other claim where an excess was payable': 'At Fault',
  'Any other claim where an excess was not payable': 'Not At Fault',
  Theft: 'Vehicle Stolen',
};
