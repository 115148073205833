import loadable from '../utils/loadable';
import {
  MODIFICATIONS_ACCESSORIES_PAGE,
  ADDRESS_SEARCH_FLOW,
  ENTHUSIAST_DRIVER_PAGE,
  DOD_PAGE,
  HOME_PAGE,
  MAIN_DRIVER_DETAILS_PAGE,
  DRIVER_SUMMARY_PAGE,
  ADDITIONAL_DRIVER_DETAILS_PAGES_ROUTE,
  POLICY_HOLDER_CRIMINAL_STATUS_PAGE,
  NO_CLAIM_BONUS_PAGE,
  PARKING_TYPE_PAGE,
  POLICY_START_DATE_PAGE,
  QSP_PAGE,
  REGISTRATION_NUMBER_PAGE,
  RUST_AND_DAMAGE_PAGE,
  SPECIFIED_MODIFICATIONS_ACCESSORIES_PAGE,
  SUM_INSURED,
  VEHICLE_CONDITION_PAGE,
  VEHICLE_FINANCE_PAGE,
  VEHICLE_REGISTRATION_PAGE,
  VEHICLE_SEARCH_PAGE,
  VEHICLE_USAGE_FREQUENCY,
  VEHICLE_USAGE_PAGE,
  POLICY_HOLDER_INSURANCE_CANCELLATION_PAGE,
  POLICY_HOLDER_BANKRUPTCY_PAGE,
  CAPTURE_CONTACT_DETAILS_PAGE,
} from '../constants/urlConstants';

const quoteFlowRouteConfig = {
  [HOME_PAGE]: {
    previousPage: undefined,
    nextPage: POLICY_START_DATE_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/DutyOfDisclosurePage'),
    ),
    description: 'Home Page',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [DOD_PAGE]: {
    previousPage: undefined,
    nextPage: POLICY_START_DATE_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/DutyOfDisclosurePage'),
    ),
    description: 'Duty Of Disclosure',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [POLICY_START_DATE_PAGE]: {
    previousPage: DOD_PAGE,
    nextPage: VEHICLE_SEARCH_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/PolicyStartDatePage'),
    ),
    description: 'Policy Start Date',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [VEHICLE_SEARCH_PAGE]: {
    previousPage: POLICY_START_DATE_PAGE,
    nextPage: MODIFICATIONS_ACCESSORIES_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/VehicleSearchPage'),
    ),
    description: 'Vehicle Search',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [MODIFICATIONS_ACCESSORIES_PAGE]: {
    previousPage: VEHICLE_SEARCH_PAGE,
    nextPage: {
      HAS_MODIFICATIONS_ACCESSORIES: SPECIFIED_MODIFICATIONS_ACCESSORIES_PAGE,
      NO_MODIFICATIONS_ACCESSORIES: RUST_AND_DAMAGE_PAGE,
    },
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/ModificationsAccessoriesPage'),
    ),
    description: 'Modifications and Accessories',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [SPECIFIED_MODIFICATIONS_ACCESSORIES_PAGE]: {
    previousPage: MODIFICATIONS_ACCESSORIES_PAGE,
    nextPage: RUST_AND_DAMAGE_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/SpecifiedModificationsPage'),
    ),
    description: 'Specified Modifications and Accessories',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [RUST_AND_DAMAGE_PAGE]: {
    previousPage: {
      NO_MODIFICATIONS_ACCESSORIES: MODIFICATIONS_ACCESSORIES_PAGE,
      HAS_MODIFICATIONS_ACCESSORIES: SPECIFIED_MODIFICATIONS_ACCESSORIES_PAGE,
    },
    nextPage: VEHICLE_CONDITION_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/RustAndDamagePage'),
    ),
    description: 'Existing Damage',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [VEHICLE_CONDITION_PAGE]: {
    previousPage: RUST_AND_DAMAGE_PAGE,
    nextPage: {
      SKIP_SUM_INSURED: VEHICLE_FINANCE_PAGE,
      SHOW_SUM_INSURED: SUM_INSURED,
    },
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/VehicleConditionPage'),
    ),
    description: 'Vehicle Condition',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [SUM_INSURED]: {
    previousPage: VEHICLE_CONDITION_PAGE,
    nextPage: VEHICLE_FINANCE_PAGE,
    Component: loadable(() => import('../pages/QuoteFlowPages/SumInsuredPage')),
    description: 'Agreed Value',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [VEHICLE_FINANCE_PAGE]: {
    previousPage: {
      SKIP_SUM_INSURED: VEHICLE_CONDITION_PAGE,
      SHOW_SUM_INSURED: SUM_INSURED,
    },
    nextPage: VEHICLE_REGISTRATION_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/VehicleFinancePage'),
    ),
    description: 'Vehicle Finance',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [VEHICLE_REGISTRATION_PAGE]: {
    previousPage: VEHICLE_FINANCE_PAGE,
    nextPage: VEHICLE_USAGE_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/VehicleRegistrationPage'),
    ),
    description: 'Registration Status',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [VEHICLE_USAGE_PAGE]: {
    previousPage: VEHICLE_REGISTRATION_PAGE,
    nextPage: VEHICLE_USAGE_FREQUENCY,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/VehicleUsagePage'),
    ),
    description: 'Vehicle Usage',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [VEHICLE_USAGE_FREQUENCY]: {
    previousPage: VEHICLE_USAGE_PAGE,
    nextPage: {
      ENTHUSIAST_DRIVER_SKIPPED: PARKING_TYPE_PAGE,
      ENTHUSIAST_DRIVER_ASKED: ENTHUSIAST_DRIVER_PAGE,
    },
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/VehicleUsageFrequencyPage'),
    ),
    description: 'Vehicle Usage Frequency',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [ENTHUSIAST_DRIVER_PAGE]: {
    previousPage: VEHICLE_USAGE_FREQUENCY,
    nextPage: PARKING_TYPE_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/EnthusiastDriverPage'),
    ),
    description: 'Enthusiast Driver',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [PARKING_TYPE_PAGE]: {
    previousPage: {
      ENTHUSIAST_DRIVER_SKIPPED: VEHICLE_USAGE_FREQUENCY,
      ENTHUSIAST_DRIVER_ASKED: ENTHUSIAST_DRIVER_PAGE,
    },
    nextPage: ADDRESS_SEARCH_FLOW,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/ParkingTypePage'),
    ),
    description: 'Parking Type',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [ADDRESS_SEARCH_FLOW]: {
    previousPage: PARKING_TYPE_PAGE,
    nextPage: MAIN_DRIVER_DETAILS_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/AddressSearchFlow'),
    ),
    description: 'Address Search Flow',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
    pathPattern: `${ADDRESS_SEARCH_FLOW}*`,
  },
  [MAIN_DRIVER_DETAILS_PAGE]: {
    previousPage: ADDRESS_SEARCH_FLOW,
    nextPage: DRIVER_SUMMARY_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/MainDriverDetailsPage'),
    ),
    description: 'Main Driver Details',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [DRIVER_SUMMARY_PAGE]: {
    previousPage: MAIN_DRIVER_DETAILS_PAGE,
    nextPage: POLICY_HOLDER_INSURANCE_CANCELLATION_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/DriverSummaryPage'),
    ),
    description: 'Driver Summary',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [ADDITIONAL_DRIVER_DETAILS_PAGES_ROUTE]: {
    previousPage: DRIVER_SUMMARY_PAGE,
    nextPage: NO_CLAIM_BONUS_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/AdditionalDriverDetailsPage'),
    ),
    description: 'Additional Driver Details',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [POLICY_HOLDER_INSURANCE_CANCELLATION_PAGE]: {
    previousPage: DRIVER_SUMMARY_PAGE,
    nextPage: POLICY_HOLDER_CRIMINAL_STATUS_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/PolicyHolderInsuranceCancellationPage'),
    ),
    description: 'Policy Holder Insurance History',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [POLICY_HOLDER_CRIMINAL_STATUS_PAGE]: {
    previousPage: POLICY_HOLDER_INSURANCE_CANCELLATION_PAGE,
    nextPage: POLICY_HOLDER_BANKRUPTCY_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/PolicyHolderCriminalStatusPage'),
    ),
    description: 'Policy Holder Criminal Status',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [POLICY_HOLDER_BANKRUPTCY_PAGE]: {
    previousPage: POLICY_HOLDER_CRIMINAL_STATUS_PAGE,
    nextPage: NO_CLAIM_BONUS_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/PolicyHolderBankruptcyPage'),
    ),
    description: 'Policy Holder Bankruptcy',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [NO_CLAIM_BONUS_PAGE]: {
    previousPage: POLICY_HOLDER_BANKRUPTCY_PAGE,
    nextPage: CAPTURE_CONTACT_DETAILS_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/NoClaimBonusPage'),
    ),
    description: 'No Claim Discount',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [CAPTURE_CONTACT_DETAILS_PAGE]: {
    previousPage: NO_CLAIM_BONUS_PAGE,
    nextPage: QSP_PAGE,
    Component: loadable(() =>
      import('../pages/QuoteFlowPages/CaptureContactDetailsPage'),
    ),
    description: 'Capture Contact Details',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [QSP_PAGE]: {
    previousPage: CAPTURE_CONTACT_DETAILS_PAGE,
    nextPage: REGISTRATION_NUMBER_PAGE,
    Component: loadable(() => import('../pages/QuoteSchedulePage')),
    description: 'Quote Summary',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
};

export default quoteFlowRouteConfig;
