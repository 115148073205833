export const I_DONT_KNOW = "I don't know";

export const YEAR = 'year';
export const MAKE = 'make';
export const MODEL = 'model';
export const TRANSMISSION = 'transmission';
export const CYLINDER = 'cylinder';
export const BODY_TYPE = 'bodyType';

export const BLANK = 'BLANK';

export const VEHICLE_CATEGORY = {
  KIT_CAR: 'KC',
  HIGH_RISK: 'HI',
  NISSAN_HIGH_RISK1: 'N1',
  NISSAN_HIGH_RISK2: 'N2',
  PERFORMANCE: 'PF',
  EXOTIC: 'EX',
  CLASSIC: 'CL',
  PRESTIGE: 'PR',
  VANILLA: 'ZZ',
};

export const VEHICLE_CATEGORY_RESTRICTED_DRIVER = {
  HIGH_RISK: 'HI',
  NISSAN_HIGH_RISK1: 'N1',
  NISSAN_HIGH_RISK2: 'N2',
  PERFORMANCE: 'PF',
  EXOTIC: 'EX',
};
