export const locationDetailsSelectorForCreateAndAmendQuote = (store) => {
  const { addressSearchInfo } = store;
  const { matchedAddress: addressDetails } = addressSearchInfo.addressInfo;
  const { suburb, state, postcode, addressId, addressQualityLevel } =
    addressDetails;

  return {
    locationReference: {
      lurn: addressId,
      lurnScale: addressQualityLevel,
    },
    riskAddress: {
      structuredAddress: structuredAddressSelector(store),
      suburb,
      state,
      postcode,
    },
  };
};

export const structuredAddressSelector = (store) => {
  const { addressSearchInfo } = store;
  const { matchedAddress: addressDetails, unhappyConfirmed } =
    addressSearchInfo.addressInfo;
  const { addressInBrokenDownForm } = addressDetails;
  const {
    buildingName,
    unitType,
    unitNumber,
    levelType,
    levelNumber,
    streetNumber1,
    streetNumber2,
    streetName: stName,
    streetType,
    streetSuffixCode,
  } = addressInBrokenDownForm;

  // TODO: check this
  const actualStreetName = streetType ? `${stName} ${streetType}` : stName;
  const flatUnitType = unhappyConfirmed
    ? addressSearchInfo.addressInfo?.pageData?.structuredAddress?.unitType
    : unitType;
  const flatUnitNumber = unhappyConfirmed
    ? addressSearchInfo.addressInfo?.pageData?.structuredAddress?.unitNumber
    : unitNumber;
  const houseNumber1 = unhappyConfirmed
    ? addressSearchInfo.addressInfo?.pageData?.structuredAddress?.streetNumber
    : streetNumber1;
  const streetName = unhappyConfirmed
    ? addressSearchInfo.addressInfo?.pageData?.structuredAddress?.streetName
    : actualStreetName;

  return {
    buildingName,
    subdwelling: {
      flatUnitType,
      flatUnitNumber,
      floorLevelType: levelType,
      floorLevelNumber: levelNumber,
    },
    streetNumber: {
      houseNumber1,
      ...(!unhappyConfirmed && { houseNumber2: streetNumber2 }),
    },
    streetName,
    streetSuffix: streetSuffixCode,
  };
};

export const structuredPostalAddressSelector = (store) => {
  const { structuredStreetAddress } = store.policyHolderDetails.postalAddress;
  const {
    building,
    unitCode,
    unitNumber,
    levelCode,
    levelNumber,
    streetNumber1,
    streetName,
  } = structuredStreetAddress;

  return {
    buildingName: building,
    subdwelling: {
      flatUnitType: unitCode,
      flatUnitNumber: unitNumber,
      floorLevelType: levelCode,
      floorLevelNumber: levelNumber,
    },
    streetNumber: { houseNumber1: streetNumber1 },
    streetName,
  };
};
