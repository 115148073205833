import { differenceInYears } from 'date-fns';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

const urlMatchResult = (pathname) => {
  const matchResult = pathname.match(
    new RegExp('^/driver-(\\d)-details$', 'i'),
  );
  return matchResult[1];
};

export const additionalDriverDetailsPageAnalytics = {
  urlPattern: new RegExp('^/driver-(\\d)-details$', 'i'),
  pageViewHandler: ({ store, pathname }) => {
    const matchResult = urlMatchResult(pathname);
    updatePageNameWithProductInfo(
      {
        pageName: `driver_details_driver${matchResult}`,
        flowType: 'quote',
      },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store, pathname }) => {
    const matchResult = urlMatchResult(pathname);
    const driverDetails =
      store.driverDetails.additionalDrivers[matchResult - 2];
    if (driverDetails?.dob && driverDetails?.gender) {
      dataLayer.dataModel.addDriver = {
        ...dataLayer.dataModel.addDriver,
        age: differenceInYears(new Date(), new Date(driverDetails.dob)),
        gender: driverDetails.gender,
      };
    }
  },
};

export default additionalDriverDetailsPageAnalytics;
