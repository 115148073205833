import {
  BODYSTYLES,
  CYLINDERS,
  DETAILS_BODYTYPES,
  DETAILS_SEQNUMBER,
  DETAILS_TRANSMISSION,
  MAKES,
  MODELS,
  TRANSMISSIONS,
  YEARS,
} from '../../constants/testVehicleAPIConstants';
import createApiInstance from '../../utils/createApiInstance';
import { BLANK } from '../../constants/vehicleSearchConstants';

export const retrieveVehicle = async (
  requestType,
  year = '2009',
  make = 'VOLKSWAGEN',
  model = 'TIGUAN',
  transmission = 'T',
  bodyStyle = '4D_Wagon',
  cylinders = 'DT4',
  seqNo = 'JX909J',
) => {
  let requestUrl = '';
  switch (requestType) {
    case YEARS:
      requestUrl = `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years`;
      break;
    case MAKES:
      requestUrl = `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years/${year}/makes`;
      break;
    case MODELS:
      requestUrl = `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years/${year}/makes/${make}/models`;
      break;
    case TRANSMISSIONS:
      requestUrl = `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years/${year}/makes/${make}/models/${model}/transmissions`;
      break;
    case CYLINDERS:
      requestUrl = `${process.env.REACT_APP_VEHICLE_SEARCH_URL}/products/CAR/vehicles/years/${year}/makes/${make}/models/${model}/cylinders?transmissions=${transmission}`;
      break;
    case BODYSTYLES:
      requestUrl = `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years/${year}/makes/${make}/models/${model}/bodystyles?transmissions=${transmission}&cylinders=${cylinders}`;
      break;
    case DETAILS_SEQNUMBER:
      requestUrl = `${process.env.REACT_APP_MOTOR_API_BASE_URL}/${seqNo}`;
      break;
    case DETAILS_TRANSMISSION:
      requestUrl = `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years/${year}/makes/${make}/models/${model}?transmissions=${transmission}`;
      break;
    case DETAILS_BODYTYPES:
      requestUrl = `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years/${year}/makes/${make}/models/${model}?transmissions=${transmission}&cylinder=${cylinders}&bodystyle/${bodyStyle}`;
      break;
  }
  try {
    const response = await createApiInstance({
      baseURL: requestUrl,
      method: 'GET',
      additionalHeaders: {
        'X-Session-ID': 'ABC123',
      },
    });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const retrieveMakes = async (year) =>
  handleApiRequest(
    `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years/${year}/makes`,
  );

export const retrieveModels = async (year, make) =>
  handleApiRequest(
    `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years/${year}/makes/${make}/models`,
  );

export const retrieveTransmissions = async (year, make, model) =>
  handleApiRequest(
    `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years/${year}/makes/${make}/models/${model}/transmissions`,
  );

export const retrieveCylinders = async (year, make, model, transmission) => {
  const queryPrams =
    transmission === BLANK
      ? `product=CAR`
      : `transmission=${transmission}&product=CAR`;
  const response = await handleApiRequest(
    `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years/${year}/makes/${make}/models/${model}/cylinders?${queryPrams}`,
  );
  return response;
};

export const retrieveBodyTypes = async (
  year,
  make,
  model,
  transmission,
  cylinder,
) => {
  let queryPrams;
  queryPrams = transmission === BLANK ? '?' : `?transmission=${transmission}&`;
  queryPrams =
    cylinder === BLANK ? queryPrams : `${queryPrams}cylinder=${cylinder}&`;
  queryPrams = `${queryPrams}product=CAR`;

  const response = await handleApiRequest(
    `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years/${year}/makes/${make}/models/${model}/bodystyles${queryPrams}`,
  );
  return response;
};

export const retrieveVehiclesWithBodyStyle = async (
  year,
  make,
  model,
  transmission,
  cylinder,
  bodyStyle,
) => {
  let queryPrams;
  queryPrams = transmission === BLANK ? '?' : `?transmission=${transmission}&`;
  queryPrams =
    cylinder === BLANK ? queryPrams : `${queryPrams}cylinder=${cylinder}&`;
  queryPrams =
    bodyStyle === BLANK ? queryPrams : `${queryPrams}bodystyle=${bodyStyle}&`;
  queryPrams = `${queryPrams}product=CAR`;

  const response = await handleApiRequest(
    `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years/${year}/makes/${make}/models/${model}${queryPrams}`,
  );
  return response;
};

export const retrieveVehicleId = async (vehicleId) => {
  const response = await handleApiRequest(
    `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/${vehicleId}`,
  );
  return response;
};

const handleApiRequest = async (requestUrl) => {
  let response = {};
  response = await createApiInstance({
    baseURL: requestUrl,
    method: 'GET',
    additionalHeaders: {
      'X-Session-ID': 'ABC123',
    },
  });
  return response && response.data ? response.data : response;
};
