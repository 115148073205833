import { VEHICLE_IDENTIFICATION_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const vehicleIdentificationPageAnalytics = {
  urlPattern: VEHICLE_IDENTIFICATION_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      { pageName: 'car_details_enginenumber', flowType: 'buy' },
      store,
    );
  },
};

export default vehicleIdentificationPageAnalytics;
