import createApiInstance from '../../utils/createApiInstance';
import {
  retrieveQuoteMapper,
  getRetrieveViaURLRequestBody,
} from './retrieveQuoteMapper';

export const callRetrieveQuoteApi = async (state) => {
  let requestBody;

  if (state.data.ref.refUrl) {
    requestBody = getRetrieveViaURLRequestBody(state);
  } else {
    requestBody = retrieveQuoteMapper(state);
  }
  const response = await createApiInstance({
    baseURL: `${process.env.REACT_APP_QUOTE_URL}${process.env.REACT_APP_RETRIEVE_QUOTE_API_URL}`,
    method: 'POST',
    requestBody,
  });
  return response;
};
