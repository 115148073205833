import { lazy, Suspense } from 'react';

// TODO: Add a fallback component eg: a spinner
// https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
const loadable = (importFunc, { fallback = null } = { fallback: null }) => {
  const LazyComponent = lazy(importFunc);

  return (props) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;
