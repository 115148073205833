import {
  Footer,
  List,
  ListItem,
  Link,
  Paragraph,
} from 'suncorp-styleguide-react-components';
import {
  GOOGLE_PRIVACY,
  GOOGLE_TOS,
  PDS_LINK,
  PRIVACY_STATEMENT_LINK,
  TERMS_AND_PRIVACY_STATEMENT_LINK,
} from '../../../constants/urlConstants';

const AppFooter = () => (
  <Footer>
    <List appearance="horizontalBar">
      <ListItem>
        <Link
          appearance="inverse"
          href={TERMS_AND_PRIVACY_STATEMENT_LINK}
          target="_blank"
        >
          Online Terms and Conditions
        </Link>
      </ListItem>
      <ListItem>
        <Link
          appearance="inverse"
          href={PRIVACY_STATEMENT_LINK}
          target="_blank"
        >
          Privacy
        </Link>
      </ListItem>
    </List>
    <Paragraph
      className="sg-Type--small sg-Type--muted"
      id="Shannons-legal-text"
    >
      Shannons Pty Limited ABN 91 099 692 636 is an authorised representative of
      AAI Limited ABN 48 005 297 807, the product issuer. Read the relevant{' '}
      <Link appearance="inverse" href={PDS_LINK} target="_blank">
        Product Disclosure Statement
      </Link>{' '}
      before buying this insurance. The Target Market Determination is also
      available.
    </Paragraph>
    <Paragraph className="sg-Type--small sg-Type--muted" id="Google-legal-text">
      This site is protected by reCAPTCHA and the{' '}
      <Link href={GOOGLE_PRIVACY} target="_blank" appearance="inverse">
        Privacy Policy
      </Link>{' '}
      and{' '}
      <Link href={GOOGLE_TOS} target="_blank" appearance="inverse">
        Terms of Service
      </Link>{' '}
      apply.
    </Paragraph>
  </Footer>
);

export default AppFooter;
