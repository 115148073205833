import { RUST_AND_DAMAGE_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const rustAndDamagePageAnalytics = {
  urlPattern: RUST_AND_DAMAGE_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'car_details_damageorrust',
        flowType: 'quote',
      },
      store,
    );
  },
};

export default rustAndDamagePageAnalytics;
