export const NEW_REQUEST = 'New Request';

export const PAYMENT_TYPE = {
  CREDIT_CARD: 'Credit Card',
};

export const CREDIT_CARD_TYPE = {
  VISA: 'Visa Card',
  MASTERCARD: 'MasterCard',
};

export const CREDIT_CARD_BADGE = {
  VISA: 'img/logos/visa_badge.svg',
  MASTERCARD: 'img/logos/mastercard_badge.svg',
};
