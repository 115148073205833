import { GridCol } from 'suncorp-styleguide-react-components';
import PropTypes from 'prop-types';

export const ProgressIndicator = ({
  thisItemProgress,
  enabled,
  handleOnClick,
}) => (
  <GridCol span={2.4} className="sg-u-noPadding">
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
    <hr
      id={`progressBar-item-${thisItemProgress}`}
      className={`sg-u-borderTopWidth4 sg-u-margin--x0point5 ${
        enabled
          ? 'sg-Type--horizontalRule--secondary'
          : 'sg-Type--horizontalRule--neutral'
      }`}
      style={{ maxWidth: '250px' }}
      onClick={enabled ? handleOnClick : null}
    />
  </GridCol>
);

ProgressIndicator.propTypes = {
  thisItemProgress: PropTypes.number,
  enabled: PropTypes.bool,
  handleOnClick: PropTypes.func,
};
