const generateUUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx4xxxyxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0; // eslint-disable-line no-bitwise
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16); // eslint-disable-line no-bitwise
  });
  return uuid;
};

export const sessionDetails = {
  state: {
    sessionId: generateUUID(),
    techErrorInfo: {
      errorCode: undefined,
      prevLocation: '',
    },
    sessionTimedOut: false,
    serialisationDatetime: new Date().getTime(),
    isRetrievedAfterPaymentTried: false,
  },
  reducers: {
    updateTechErrorInfo(state, payload) {
      return { ...state, techErrorInfo: { ...payload } };
    },
    updateSessionTimedOut(state, payload) {
      return { ...state, sessionTimedOut: payload };
    },
    updateIsRetrieved(state, payload) {
      return { ...state, isRetrievedAfterPaymentTried: payload };
    },
  },
  effects: (dispatch) => ({
    updateQuoteRetrievalFlag(payload) {
      const { driverDetails } = payload.data.attributes;
      if (driverDetails.mainDriver.firstName)
        dispatch.sessionDetails.updateIsRetrieved(true);
    },
  }),
};
