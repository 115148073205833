import { Link } from 'suncorp-styleguide-react-components';
import PropTypes from 'prop-types';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import { CONTACT_URL } from '../../../constants/urlConstants';
import {
  CONTACT_NUMBER_URL,
  MOBILE_URL,
} from '../../../constants/phoneConstants';

const ContactNumberLink = () => {
  const { isMobile } = useDeviceDetect();
  return (
    <Link
      href={isMobile ? MOBILE_URL : CONTACT_URL}
      {...(!isMobile && { target: '_blank' })}
    >
      {CONTACT_NUMBER_URL}
    </Link>
  );
};

ContactNumberLink.propTypes = {
  contactNumber: PropTypes.string,
  desktopUrl: PropTypes.string,
  mobileUrl: PropTypes.string,
};

export default ContactNumberLink;
