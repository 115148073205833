export const policyHolderDetailsInitalState = {
  policyInNameOfCompany: '',
  companyName: '',
  contactName: '',
  marketConsent: '',
  sameAsRiskAddress: undefined,
  postalAddress: {
    structuredStreetAddress: {},
  },
  contactEmailAddress: '',
  communicationPreference: 'email',
  contactNumber: '',
  marketingContactNumber: '',
  hasInsuranceCancelled: '',
  hasCriminalHistory: '',
  hasBankruptcy: '',
};

export const policyHolderDetails = {
  state: policyHolderDetailsInitalState,
  reducers: {
    setPolicyHolderDetails(state, payload) {
      return { ...state, ...payload };
    },
    setPolicyInNameOfCompany(state, payload) {
      return { ...state, policyInNameOfCompany: payload };
    },
    setCompanyName(state, payload) {
      return { ...state, companyName: payload };
    },
    setContactName(state, payload) {
      return { ...state, contactName: payload };
    },
    setMarketConsent(state, payload) {
      return { ...state, marketConsent: payload };
    },
    updateSameAsRiskAddress(state, payload) {
      return { ...state, sameAsRiskAddress: payload };
    },
    updatePostalAddress(state, payload) {
      return { ...state, postalAddress: payload };
    },
    updateContactEmailAddress(state, payload) {
      return { ...state, contactEmailAddress: payload };
    },
    updateCommunicationPreference(state, payload) {
      return { ...state, communicationPreference: payload };
    },
    updateContactNumber(state, payload) {
      return { ...state, contactNumber: payload };
    },
    updateMarketingContactNumber(state, payload) {
      return { ...state, marketingContactNumber: payload };
    },
    setHasInsuranceCancelled(state, payload) {
      return { ...state, hasInsuranceCancelled: payload };
    },
    setHasCriminalHistory(state, payload) {
      return { ...state, hasCriminalHistory: payload };
    },
    setHasBankruptcy(state, payload) {
      return { ...state, hasBankruptcy: payload };
    },
    updatePolicyHolderDetailsOnQuoteRetrieve(
      state,
      policyHolderRetrieveDetails,
      emailMobileNumber,
    ) {
      return {
        ...state,
        hasInsuranceCancelled:
          policyHolderRetrieveDetails.insuranceCancelled.toString(),
        hasCriminalHistory:
          policyHolderRetrieveDetails.criminalOffence.toString(),
        hasBankruptcy: policyHolderRetrieveDetails.bankruptcy.toString(),
        contactEmailAddress:
          emailMobileNumber && emailMobileNumber.emailAddress,
        contactNumber: emailMobileNumber && emailMobileNumber.mobileNumber,
        marketingContactNumber:
          emailMobileNumber && emailMobileNumber.mobileNumber,
      };
    },
  },
};
