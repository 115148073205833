import { CLAIM_OCCURRENCE_TYPE } from '../../constants/driverConstants';

export const driverDetailsSelectorForBuyQuote = (state) => {
  const { title, firstname, surname, dob, gender, isPolicyHolder } =
    state.driverDetails.mainDriver;

  const { policyHolderDetails } = state;

  const additionalDriversArr = [];
  state.driverDetails.additionalDrivers.forEach((item) => {
    additionalDriversArr.push({
      policyHolder: policyHolderDetails.companyName
        ? false
        : item.isPolicyHolder,
      title: item.title,
      firstName: item.firstName,
      lastName: item.surname,
      dateOfBirth: item.dob,
      gender: item.gender,
    });
  });

  return {
    mainDriver: {
      policyHolder: policyHolderDetails.companyName ? false : isPolicyHolder,
      title,
      firstName: firstname,
      lastName: surname,
      dateOfBirth: dob,
      gender,
    },
    additionalDrivers: additionalDriversArr,
  };
};

export const driverDetailsSelectorForCreateAndAmendQuote = (state) => {
  const {
    driverDetails,
    quoteDetails: { paymentContext = {} },
    sessionDetails: { isRetrievedAfterPaymentTried = false } = {},
  } = state;
  const hasPaymentAmendBeenCalled = !!paymentContext.token;

  const shouldFetchDriverName = !!(
    hasPaymentAmendBeenCalled || isRetrievedAfterPaymentTried
  );

  const { dob, gender, firstname, surname } = driverDetails.mainDriver;

  const additionalDriversArr = [];
  driverDetails.additionalDrivers.forEach((item) => {
    additionalDriversArr.push({
      dateOfBirth: item.dob,
      gender: item.gender,
      ...(shouldFetchDriverName && {
        firstName: item.firstName !== undefined ? item.firstName : 'ADVISE', // As quote is no longer anonymous adding dummy value ADVISE in amend request
        lastName: item.surname !== undefined ? item.surname : 'ADVISE',
      }),
    });
  });

  return {
    mainDriver: {
      dateOfBirth: dob,
      gender,
      ...(shouldFetchDriverName && {
        firstName: firstname,
        lastName: surname,
      }),
    },
    additionalDrivers: additionalDriversArr,
  };
};

export const driverDetailsSelectorForPaymentAmendQuote = (state) => {
  const {
    dob: dateOfBirth,
    gender,
    title,
    firstname: firstName,
    surname: lastName,
    isPolicyHolder,
    claimOccurrences,
    suspensionOccurrences,
  } = state.driverDetails.mainDriver;
  const { policyHolderDetails } = state;

  const additionalDriversArr = [];
  state.driverDetails.additionalDrivers.forEach((item) => {
    const driverClaimOccurrences = [];
    item.claimOccurrences.forEach((subItem) => {
      const splitDate = subItem.date.split('-');
      driverClaimOccurrences.push({
        type: CLAIM_OCCURRENCE_TYPE[subItem.type],
        month: splitDate[1],
        year: splitDate[0],
        freeFormatDescription: subItem.type,
        totalLoss: subItem.totalLoss === 'true',
      });
    });
    const driverInfringements = [];
    item.suspensionOccurrences.forEach((subItem) => {
      const splitDate = subItem.split('-');
      driverInfringements.push({
        month: splitDate[1],
        year: splitDate[0],
      });
    });
    additionalDriversArr.push({
      dateOfBirth: item.dob,
      gender: item.gender,
      policyHolder: policyHolderDetails.companyName
        ? false
        : item.isPolicyHolder,
      title: item.title,
      firstName: item.firstName,
      lastName: item.surname,
      claimOccurrences: driverClaimOccurrences,
      infringements: driverInfringements,
    });
  });

  const mainDriverClaimOccurrences = [];
  claimOccurrences.forEach((item) => {
    const splitDate = item.date.split('-');
    mainDriverClaimOccurrences.push({
      type: CLAIM_OCCURRENCE_TYPE[item.type],
      month: splitDate[1],
      year: splitDate[0],
      freeFormatDescription: item.type,
      totalLoss: item.totalLoss === 'true',
    });
  });

  const mainDriverInfringements = [];
  suspensionOccurrences.forEach((item) => {
    const splitDate = item.split('-');
    mainDriverInfringements.push({
      month: splitDate[1],
      year: splitDate[0],
    });
  });

  return {
    mainDriver: {
      dateOfBirth,
      gender,
      policyHolder: policyHolderDetails.companyName ? false : isPolicyHolder,
      title,
      firstName,
      lastName,
      claimOccurrences: mainDriverClaimOccurrences,
      infringements: mainDriverInfringements,
    },
    additionalDrivers: additionalDriversArr,
  };
};
