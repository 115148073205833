import { VEHICLE_USAGE_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const vehicleUsagePageAnalytics = {
  urlPattern: VEHICLE_USAGE_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'car_details_usage',
        flowType: 'quote',
      },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    dataLayer.dataModel.vehiclePrimaryUse = store.vehicleDetails?.vehicleUsage;
  },
};

export default vehicleUsagePageAnalytics;
