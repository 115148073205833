import { CAR_FINANCE_PROVIDER } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const carFinanceProviderPageAnalytics = {
  urlPattern: CAR_FINANCE_PROVIDER,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      { pageName: 'car_details_finance', flowType: 'buy' },
      store,
    );
  },
};

export default carFinanceProviderPageAnalytics;
