// TODO: once more files are using this, remove from code coverage ignore list

const loadJs = (url) =>
  new Promise((resolve, reject) => {
    const element = document.createElement('script');
    element.async = true;

    // Important success and error for the promise
    element.onload = () => resolve(url);

    element.onerror = () => reject(url);

    // Inject into document to kick off loading
    element.src = url;
    document.head.appendChild(element);
  });

const loadCss = (url) =>
  new Promise((resolve, reject) => {
    const element = document.createElement('link');
    element.type = 'text/css';
    element.rel = 'stylesheet';
    element.href = url;

    element.onload = () => resolve(url);
    element.onerror = () => reject(url);

    document.head.insertBefore(element, document.head.firstChild);
  });

const loader = (url) => {
  if (url.includes('.js')) {
    return loadJs(url);
  }

  if (url.endsWith('.css')) {
    return loadCss(url);
  }

  throw new Error('Unsupported type');
};

export const load = (urls) => Promise.all(urls.map((url) => loader(url)));
