import 'react-app-polyfill/ie11';
import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import App from './App';
import configureStore from './utils/configureStore';
import { loadAndConfigureEnsightenBootstrap } from './analytics/configureEnsighten';
import { makeServer } from './mockServer';
import loadReCaptcha from './utils/loadReCaptcha';
import loadJQuery from './utils/loadJQuery';

Promise.all([
  loadAndConfigureEnsightenBootstrap(),
  loadReCaptcha(),
  loadJQuery(),
]).finally(() => {
  // Ignore any errors while loading ensighten to keep the app working
  render();
});

if (process.env.REACT_APP_MOCK_API === 'true') {
  makeServer();
}
const store = configureStore();

const persistor = persistStore(store);

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
