import { amendQuoteForPaymentMapper } from './amendQuoteForPaymentMapper';
import createApiInstance from '../../utils/createApiInstance';

export const callAmendQuoteForPaymentApi = async (state, token) => {
  const requestBody = amendQuoteForPaymentMapper(state);
  const { sessionId } = state.sessionDetails;
  const { quoteId } = requestBody.data.attributes.quoteDetails;
  const response = await createApiInstance({
    baseURL: `${process.env.REACT_APP_QUOTE_URL}/${quoteId}?action=payment`,
    method: 'POST',
    additionalHeaders: {
      'X-Session-ID': sessionId,
      ...(token !== undefined && { 'X-ReCaptcha-Token': token }),
    },
    requestBody,
  });
  return response;
};
