export const EXCELLENT_CONDITION = 'Excellent';
export const GOOD_CONDITION = 'Good';
export const FAIR_CONDITION = 'Fair';
export const RESTORABLE_CONDITION = 'Restorable / Under Restoration';
export const ACCIDENT_DAMAGE = 'Incident / Accident Damage';
export const PRE_EXISTING_DAMAGE = 'Restorable / Under Restoration';

export const vehicleConditionDataNoRust = [
  {
    value: EXCELLENT_CONDITION,
    description:
      'The overall condition of the vehicle is "as new" or has been restored or modified to a higher than average standard.',
  },
  {
    value: GOOD_CONDITION,
    description:
      'A well maintained vehicle that is in average to above average overall condition and presents well with no mechanical problems.',
  },
  {
    value: FAIR_CONDITION,
    description:
      'The vehicle has been driven on a regular basis, is in sound condition but has some defects to paint, body or its mechanical condition.',
  },
  {
    value: RESTORABLE_CONDITION,
    description:
      'The vehicle is complete but requires repair or restoration or is currently in the process of restoration. There is no accident damage to the vehicle. Only laid up cover will be available.',
  },
];

export const vehicleConditionDataHasRust = [
  {
    value: ACCIDENT_DAMAGE,
    description:
      'The vehicle has damage caused by an incident or accident that has already occurred. E.g weather event, collision, attempted theft.',
  },
  {
    value: PRE_EXISTING_DAMAGE,
    description:
      'The vehicle is complete but requires repair or restoration or is currently in the process of restoration. There is no accident damage to the vehicle.',
  },
];
