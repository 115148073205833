import { parse, format } from 'date-fns';
import { POLICY_START_DATE_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const policyStartDatePageAnalytics = {
  urlPattern: POLICY_START_DATE_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      { pageName: 'policystartdate', flowType: 'quote' },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    if (store.quoteDetails.policyStartDate) {
      dataLayer.dataModel.policyStartDate = format(
        parse(store.quoteDetails.policyStartDate, 'yyyy-mm-dd', new Date()),
        'yyyymmdd',
      );
    }
  },
};

export default policyStartDatePageAnalytics;
