export const retrieveQuoteMapper = (payload) => ({
  data: {
    type: 'CarQuote',
    attributes: {
      quoteNumber: payload.data.quoteNumber,
      dateOfBirth: payload.data.mainDriverDob,
      postcode: payload.data.postcode,
    },
  },
});

export const getRetrieveViaURLRequestBody = (payload) => ({
  data: {
    type: 'CarQuote',
    attributes: {
      ref: payload.data.ref.refUrl,
    },
  },
});
