import { ALL_AVAILABLE_EXPERIENCES } from '../constants/testAndTargetConstants';

export const getInitialState = (all) => {
  const result = {};
  Object.keys(all).forEach((expName) => {
    result[expName] = all[expName].defaultExpValue;
  });
  return result;
};

export const testAndTargetInitialState = getInitialState(
  ALL_AVAILABLE_EXPERIENCES,
);

const testAndTarget = {
  state: testAndTargetInitialState,
  reducers: {
    setExperience(state, payload) {
      return {
        ...state,
        [payload.experienceType]: payload.experienceValue,
      };
    },
    reset() {
      return { ...testAndTargetInitialState };
    },
  },
};

export default testAndTarget;
