import { CAPTURE_CONTACT_DETAILS_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const captureContactDetailsPageAnalytics = {
  urlPattern: CAPTURE_CONTACT_DETAILS_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'driver_details_emailaddress',
        flowType: 'quote',
      },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    dataLayer.dataModel.emailAddressProvided = store.policyHolderDetails
      ?.contactEmailAddress
      ? 'Yes'
      : 'Skip';
    dataLayer.dataModel.contactNoProvided = store.policyHolderDetails
      ?.marketingContactNumber
      ? 'Yes'
      : 'Skip';
  },
};

export default captureContactDetailsPageAnalytics;
