import storage from 'redux-persist/lib/storage/session';
import persistPlugin from '@rematch/persist';
import { init } from '@rematch/core';
import models from '../models';
import { analyticsMiddleware } from '../middlewares/analyticsMiddleware';

const persistConfig = {
  key: 'root',
  storage,
};

// eslint-disable-next-line no-unused-vars
export default function configureStore(sessionStorageKey, history) {
  return init({
    plugins: [persistPlugin(persistConfig)],
    redux: {
      middlewares: [analyticsMiddleware],
    },
    models,
  });
}
