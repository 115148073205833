import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  triggerCaptureAnalytics,
  triggerEventAnalytics,
  triggerPageViewAnalytics,
} from '../../../analytics/analyticsTriggers';

const AnalyticsPageHandler = () => {
  const store = useSelector((state) => state);
  const [prevLocationPathName, setPrevLocationPathName] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // runs on every location, i.e. route, change
    triggerCaptureAnalytics(store, prevLocationPathName);
    triggerPageViewAnalytics(store, location.pathname);
    setPrevLocationPathName(location.pathname);

    // Create a function that will be called by our event listener that we can
    // also pass to removeEventListener
    function analyticsEventListener(event) {
      triggerEventAnalytics(event, store, location);
    }
    // Setup the actual listener for the click or change event on the document
    document.addEventListener('click', analyticsEventListener);
    document.addEventListener('change', analyticsEventListener);

    // Be sure to remove the listener when the component is unmounted to prevent
    // memory leaks!
    return () => {
      document.removeEventListener('click', analyticsEventListener);
      document.addEventListener('change', analyticsEventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <></>;
};

export default AnalyticsPageHandler;
