import {
  MOTOR_VEHICLE_USAGE_BUSINESS,
  MOTOR_VEHICLE_USAGE_HIRE,
  MOTOR_VEHICLE_USAGE_PRIVATE,
  VEHICLE_USAGE_FREQUENCY_CLUB_PLATE_USE,
  VEHICLE_USAGE_FREQUENCY_EXTREME_LIMITED_USE,
  VEHICLE_USAGE_FREQUENCY_LIMITED_USAGE,
  VEHICLE_USAGE_FREQUENCY_NOT_DRIVEN_LAID_UP,
  VEHICLE_USAGE_FREQUENCY_REGULAR_DRIVER,
} from './vehicleConstants';
import {
  MISAPI_EXCELLENT_CONDITION_NO_RUST,
  MISAPI_FAIR_CONDITION_NO_RUST,
  MISAPI_GOOD_CONDITION_NO_RUST,
  MISAPI_MODIFICATION_ACCESSORIES,
  MISAPI_MODIFICATION_BODY,
  MISAPI_MODIFICATION_HOT_ROD,
  MISAPI_MODIFICATION_MAJOR_PAINT,
  MISAPI_MODIFICATION_MAJOR_PERF,
  MISAPI_MODIFICATION_MINOR_PAINT,
  MISAPI_MODIFICATION_MINOR_PERF,
  MISAPI_MODIFICATION_SAFTEY,
  MISAPI_MODIFICATION_SUSPENSION,
  MISAPI_MODIFICATION_WHEELS,
  MISAPI_MOTOR_VEHICLE_USAGE_WEDDING,
  MISAPI_PREEXISTING_DAMAGE_CONDITION,
  MISAPI_RESTORABLE_CONDITION,
  MISAPI_VEHICLE_USAGE_FREQUENCY_CLUB_PLATE_USE,
  MISAPI_VEHICLE_USAGE_FREQUENCY_EXTREME_LIMITED_USE,
  MISAPI_VEHICLE_USAGE_FREQUENCY_LAID_UP,
  MISAPI_VEHICLE_USAGE_FREQUENCY_LIMITED_USAGE,
  MISAPI_VEHICLE_USAGE_FREQUENCY_REGULAR_DRIVER,
  PERMITTED_USE,
} from './misapiMappings';
import {
  MODIFICATION_ACCESSORIES,
  MODIFICATION_BODY,
  MODIFICATION_HOT_ROD,
  MODIFICATION_MAJOR_PAINT,
  MODIFICATION_MAJOR_PERF,
  MODIFICATION_MINOR_PAINT,
  MODIFICATION_MINOR_PERF,
  MODIFICATION_SAFETY,
  MODIFICATION_SUSPENSION,
  MODIFICATION_WHEELS,
} from './modificationAccessoriesConstants';
import {
  EXCELLENT_CONDITION,
  FAIR_CONDITION,
  GOOD_CONDITION,
  RESTORABLE_CONDITION,
  PRE_EXISTING_DAMAGE,
} from './vehicleCondition';
import {
  EXCESS_CLAIMS_NONE,
  EXCESS_CLAIMS_ONE,
  EXCESS_CLAIMS_THREE,
  EXCESS_CLAIMS_TWO,
} from './driverConstants';
import {
  FORTYFIVE_PERCENT_NO_CLAIM_DISCOUNT,
  PROTECTED_NO_CLAIM_DISCOUNT,
  SIXTYFIVE_PERCENT_NO_CLAIM_DISCOUNT,
} from './productConstants';

export const retrieveQuoteResponseMappings = {
  [MISAPI_MOTOR_VEHICLE_USAGE_WEDDING]: MOTOR_VEHICLE_USAGE_HIRE,
  [MOTOR_VEHICLE_USAGE_BUSINESS + PERMITTED_USE]: MOTOR_VEHICLE_USAGE_BUSINESS,
  [MOTOR_VEHICLE_USAGE_PRIVATE + PERMITTED_USE]: MOTOR_VEHICLE_USAGE_PRIVATE,
  [MISAPI_MODIFICATION_ACCESSORIES]: MODIFICATION_ACCESSORIES,
  [MISAPI_MODIFICATION_WHEELS]: MODIFICATION_WHEELS,
  [MISAPI_MODIFICATION_MINOR_PERF]: MODIFICATION_MINOR_PERF,
  [MISAPI_MODIFICATION_MAJOR_PERF]: MODIFICATION_MAJOR_PERF,
  [MISAPI_MODIFICATION_SUSPENSION]: MODIFICATION_SUSPENSION,
  [MISAPI_MODIFICATION_BODY]: MODIFICATION_BODY,
  [MISAPI_MODIFICATION_SAFTEY]: MODIFICATION_SAFETY,
  [MISAPI_MODIFICATION_MINOR_PAINT]: MODIFICATION_MINOR_PAINT,
  [MISAPI_MODIFICATION_MAJOR_PAINT]: MODIFICATION_MAJOR_PAINT,
  [MISAPI_MODIFICATION_HOT_ROD]: MODIFICATION_HOT_ROD,
  [MISAPI_PREEXISTING_DAMAGE_CONDITION]: PRE_EXISTING_DAMAGE,
  [MISAPI_EXCELLENT_CONDITION_NO_RUST]: EXCELLENT_CONDITION,
  [MISAPI_GOOD_CONDITION_NO_RUST]: GOOD_CONDITION,
  [MISAPI_FAIR_CONDITION_NO_RUST]: FAIR_CONDITION,
  [MISAPI_RESTORABLE_CONDITION]: RESTORABLE_CONDITION,
  [MISAPI_VEHICLE_USAGE_FREQUENCY_LAID_UP]:
    VEHICLE_USAGE_FREQUENCY_NOT_DRIVEN_LAID_UP,
  [MISAPI_VEHICLE_USAGE_FREQUENCY_LIMITED_USAGE]:
    VEHICLE_USAGE_FREQUENCY_LIMITED_USAGE,
  [MISAPI_VEHICLE_USAGE_FREQUENCY_LIMITED_USAGE]:
    VEHICLE_USAGE_FREQUENCY_LIMITED_USAGE,
  [MISAPI_VEHICLE_USAGE_FREQUENCY_EXTREME_LIMITED_USE]:
    VEHICLE_USAGE_FREQUENCY_EXTREME_LIMITED_USE,
  [MISAPI_VEHICLE_USAGE_FREQUENCY_CLUB_PLATE_USE]:
    VEHICLE_USAGE_FREQUENCY_CLUB_PLATE_USE,
  [MISAPI_VEHICLE_USAGE_FREQUENCY_REGULAR_DRIVER]:
    VEHICLE_USAGE_FREQUENCY_REGULAR_DRIVER,
  [PROTECTED_NO_CLAIM_DISCOUNT]: EXCESS_CLAIMS_NONE,
  [SIXTYFIVE_PERCENT_NO_CLAIM_DISCOUNT]: EXCESS_CLAIMS_ONE,
  [SIXTYFIVE_PERCENT_NO_CLAIM_DISCOUNT]: EXCESS_CLAIMS_TWO,
  [FORTYFIVE_PERCENT_NO_CLAIM_DISCOUNT]: EXCESS_CLAIMS_THREE,
};
