import { CONTACT_DETAILS_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const contactDetailsPageAnalytics = {
  urlPattern: CONTACT_DETAILS_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'policy_contactdetails',
        flowType: 'buy',
      },
      store,
    );
  },
};

export default contactDetailsPageAnalytics;
