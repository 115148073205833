import {
  structuredAddressSelector,
  structuredPostalAddressSelector,
} from './locationDetailsSelector';
import {
  COMMUNICATION_PREFERENCE,
  MARKETING_CONSENT,
} from '../../constants/policyHolderConstants';

export const policyHolderDetailsSelectorForCreateAndAmendQuote = (state) => {
  const { policyHolderDetails } = state;
  return {
    criminalOffence: /true/.test(policyHolderDetails.hasCriminalHistory),
    insuranceCancelled: /true/.test(policyHolderDetails.hasInsuranceCancelled),
    bankruptcy: /true/.test(policyHolderDetails.hasBankruptcy),
  };
};

export const policyHolderDetailsSelectorForBuyQuote = (state) => {
  const { policyHolderDetails, addressSearchInfo } = state;
  const {
    suburb: addressSuburb,
    state: addressState,
    postcode: addressPostcode,
  } = addressSearchInfo.addressInfo.matchedAddress;
  const { sameAsRiskAddress, postalAddress } = policyHolderDetails;
  const {
    suburb: postalSuburb,
    state: postalState,
    postcode: postalPostcode,
  } = postalAddress;
  return {
    postalAddress: {
      structuredAddress:
        sameAsRiskAddress === 'true'
          ? structuredAddressSelector(state)
          : structuredPostalAddressSelector(state),
      suburb: sameAsRiskAddress === 'true' ? addressSuburb : postalSuburb,
      state: sameAsRiskAddress === 'true' ? addressState : postalState,
      postcode: sameAsRiskAddress === 'true' ? addressPostcode : postalPostcode,
    },
    contactDetails: {
      email: policyHolderDetails.contactEmailAddress,
      mobileNumber:
        policyHolderDetails.communicationPreference ===
        COMMUNICATION_PREFERENCE.EMAIL
          ? policyHolderDetails.contactNumber
          : null,
      phoneNumber:
        policyHolderDetails.communicationPreference ===
        COMMUNICATION_PREFERENCE.POST
          ? policyHolderDetails.contactNumber
          : null,
    },
    ...(policyHolderDetails.companyName && {
      companyDetails: {
        companyName: policyHolderDetails.companyName,
      },
    }),
  };
};

export const policyHolderDetailsSelectorForPaymentAmendQuote = (state) => {
  const { policyHolderDetails } = state;
  return {
    ...policyHolderDetailsSelectorForBuyQuote(state),
    ...(policyHolderDetails.companyName && {
      companyDetails: {
        companyName: policyHolderDetails.companyName,
        contactName: policyHolderDetails.contactName,
      },
    }),
    ...policyHolderDetailsSelectorForCreateAndAmendQuote(state),
    electronicNoticeConsent:
      policyHolderDetails.communicationPreference ===
      COMMUNICATION_PREFERENCE.EMAIL,
    marketingConsents:
      policyHolderDetails.marketConsent === 'true'
        ? MARKETING_CONSENT.YES
        : MARKETING_CONSENT.NO,
  };
};
