import { callCreateQuoteApi } from '../apis/createQuote';
import { callBuyQuoteApi } from '../apis/buyQuote';
import { MISAPI_ERRORS, TECH_ERROR } from '../constants/techErrorConstants';
import {
  HOME_PAGE,
  NO_CLAIM_BONUS_PAGE,
  QSP_PAGE,
} from '../constants/urlConstants';
import { callEmailQuoteApi } from '../apis/emailQuote';
import { callMarketingEmail } from '../apis/marketingEmail';
import { callAmendQuoteApi } from '../apis/amendQuote';
import { callRetrieveQuoteApi } from '../apis/retrieveQuote';
import { callAmendQuoteForPaymentApi } from '../apis/amendQuoteForPayment';
import { JEOPARDY_TYPE } from '../constants/jeopardyConstants';
import {
  hasSoftJeopardy,
  isSoftJeopardySumInsuredExceedMaxValue,
} from '../utils/quoteSchedulePageUtils';
import { PROGRESS_BAR_STEPS } from '../constants/progressBarConstants';
import { AGREED_VALUE_STATUS_CHANGED } from '../constants/coverDetailsConstants';
import {
  PSD_CHANGED,
  QUOTE_UPDATED_REASONS,
} from '../constants/quoteConstants';
import { createJeopardy } from './jeopardyDetails';
export const quoteDetailsInitialState = {
  id: '',
  quoteId: '',
  quoteNumber: '',
  policyStartDate: '',
  dutyOfDisclosureAccepted: false,
  productType: 'car',
  noClaimBonus: '',
  saveQuoteEmailSent: false,
  policyExpiryDate: '',
  quoteExpiryDate: '',
  premium: {
    annualPremium: 1234.56,
    monthlyPremium: 128.19,
    annualBasePremium: 670.69,
    annualCharges: 0.5,
    annualStampDuty: 73.77,
    annualGST: 10.0,
  },
  paymentFrequency: 'Monthly',
  paymentContext: {
    token: '',
    publicKey: '',
    sunpayUrl: '',
  },
  paymentDetails: undefined,
  policyDetails: {
    receiptNumber: undefined,
    policyNumber: undefined,
    paymentDate: undefined,
    paymentStatus: undefined,
  },
  retrieveQuoteError: '',
  isRetrievedQuote: false,
  policyStartDateStatus: '',
  isRetrievedQuoteButVehicleChanged: false,
  isRetrievedQuoteButClaimsChanged: false,
  isRetrievedQuoteByEmail: false,
  quoteFlowProgress: null,
  shouldTriggerAmendQuoteOnQSPLoad: false,
  quoteUpdatedReasons: [],
  quoteReminderRequired: false,
  registrationOutcome: undefined,
};

export const quoteDetails = {
  state: quoteDetailsInitialState,
  reducers: {
    setQuoteDetails(state, payload) {
      return { ...state, ...payload };
    },
    setId(state, payload) {
      return { ...state, id: payload };
    },
    setQuoteId(state, payload) {
      return { ...state, quoteId: payload };
    },
    setQuoteNumber(state, payload) {
      return { ...state, quoteNumber: payload };
    },
    setPolicyStartDate(state, payload) {
      return { ...state, policyStartDate: payload };
    },
    setDutyOfDisclosureAccepted(state, payload) {
      return { ...state, dutyOfDisclosureAccepted: payload };
    },
    setNoClaimBonus(state, payload) {
      return { ...state, noClaimBonus: payload };
    },
    setSaveQuoteEmailSent(state, payload) {
      return { ...state, saveQuoteEmailSent: payload };
    },
    setPremium(state, payload) {
      return { ...state, premium: payload };
    },
    setPaymentFrequency(state, payload) {
      return { ...state, paymentFrequency: payload };
    },
    setPaymentContext(state, payload) {
      return { ...state, paymentContext: payload };
    },
    setPaymentDetails(state, payload) {
      return { ...state, paymentDetails: payload };
    },
    setIsLoading(state, payload) {
      return { ...state, isLoading: payload };
    },
    setPolicyDetails(state, payload) {
      const { receiptNumber, policyNumber, paymentDate, paymentStatus } =
        payload;
      return {
        ...state,
        policyDetails: {
          receiptNumber,
          policyNumber,
          paymentDate,
          paymentStatus,
        },
      };
    },
    setRetrieveQuoteError(state, payload) {
      return { ...state, retrieveQuoteError: payload };
    },
    setIsRetrievedQuote(state, payload) {
      return { ...state, isRetrievedQuote: payload };
    },
    setPolicyStartDateStatus(state, payload) {
      return { ...state, policyStartDateStatus: payload };
    },
    setIsRetrievedQuoteButVehicleChanged(state, payload) {
      return { ...state, isRetrievedQuoteButVehicleChanged: payload };
    },
    setIsRetrievedQuoteButClaimsChanged(state, payload) {
      return { ...state, isRetrievedQuoteButClaimsChanged: payload };
    },
    setIsRetrievedQuoteByEmail(state, payload) {
      return { ...state, isRetrievedQuoteByEmail: payload };
    },
    setQuoteFlowProgress(state, payload) {
      return { ...state, quoteFlowProgress: payload };
    },
    setShouldTriggerAmendQuoteOnQSPLoad(state, payload) {
      return { ...state, shouldTriggerAmendQuoteOnQSPLoad: payload };
    },
    setQuoteUpdatedReasons(state, payload) {
      return { ...state, quoteUpdatedReasons: payload };
    },
    resetQuoteUpdatedReasons(state) {
      return { ...state, quoteUpdatedReasons: [] };
    },
    setQuoteReminderRequired(state, payload) {
      return { ...state, quoteReminderRequired: payload };
    },
    setRegistrationOutcome(state, payload) {
      return { ...state, registrationOutcome: payload };
    },
  },
  effects: (dispatch) => ({
    handleBuyQuoteError(payload) {
      // dispatch.quoteDetails.setIsLoading(false);
      if (
        payload.error?.response?.status >= 500 &&
        payload.error?.response?.data?.errorKey === MISAPI_ERRORS.UNKNOWN
      ) {
        dispatch.sessionDetails.updateTechErrorInfo({
          errorCode: TECH_ERROR.BIND_ERROR_CODE,
          prevLocation: null,
        });
      } else {
        dispatch.sessionDetails.updateTechErrorInfo({
          errorCode: TECH_ERROR.CLIENT_ERROR_CODE,
          prevLocation: payload.prevLocation || HOME_PAGE,
        });
      }
    },
    handleTechError(payload) {
      if (payload.prevLocation === QSP_PAGE || NO_CLAIM_BONUS_PAGE) {
        dispatch.quoteDetails.setIsLoading(false);
      }

      if (payload.error && payload.error.status >= 500) {
        dispatch.sessionDetails.updateTechErrorInfo({
          errorCode: TECH_ERROR.SERVER_ERROR_CODE,
          prevLocation: payload.prevLocation,
        });
      } else {
        dispatch.sessionDetails.updateTechErrorInfo({
          errorCode: TECH_ERROR.CLIENT_ERROR_CODE,
          prevLocation: payload.prevLocation || HOME_PAGE,
        });
      }
    },
    handleQuoteResponse(payload, store) {
      dispatch.quoteDetails.setQuoteDetails({
        ...payload.response.data.attributes.quoteDetails,
        noClaimBonus:
          payload.response.data.attributes.vehicleDetails.noClaimBonus,
      });
      dispatch.vehicleDetails.setUniqueVehicleIdentifier(
        payload.response.data.attributes.vehicleDetails.uniqueVehicleIdentifier,
      );
      if (!hasSoftJeopardy(payload.response.data)) {
        dispatch.coverDetails.setCoverDetails(
          payload.response.data.attributes.coverDetails,
        );
      }
      dispatch.quoteDetails.setId(payload.response.data.id);
      if (hasSoftJeopardy(payload.response.data)) {
        const isSoftJeopardySumInsuredExceedMaxSIValue =
          isSoftJeopardySumInsuredExceedMaxValue(
            store.testAndTarget.SumInsuredMaxValue,
            payload.response.data.attributes.jeopardyDetails,
          );
        const softJeopardy = createJeopardy(
          isSoftJeopardySumInsuredExceedMaxSIValue
            ? JEOPARDY_TYPE.SOFT_JEOPARDY_SUM_INSURED_EXCEED_MAX_VALUE
            : JEOPARDY_TYPE.SOFT_JEOPARDY,
          [],
          payload.prevLocation,
          payload.response.data.attributes.jeopardyDetails.warnings,
          isSoftJeopardySumInsuredExceedMaxSIValue
            ? payload.response.data.attributes.jeopardyDetails.agreedValueMax
            : '',
        );
        dispatch.quoteDetails.resetQuoteUpdatedReasons();
        dispatch.jeopardyDetails.updateJeopardyDetails(softJeopardy);
      }
      dispatch.quoteDetails.setIsLoading(false);
    },
    async createQuote(payload, store) {
      try {
        let token;
        if (/true/.test(process.env.REACT_APP_RECAPTCHA)) {
          token = await dispatch.quoteDetails.getReCaptchaToken(payload);
        }
        const response = await callCreateQuoteApi(store, token);
        dispatch.quoteDetails.handleQuoteResponse({
          response,
          store,
          prevLocation: payload.prevLocation,
        });
        return response.data;
      } catch (error) {
        dispatch.quoteDetails.handleTechError({
          error,
          prevLocation: payload.prevLocation,
        });
        return error;
      }
    },
    async emailQuote(payload, store) {
      try {
        return await callEmailQuoteApi(store);
      } catch (error) {
        return error;
      }
    },
    async sendMarketingEmail(payload, store) {
      try {
        return await callMarketingEmail(store);
      } catch (error) {
        return error;
      }
    },
    async buyQuote(payload, store) {
      const response = await callBuyQuoteApi(store, payload.resId);
      dispatch.quoteDetails.setPolicyDetails(
        response.data.attributes.quoteDetails,
      );
      dispatch.quoteDetails.setRegistrationOutcome(
        response?.data?.attributes?.identityServiceResponse
          ?.selfServiceRegistrationResponse,
      );
      if (response.data.attributes.paymentDetails) {
        dispatch.quoteDetails.setPaymentDetails(
          response.data.attributes.paymentDetails,
        );
      } else {
        dispatch.quoteDetails.setPaymentDetails(undefined);
      }
      return response.data;
    },
    async amendQuote(payload, store) {
      try {
        dispatch.quoteDetails.setIsLoading(true);
        let token;
        if (/true/.test(process.env.REACT_APP_RECAPTCHA)) {
          token = await dispatch.quoteDetails.getReCaptchaToken(payload);
        }
        const response = await callAmendQuoteApi(store, token);
        dispatch.quoteDetails.handleQuoteResponse({
          response,
          store,
          prevLocation: payload.prevLocation,
        });
        if (store.quoteDetails.quoteReminderRequired) {
          dispatch.quoteDetails.sendMarketingEmail();
        }
        return response.data;
      } catch (error) {
        dispatch.quoteDetails.handleTechError({
          error,
          prevLocation: payload.prevLocation,
        });
        return error;
      }
    },
    async retrieveQuote(payload, store) {
      const response = await callRetrieveQuoteApi(payload, store);
      dispatch.quoteDetails.handleQuoteResponse({ response });
      const { attributes } = response.data;
      const {
        locationDetails,
        policyHolderDetails,
        driverDetails,
        vehicleDetails,
        coverDetails,
        emailAddress,
        mobileNumber,
      } = attributes;
      dispatch.quoteDetails.setDutyOfDisclosureAccepted(true);
      const { uniqueVehicleIdentifier } = vehicleDetails;

      let emailMobileNumber = {};
      if (emailAddress || mobileNumber) {
        dispatch.quoteDetails.setQuoteReminderRequired(true);
        emailMobileNumber = {
          emailAddress,
          mobileNumber,
        };
      }
      dispatch.policyHolderDetails.updatePolicyHolderDetailsOnQuoteRetrieve(
        policyHolderDetails,
        emailMobileNumber,
      );
      dispatch.addressSearchInfo.updateAddressInfoOnQuoteRetrieve(
        locationDetails,
      );
      dispatch.addressSearchInfo.updateAddressDetailsOnQuoteRetrieve(
        locationDetails,
      );

      dispatch.driverDetails.updateDriverDetailsOnQuoteRetrieve(
        driverDetails,
        vehicleDetails,
      );
      dispatch.vehicleDetails.updateVehicleDetailsOnQuoteRetrieve(
        vehicleDetails,
      );
      await dispatch.vehicleSearchInfo.makeCallToVehicleId(
        uniqueVehicleIdentifier,
      );
      dispatch.quoteDetails.setIsRetrievedQuote(true);
      dispatch.sessionDetails.updateQuoteRetrievalFlag(response);
      dispatch.quoteDetails.setIsRetrievedQuoteButVehicleChanged(false);
      dispatch.vehicleSearchInfo.setSameVehicleAsPreviousSelectionStatus(true);
      dispatch.quoteDetails.setIsRetrievedQuoteButClaimsChanged(false);
      dispatch.quoteDetails.setQuoteFlowProgress(PROGRESS_BAR_STEPS.YOUR_QUOTE);
      dispatch.enthusiastDriver.setSkipEnthusiastDriverPage('true');
      const quoteUpdatedReasonsKeys = [];
      if (coverDetails?.agreedValueStatus === AGREED_VALUE_STATUS_CHANGED) {
        quoteUpdatedReasonsKeys.push(
          QUOTE_UPDATED_REASONS.AGREED_VALUE_CHANGED,
        );
        if (emailAddress || mobileNumber) {
          dispatch.quoteDetails.sendMarketingEmail();
        }
      }
      if (
        response.data.attributes.quoteDetails?.policyStartDateStatus ===
        PSD_CHANGED
      ) {
        quoteUpdatedReasonsKeys.push(
          QUOTE_UPDATED_REASONS.POLICY_START_DATE_PASSED,
        );
        if (emailAddress || mobileNumber) {
          dispatch.quoteDetails.sendMarketingEmail();
        }
      }
      dispatch.quoteDetails.setQuoteUpdatedReasons(quoteUpdatedReasonsKeys);
      return response;
    },
    async getReCaptchaToken(payload) {
      return new Promise((resolve, reject) => {
        window.grecaptcha.enterprise.ready(async () => {
          try {
            window.grecaptcha.enterprise
              .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY, {})
              .then((token) => {
                resolve(token);
              }, reject);
          } catch (error) {
            dispatch.quoteDetails.handleTechError({
              error,
              prevLocation: payload.prevLocation,
            });
          }
        });
      });
    },
    async amendQuoteForPayment(payload, store) {
      try {
        let reCaptchaToken;
        if (/true/.test(process.env.REACT_APP_RECAPTCHA)) {
          reCaptchaToken = await dispatch.quoteDetails.getReCaptchaToken(
            payload,
          );
        }
        dispatch.quoteDetails.setIsLoading(true);
        const response = await callAmendQuoteForPaymentApi(
          store,
          reCaptchaToken,
        );
        if (store.quoteDetails.quoteReminderRequired) {
          dispatch.quoteDetails.sendMarketingEmail();
        }
        const { token, publicKey } = response?.data?.attributes?.paymentContext;
        dispatch.quoteDetails.handleQuoteResponse({
          response,
          prevLocation: payload.prevLocation,
        });
        dispatch.quoteDetails.setPaymentContext({
          token,
          publicKey,
        });
        return response;
      } catch (error) {
        dispatch.quoteDetails.handleTechError({
          error,
          prevLocation: payload.prevLocation,
        });
        return error;
      }
    },
  }),
};
