import { TECH_ERROR_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithJeopardy } from '../../../analytics/dataLayerUtils';
import { TECH_ERROR } from '../../../constants/techErrorConstants';

export const techErrorPageAnalytics = {
  urlPattern: TECH_ERROR_PAGE,
  pageViewHandler: ({ store }) => {
    const { errorCode } = store.sessionDetails?.techErrorInfo;
    let jeopardyMessage = 'server issue';
    if (errorCode === TECH_ERROR.BIND_ERROR_CODE) {
      jeopardyMessage = 'bind issue';
    } else if (
      errorCode === TECH_ERROR.SERVER_ERROR_CODE ||
      errorCode === TECH_ERROR.CLIENT_ERROR_CODE
    ) {
      jeopardyMessage = 'technical issue';
    }
    updatePageNameWithJeopardy(
      { pageName: 'jeopardy_technical' },
      jeopardyMessage,
      store,
    );
  },
};

export default techErrorPageAnalytics;
