import {
  ADDRESS_SEARCH_FLOW,
  ADDRESS_SEARCH_PARTIAL_MATCH_URL,
  ADDRESS_SEARCH_STRUCTURED_ADDRESS_URL,
  CAPTURE_CONTACT_DETAILS_PAGE,
  DOD_PAGE,
  DRIVER_SUMMARY_PAGE,
  ENTHUSIAST_DRIVER_PAGE,
  HOME_PAGE,
  MAIN_DRIVER_DETAILS_PAGE,
  MODIFICATIONS_ACCESSORIES_PAGE,
  NO_CLAIM_BONUS_PAGE,
  PARKING_TYPE_PAGE,
  POLICY_HOLDER_BANKRUPTCY_PAGE,
  POLICY_HOLDER_CRIMINAL_STATUS_PAGE,
  POLICY_HOLDER_INSURANCE_CANCELLATION_PAGE,
  POLICY_START_DATE_PAGE,
  QSP_PAGE,
  RUST_AND_DAMAGE_PAGE,
  SPECIFIED_MODIFICATIONS_ACCESSORIES_PAGE,
  SUM_INSURED,
  VEHICLE_CONDITION_PAGE,
  VEHICLE_FINANCE_PAGE,
  VEHICLE_REGISTRATION_PAGE,
  VEHICLE_SEARCH_PAGE,
  VEHICLE_USAGE_FREQUENCY,
  VEHICLE_USAGE_PAGE,
} from '../constants/urlConstants';
import { PROGRESS_BAR_STEPS } from '../constants/progressBarConstants';

export const stepTitle = [
  '',
  'Find Your Car',
  'Modifications & Accessories',
  'Car Details',
  'Driver Details',
  'Your Quote',
];

export const findYourCarPages = [
  HOME_PAGE,
  DOD_PAGE,
  POLICY_START_DATE_PAGE,
  VEHICLE_SEARCH_PAGE,
];

export const modsAccessoriesPages = [
  MODIFICATIONS_ACCESSORIES_PAGE,
  SPECIFIED_MODIFICATIONS_ACCESSORIES_PAGE,
];

export const vehicleDetailsPages = [
  RUST_AND_DAMAGE_PAGE,
  VEHICLE_CONDITION_PAGE,
  SUM_INSURED,
  VEHICLE_FINANCE_PAGE,
  VEHICLE_REGISTRATION_PAGE,
  VEHICLE_USAGE_PAGE,
  VEHICLE_USAGE_FREQUENCY,
  ENTHUSIAST_DRIVER_PAGE,
  PARKING_TYPE_PAGE,
  ADDRESS_SEARCH_FLOW,
  ADDRESS_SEARCH_STRUCTURED_ADDRESS_URL,
  ADDRESS_SEARCH_PARTIAL_MATCH_URL,
];

export const driverDetailsPages = [
  MAIN_DRIVER_DETAILS_PAGE,
  DRIVER_SUMMARY_PAGE,
  POLICY_HOLDER_INSURANCE_CANCELLATION_PAGE,
  POLICY_HOLDER_CRIMINAL_STATUS_PAGE,
  POLICY_HOLDER_BANKRUPTCY_PAGE,
  NO_CLAIM_BONUS_PAGE,
  CAPTURE_CONTACT_DETAILS_PAGE,
];

export const quoteSchedulePages = [QSP_PAGE];

export const findProgressBarStep = (pageURL) => {
  let step = 0;
  if (findYourCarPages.includes(pageURL))
    step = PROGRESS_BAR_STEPS.FIND_YOUR_CAR;

  if (modsAccessoriesPages.includes(pageURL))
    step = PROGRESS_BAR_STEPS.MODS_ACCESSORIES;

  if (vehicleDetailsPages.includes(pageURL))
    step = PROGRESS_BAR_STEPS.CAR_DETAILS;

  if (
    driverDetailsPages.includes(pageURL) ||
    /^\/driver-\d-details$/.test(pageURL)
  )
    step = PROGRESS_BAR_STEPS.DRIVER_DETAILS;

  if (quoteSchedulePages.includes(pageURL))
    step = PROGRESS_BAR_STEPS.YOUR_QUOTE;

  const title = stepTitle[step] || '';
  return { step, title };
};
