import PropTypes from 'prop-types';
import {
  Button,
  Cell,
  Dialog,
  Grid,
  GridCol,
  GridRow,
  Icon,
} from 'suncorp-styleguide-react-components';
import ContactNumberLink from '../../atoms/ContactNumberLink';

const JeopardyModal = ({
  reasons,
  openJeopardyModal,
  updateModalIsOpen,
  modalMaxWidth,
}) => {
  const handleCloseModal = () => {
    updateModalIsOpen(false);
  };
  return (
    <Dialog
      bodyClassName="sg-u-noPadding"
      type="modal"
      appearance="lite"
      id="jeopardy-modal"
      open={openJeopardyModal}
      width={modalMaxWidth}
      hasCloseButton={false}
      clickOffToClose={false}
    >
      <>
        <Button
          type="button"
          className="sg-Btn sg-Btn--prev sg-u-marginLeft--x2"
          onClick={handleCloseModal}
          data-testid="modalBackBtn"
        >
          Back
        </Button>
        <div className="sg-u-paddingLeft--x3 sg-u-paddingBottom--x2 sg-u-paddingRight--x3 sg-Type--colourDark">
          <p className=" sg-Type--size24 sg-Type--bold">
            Sorry, we&#39;re unable to continue at the moment
          </p>
          {reasons && (
            <div className="sg-u-marginTop">
              <p className="sg-u-uppercase sg-Type--muted">reason</p>
              <ul
                id="jeopardyReason"
                className="sg-List-unstyled sg-u-noMargin"
              >
                {reasons.map((reason) => (
                  <li key={reason.key}>{reason.value}</li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <Cell appearance="correct" className="sg-u-noPadding">
          <Grid className="sg-u-paddingLeft--x3 sg-u-paddingTop--x2 sg-u-paddingBottom--x3 sg-u-paddingRight--x3">
            <GridRow className="sg-u-flex--middle">
              <GridCol
                span={{ default: 2, medium: 2, large: 1, xlarge: 1 }}
                className="sg-u-noPadding"
              >
                <Icon
                  id="phoneIcon"
                  name="mobile"
                  color="secondary"
                  size="xlarge"
                />
              </GridCol>
              <GridCol
                span={{ default: 10, medium: 10, large: 11, xlarge: 11 }}
              >
                <div className="sg-u-marginLeft--x0point5">
                  <p
                    id="whatToDoText"
                    className="sg-u-noMargin sg-Type--colourText sg-u-uppercase"
                  >
                    What to do next
                  </p>
                  <p
                    id="callText"
                    className="sg-Type--bold sg-u-noMargin sg-Type--size20 sg-Type--colourDark"
                  >
                    Contact us
                  </p>
                </div>
              </GridCol>
            </GridRow>
            <GridRow className="sg-u-marginTop--x1point5">
              <GridCol>
                <p id="callUsOnText" className="sg-Type--colourDark">
                  Call us on <ContactNumberLink /> and one of our Insurance
                  Consultants will assist you.
                </p>
              </GridCol>
            </GridRow>
          </Grid>
        </Cell>
      </>
    </Dialog>
  );
};

JeopardyModal.defaultProps = {
  modalMaxWidth: 650,
};

JeopardyModal.propTypes = {
  reasons: PropTypes.arrayOf(PropTypes.object),
  openJeopardyModal: PropTypes.bool,
  updateModalIsOpen: PropTypes.func,
  modalMaxWidth: PropTypes.number,
};

export default JeopardyModal;
