import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { TIMEOUT_PAGE } from '../constants/urlConstants';
import { resetReduxStore } from '../utils/sessionTimeoutUtils';

export function useSessionTimeout() {
  const dispatch = useDispatch();
  const { sessionTimedOut } = useSelector((state) => state.sessionDetails);
  const handleOnIdle = async () => {
    await dispatch.sessionDetails.updateSessionTimedOut(true);
    resetReduxStore(dispatch);
  };

  // More on this tool at https://idletimer.dev/
  useIdleTimer({
    timeout: process.env.REACT_APP_SESSION_TIMEOUT_TIME,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    const isTimeoutPage = window.location.pathname === TIMEOUT_PAGE;
    if (sessionTimedOut && !isTimeoutPage) {
      window.location.pathname = TIMEOUT_PAGE;
    }
  }, [sessionTimedOut]);
}
