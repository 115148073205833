import {
  COMP_CLASSIC,
  COMP_EMERGING_CLASSIC,
  CAR,
  CAR_NPS,
  NON_PRODUCT_SPECIFIC,
} from '../constants/coverConstants';
// You need to replace following "xxx" with the actual config value
// Common dataLayer config
export const BASE_PAGE_TYPE = 'secapp:sales:motor';
export const BASE_PAGE_NAME = `in:shn:${BASE_PAGE_TYPE}`;
export const PRODUCT_COMPREHENSIVE = 'car_comprehensive';
export const PRODUCT_CLASSIC = 'car_classic_comprehensive';
export const PRODUCT_EMERGING_CLASSIC = 'car_emerging_classic_comprehensive';
export const CAR_NO_PRODUCT_SELECTED = 'car_nps';
export const NON_SPECIFIC = 'car_nonproduct_specific';

export const AVAILABLE_PRODUCT_NAMES = {
  [CAR_NPS]: CAR_NO_PRODUCT_SELECTED,
  [CAR]: PRODUCT_COMPREHENSIVE,
  [COMP_CLASSIC]: PRODUCT_CLASSIC,
  [COMP_EMERGING_CLASSIC]: PRODUCT_EMERGING_CLASSIC,
  [NON_PRODUCT_SPECIFIC]: NON_SPECIFIC,
};

const dataLayer = {
  brand: 'shn',
  campaign: {},
  dataModel: {
    dataModelType: 'rxMotor',
    rxMotor: {},
    intraPageElement: {},
    mainDriver: {},
  },
  errors: [],
  events: [],
  jeopardyMessages: [],
  metricsServer: 'shannons.com.au',
  pageName: `${BASE_PAGE_NAME}:quote_started:${AVAILABLE_PRODUCT_NAMES[CAR_NPS]}`,
  rsID: 'sunshannons',
  siteID: 'in:shn',
  sessionSerialisationID: '', // should be max of 20 alphanumeric
};

// Only stage and prod is available
const getBootstrapRegion = (appEnv) =>
  appEnv === 'prod' || appEnv === 'betaprod' ? 'prod' : 'stage';

// Generate the config by dynamic env
const getEnsightenConfig = (env) => ({
  analyticsSessionStorageKey: 'shannons-motor-analytics',
  dataLayer: {
    ...dataLayer,
    environment: env === 'prod' || env === 'betaprod' ? 'prod' : 'dev',
  },
  bootstrapUrl: `//assets1.shannons.com.au/suncorp/shn-motor-${getBootstrapRegion(
    env,
  )}/Bootstrap.js`,
});

export default getEnsightenConfig;
