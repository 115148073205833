import { VEHICLE_SEARCH_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';
import { BLANK } from '../../../constants/vehicleSearchConstants';

export const vehicleSearchPageAnalytics = {
  urlPattern: VEHICLE_SEARCH_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      { pageName: 'findyourcar', flowType: 'quote' },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    dataLayer.dataModel.vehicleYearManufacture =
      store.vehicleSearchInfo?.searchResults?.year;
    dataLayer.dataModel.vehicleMake =
      store.vehicleSearchInfo?.searchResults?.make;
    dataLayer.dataModel.vehicleModel =
      store.vehicleSearchInfo?.searchResults?.model;
    dataLayer.dataModel.vehicleNumberOfCylinders =
      store.vehicleSearchInfo?.searchResults?.cylinder === BLANK
        ? ''
        : store.vehicleSearchInfo?.searchResults?.cylinder;
    dataLayer.dataModel.vehicleBodyType =
      store.vehicleSearchInfo?.searchResults?.bodyType === BLANK
        ? ''
        : store.vehicleSearchInfo?.searchResults?.bodyType;
    dataLayer.dataModel.vehicleTransmissionType =
      store.vehicleSearchInfo?.searchResults?.transmission === BLANK
        ? ''
        : store.vehicleSearchInfo?.searchResults?.transmission;
  },
};

export default vehicleSearchPageAnalytics;
