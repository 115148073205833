import { POLICYHOLDER_ADDRESS_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const policyHolderAddressPageAnalytics = {
  urlPattern: POLICYHOLDER_ADDRESS_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'policy_postaladdress',
        flowType: 'buy',
      },
      store,
    );
  },
};

export default policyHolderAddressPageAnalytics;
