import {
  FlexCell,
  Flextable,
  GridRow,
} from 'suncorp-styleguide-react-components';
import PropTypes from 'prop-types';

const AddressSection = ({ oneLineStreetAddress, postcodeSuburbState }) => (
  <GridRow spacing="between" className="sg-u-marginTop">
    <Flextable>
      <FlexCell className="sg-u-noPadding">
        <div className="sg-Type--heading">Address</div>
        <div style={{ marginTop: '5px' }} className="sg-Type--muted">
          {oneLineStreetAddress}, {postcodeSuburbState}
        </div>
      </FlexCell>
    </Flextable>
  </GridRow>
);

AddressSection.propTypes = {
  oneLineStreetAddress: PropTypes.string,
  postcodeSuburbState: PropTypes.string,
};

export default AddressSection;
