import { MODIFICATIONS_ACCESSORIES_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const modificationsAccessoriesPageAnalytics = {
  urlPattern: MODIFICATIONS_ACCESSORIES_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      { pageName: 'car_details_accesoriesmodifications', flowType: 'quote' },
      store,
    );
  },
};

export default modificationsAccessoriesPageAnalytics;
