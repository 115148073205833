export const quoteDetailsSelectorForBuyQuote = (state) => {
  const { quoteId, quoteNumber } = state.quoteDetails;
  return {
    quoteId,
    quoteNumber,
  };
};

export const quoteDetailsSelectorForCreateQuote = (state) => {
  const { quoteDetails } = state;
  return {
    productType: 'SCM',
    policyStartDate: quoteDetails.policyStartDate,
    acceptDutyOfDisclosure: quoteDetails.dutyOfDisclosureAccepted,
    paymentFrequency: quoteDetails.paymentFrequency,
  };
};

export const quoteDetailsSelectorForAmendQuote = (state) => {
  const { quoteDetails } = state;
  return {
    productType: 'SCM',
    quoteId: quoteDetails.quoteId,
    quoteNumber: quoteDetails.quoteNumber,
    policyStartDate: quoteDetails.policyStartDate,
    acceptDutyOfDisclosure: quoteDetails.dutyOfDisclosureAccepted,
    paymentFrequency: quoteDetails.paymentFrequency,
  };
};
