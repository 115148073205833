// TODO: once more files are using this, remove from code coverage ignore list

import pageAnalytics from './pageAnalytics';
import { getDataLayer, getWebAnalytics } from './dataLayerUtils';

export const triggerEventAnalytics = (event, store, location) => {
  const dataLayer = getDataLayer();
  const webAnalytics = getWebAnalytics();
  const activeAnalyticsConfig = pageAnalytics.filter(
    ({ urlPattern }) => urlPattern === location.pathname,
  );
  // eslint-disable-next-line no-unused-vars
  activeAnalyticsConfig.forEach(({ bindEventHandlers = [], urlPattern }) => {
    bindEventHandlers.forEach(({ eventName, selector, eventHandler }) => {
      let { target } = event;
      const { type } = event;
      if (eventName !== type) return;
      while (target && target !== document) {
        if (target && target.matches(selector)) {
          eventHandler({
            dataLayer,
            store,
            webAnalytics,
            event,
          });
        }
        target = target.parentNode;
      }
    });
  });
};

const urlPatternExists = (urlPattern, pathname) => {
  if (typeof urlPattern === 'string' || urlPattern instanceof String) {
    return urlPattern === pathname;
  }
  if (urlPattern instanceof RegExp) {
    return pathname.match(urlPattern);
  }
  return false;
};

export const triggerPageViewAnalytics = (store, pathname) => {
  const dataLayer = getDataLayer();
  const webAnalytics = getWebAnalytics();
  const activeAnalyticsConfig = pageAnalytics.filter(({ urlPattern }) =>
    urlPatternExists(urlPattern, pathname),
  );

  // eslint-disable-next-line no-unused-vars
  activeAnalyticsConfig.forEach(({ pageViewHandler = [], urlPattern }) => {
    pageViewHandler({ store, dataLayer, pathname, webAnalytics });
  });
};

export const triggerCaptureAnalytics = (store, pathname) => {
  const dataLayer = getDataLayer();
  if (pathname) {
    const activeAnalyticsConfig = pageAnalytics.filter(({ urlPattern }) =>
      urlPatternExists(urlPattern, pathname),
    );
    // eslint-disable-next-line no-unused-vars
    activeAnalyticsConfig.forEach(({ onCaptureHandler, urlPattern }) => {
      if (onCaptureHandler) {
        onCaptureHandler({ dataLayer, store, pathname });
      }
    });
  }
};
