import { createQuoteMapper } from './createQuoteMapper';
import createApiInstance from '../../utils/createApiInstance';

export const callCreateQuoteApi = async (state, token) => {
  const { sessionId } = state.sessionDetails;
  const requestBody = createQuoteMapper(state);
  const requestUrl = process.env.REACT_APP_QUOTE_URL;
  const response = await createApiInstance({
    baseURL: requestUrl,
    method: 'POST',
    additionalHeaders: {
      'X-Session-ID': sessionId,
      ...(!!token && { 'X-ReCaptcha-Token': token }),
    },
    requestBody,
  });
  return response;
};
