import { Server, Response } from 'miragejs';
import format from 'date-fns/format';
import mockAPIRouteConfig, {
  ANALYTICS_PASS_THROUGH_URL,
  ANALYTICS_SMETRICS_PASS_THROUGH_URL,
  BASE_ADDRESS_SEARCH_URL,
  EMAIL_PASS_THROUGH_URL,
  VSS_BODYSTYLES_URL,
  VSS_CYLINDERS_URL,
  VSS_FIND_BY_BODY_URL,
  VSS_FIND_BY_SEQNO_URL,
  VSS_FIND_BY_TRANSMISSION_URL,
  VSS_MAKES_URL,
  VSS_MODELS_URL,
  VSS_PASS_THROUGH_URL,
  VSS_TRANSMISSIONS_URL,
  VSS_YEARS_URL,
} from './constants';
import { updateQuoteDates } from './utils/quoteUtils';
const configuration = require('./responses/configuration.json');
const vehicleYearData = require('./responses/vehicleYears.json');
const vehicleMakesData = require('./responses/vehicleMakes.json');
const vehicleModelsData = require('./responses/vehicleModels.json');
const vehicleTransmissionsData = require('./responses/vehicleTransmissions.json');
const vehicleCylindersData = require('./responses/vehicleCylinders.json');
const vehicleBodyStylesData = require('./responses/vehicleBodyStyles.json');
const vehicleDetailsTransmissionData = require('./responses/vehicleLookupByTransmission.json');
const vehicleDetailsBodyStyleData = require('./responses/vehicleLookupByBodyType.json');
const vehicleDetailsSequenceNoData = require('./responses/vehicleLookupBySequenceNumber.json');
const createQuoteData = require('./responses/createQuote.json');
const amendQuoteData = require('./responses/amendQuote.json');
const buyQuoteData = require('./responses/buyQuote.json');

// const style = 'border: 3px solid #0f7215; padding: 0.4rem;';
const removeDuplicateObjectsFromArray = (arr) => {
  arr = arr.filter(
    (value, idx, self) => idx === self.findIndex((k) => k.code === value.code),
  );
  return arr;
};

const apiConfigHandler = (server) => {
  server.get(process.env.REACT_APP_CONFIGURATION_DATA_URL, () => {
    const effectiveDate = format(new Date(Date.now()), 'yyyy-MM-dd');
    const result = configuration.data.attributes.configurationEntries.find(
      ({ entryType }) => entryType === 'policyStartDate',
    );
    result.properties.map((item) => {
      item.value = effectiveDate;
      return item;
    });
    return new Response(200, {}, configuration);
  });
};

const vehicleYearsHandler = (server) => {
  server.get(VSS_YEARS_URL, () => new Response(200, {}, vehicleYearData));
};

const vehicleMakesHandler = (server) => {
  server.get(VSS_MAKES_URL, (schema, request) => {
    const responseData = vehicleMakesData[request.params.year];
    if (responseData === undefined) {
      return new Response(200, {}, []);
    }
    if (responseData) {
      return new Response(200, {}, responseData);
    }
    return new Response(404, {});
  });
};

const vehicleModelsHandler = (server) => {
  server.get(VSS_MODELS_URL, (schema, request) => {
    const modelsForGivenYear = vehicleModelsData[request.params.year];
    const responseData = modelsForGivenYear[request.params.make];
    if (responseData) {
      return new Response(200, {}, responseData);
    }
    return new Response(404, {});
  });
};

const vehicleTransmissionsHandler = (server) => {
  server.get(VSS_TRANSMISSIONS_URL, (schema, request) => {
    const { year, make, model } = request.params;
    const responseData = vehicleTransmissionsData[year][make][model];
    if (responseData) {
      return new Response(200, {}, responseData);
    }
    return new Response(404, {});
  });
};

const vehicleCylindersHandler = (server) => {
  server.get(VSS_CYLINDERS_URL, (schema, request) => {
    const { year, make, model } = request.params;
    const { transmission } = request.queryParams;
    let responseData;
    if (transmission) {
      responseData = vehicleCylindersData[year][make][model][transmission];
    } else {
      const transmissionAndCylinders = vehicleCylindersData[year][make][model];
      const cylinders = Object.values(transmissionAndCylinders)
        .map((item) => item?.data?.attributes?.cylinders)
        .flat();
      const arr = Array.from(new Set(cylinders));
      responseData = cylinders.length > 0 && {
        attributes: { cylinders: removeDuplicateObjectsFromArray(arr) },
      };
    }
    if (responseData) {
      return new Response(200, {}, responseData);
    }
    return new Response(404, {});
  });
};

const vehicleBodyStylesHandler = (server) => {
  server.get(VSS_BODYSTYLES_URL, (schema, request) => {
    const { year, make, model } = request.params;
    const { transmission, cylinders } = request.queryParams;
    let responseData;
    if (transmission && cylinders) {
      responseData =
        vehicleBodyStylesData[year][make][model][transmission][cylinders];
    } else if (transmission && !cylinders) {
      const transmissionAndBodyStyles =
        vehicleBodyStylesData[year][make][model][transmission];
      const bodystyles = Object.values(transmissionAndBodyStyles)
        .map((item) => item?.data?.attributes?.bodyStyles)
        .flat();
      const arr = Array.from(new Set(bodystyles));
      responseData = arr.length > 0 && {
        attributes: { bodyStyles: removeDuplicateObjectsFromArray(arr) },
      };
    } else if (!transmission && cylinders) {
      const transmissionWithBodyStyles =
        vehicleBodyStylesData[year][make][model];
      const bodystyles = Object.values(transmissionWithBodyStyles)
        .map((item) => item[cylinders])
        .filter((item) => item !== undefined)
        .map((k) => k?.data?.attributes?.bodyStyles)
        .flat();
      const arr = Array.from(new Set(bodystyles));
      responseData = arr.length > 0 && {
        attributes: { bodyStyles: removeDuplicateObjectsFromArray(arr) },
      };
    } else {
      const transmissionWithBodyStyles =
        vehicleBodyStylesData[year][make][model];
      const bodystyles = Object.values(transmissionWithBodyStyles)
        .map((item) => Object.values(item))
        .flat()
        .map((item) => Object.values(item))
        .flat()
        .map((k) => k?.attributes?.bodyStyles)
        .flat();
      const arr = Array.from(new Set(bodystyles));
      responseData = arr.length > 0 && {
        attributes: { bodyStyles: removeDuplicateObjectsFromArray(arr) },
      };
    }
    if (responseData) {
      return new Response(200, {}, responseData);
    }
    return new Response(404, {});
  });
};

const vehicleDetailsByTransmissionHandler = (server) => {
  server.get(VSS_FIND_BY_TRANSMISSION_URL, (schema, request) => {
    const { year, make, model } = request.params;
    const transmission = request.queryParams.transmissions;
    let responseData;
    const data = vehicleDetailsTransmissionData;
    if (transmission) {
      responseData = data[year][make][model][transmission];
    } else {
      const cylindersAndBodyStyles = data[year][make][model];
      const vehicleDetailsVal = Object.values(cylindersAndBodyStyles)
        .map((item) => item.vehicleDetails)
        .flat();
      responseData = { vehicleDetails: Array.from(new Set(vehicleDetailsVal)) };
    }
    if (responseData) {
      return new Response(200, {}, responseData);
    }
    return new Response(404, {});
  });
};

const vehicleDetailsByBodyStyleHandler = (server) => {
  server.get(VSS_FIND_BY_BODY_URL, (schema, request) => {
    const { year, make, model } = request.params;
    const { transmission, cylinder, bodystyle } = request.queryParams;
    let formattedBodyStyle = bodystyle;
    if (bodystyle) {
      const splitBodyStyleByWords = bodystyle.split('+');
      if (splitBodyStyleByWords.length > 0) {
        splitBodyStyleByWords.forEach((item, idx) => {
          if (idx !== 0) formattedBodyStyle = `${formattedBodyStyle} ${item}`;
          else formattedBodyStyle = item;
        });
      }
    }
    let responseData;
    if (transmission && cylinder && bodystyle) {
      responseData =
        vehicleDetailsBodyStyleData[year][make][model][transmission][cylinder][
          formattedBodyStyle
        ];
      responseData = responseData?.data;
    }
    if (transmission && cylinder && !bodystyle) {
      const response =
        vehicleDetailsBodyStyleData[year][make][model][transmission][cylinder];
      const vehicleDetailsVal = Object.values(response)
        .map((item) => item?.data?.attributes?.vehicles)
        .flat();
      responseData =
        vehicleDetailsVal.length > 0 && Array.from(new Set(vehicleDetailsVal));
    }
    if (!transmission && cylinder && bodystyle) {
      const response = vehicleDetailsBodyStyleData[year][make][model];
      const vehicleDetailsVal = Object.values(response);
      responseData =
        vehicleDetailsVal.length > 0 && Array.from(new Set(vehicleDetailsVal));
      responseData = vehicleDetailsVal[0][cylinder][formattedBodyStyle];
      responseData = responseData?.data;
    }
    if (!transmission && cylinder && !bodystyle) {
      const response = vehicleDetailsBodyStyleData[year][make][model];
      let vehicleDetailsVal = Object.values(response);
      vehicleDetailsVal = vehicleDetailsVal[0][cylinder];
      vehicleDetailsVal = Object.values(vehicleDetailsVal)
        .map((k) => k?.data?.attributes?.vehicles)
        .flat();
      responseData =
        vehicleDetailsVal.length > 0 && Array.from(new Set(vehicleDetailsVal));
    }
    if (!transmission && !cylinder && bodystyle) {
      const response = vehicleDetailsBodyStyleData[year][make][model];
      const vehicleDetailsVal = Object.values(response)
        .map((item) => Object.values(item))
        .flat();
      responseData = vehicleDetailsVal[0][formattedBodyStyle];
      responseData = responseData?.data;
    }
    if (!transmission && !cylinder && !bodystyle) {
      const response = vehicleDetailsBodyStyleData[year][make][model];
      const vehicleDetailsVal = Object.values(response)
        .map((item) => Object.values(item))
        .flat()
        .map((item) => Object.values(item))
        .flat()
        .map((k) => k?.data?.attributes?.vehicles)
        .flat();
      responseData =
        vehicleDetailsVal.length > 0 && Array.from(new Set(vehicleDetailsVal));
    }
    if (transmission && !cylinder && !bodystyle) {
      const response =
        vehicleDetailsBodyStyleData[year][make][model][transmission];
      const vehicleDetailsVal = Object.values(response)
        .map((item) => Object.values(item))
        .flat()
        .map((k) => k?.data?.attributes?.vehicles)
        .flat();
      responseData =
        vehicleDetailsVal.length > 0 && Array.from(new Set(vehicleDetailsVal));
    }
    if (transmission && !cylinder && bodystyle) {
      const response =
        vehicleDetailsBodyStyleData[year][make][model][transmission];
      const vehicleDetailsVal = Object.values(response);
      responseData = vehicleDetailsVal[0][formattedBodyStyle];
      responseData = responseData?.data;
    }
    if (responseData) {
      return new Response(200, {}, responseData);
    }
    return new Response(404, {});
  });
};

const vehicleDetailsBySeqNumberHandler = (server) => {
  server.get(VSS_FIND_BY_SEQNO_URL, (schema, request) => {
    const { sequenceNo } = request.params;
    const responseData = vehicleDetailsSequenceNoData[sequenceNo];

    if (responseData) {
      return new Response(200, {}, responseData);
    }
    return new Response(404, {});
  });
};

const createQuoteHandler = (server) => {
  const createQuoteUrl = mockAPIRouteConfig.createquote.path;
  server.post(createQuoteUrl, (schema, request) => {
    const { requestBody } = request;
    const { sequenceNumber } = requestBody.data.attributes.vehicleDetails;
    const responseData = createQuoteData[sequenceNumber];
    if (responseData) {
      responseData.data.attributes.quoteDetails = updateQuoteDates(
        responseData.data.attributes.quoteDetails,
      );
      return new Response(200, {}, responseData);
    }
    return new Response(
      400,
      {},
      {
        errorKey: 'REQUEST_VALIDATION_ERROR',
      },
    );
  });
};

const amendQuoteHandler = (server) => {
  const amendQuoteUrl = mockAPIRouteConfig.amendquote.path;
  server.put(amendQuoteUrl, (schema, request) => {
    const { requestBody } = request;
    const { paymentFrequency } = requestBody.data.attributes.quoteDetails;
    const { voluntaryExcess } = requestBody.data.attributes.coverDetails;
    const { sequenceNumber } = requestBody.data.attributes.vehicleDetails;
    const { policyStartDate } = requestBody.data.attributes.quoteDetails;

    const responseData =
      amendQuoteData[paymentFrequency][voluntaryExcess][sequenceNumber];
    responseData.data.attributes.quoteDetails.policyStartDate = policyStartDate;

    if (responseData) {
      responseData.data.attributes.quoteDetails = updateQuoteDates(
        responseData.data.attributes.quoteDetails,
      );
      return new Response(200, {}, responseData);
    }
    return new Response(
      400,
      {},
      {
        errorKey: 'REQUEST_VALIDATION_ERROR',
      },
    );
  });
};

const buyQuoteHandler = (server) => {
  const buyQuoteUrl = mockAPIRouteConfig.buyquote.path;
  server.post(buyQuoteUrl, (schema, request) => {
    const { requestBody } = request;
    const { postcode } =
      requestBody.data.attributes.locationDetails.riskAddress;
    const { paymentFrequency } = requestBody.data.attributes.quoteDetails;

    try {
      const paymentType =
        postcode.substring(0, 1) === '3' ? 'PayLater' : 'PayNow';
      const responseData = buyQuoteData[paymentType][paymentFrequency];
      if (responseData) {
        return new Response(200, {}, responseData);
      }
    } catch {
      // eslint-disable-next-line
      console.error(
        `No mocked buyQuote response defined for postcode [${postcode}] and paymentFrequency [${paymentFrequency}]`,
      );
    }
    return new Response(
      400,
      {},
      {
        errorKey: 'REQUEST_VALIDATION_ERROR',
      },
    );
  });
};

const emailQuoteHandler = (server) => {
  const emailQuoteUrl = mockAPIRouteConfig.emailquote.path;
  server.post(emailQuoteUrl, (schema, request) => {
    const { requestBody } = request;
    const { postcode } = requestBody;

    if (postcode === '4000') {
      return new Response(
        400,
        {},
        {
          errorKey: 'REQUEST_VALIDATION_ERROR',
        },
      );
    }
    if (postcode === '4008') {
      return new Response(
        404,
        {},
        {
          errorKey: 'QUOTE_NOT_FOUND',
          logMessage: 'QUOTE_NOT_FOUND',
        },
      );
    }
    if (postcode === '5000') {
      return new Response(
        500,
        {},
        {
          errorKey: 'UNEXPECTED_ERROR',
        },
      );
    }
    return new Response(200, {}, JSON.stringify('OK'));
  });
};

const updateStorage = (mockAPIKeyList, mockAPIList) => {
  mockAPIKeyList.map((mockAPIkey) => {
    if (!Object.keys(mockAPIList).includes(mockAPIkey)) {
      const mockAPIObj = {};
      mockAPIObj[mockAPIkey] = mockAPIRouteConfig[mockAPIkey].defaultMock;
      mockAPIList = { ...mockAPIList, ...mockAPIObj };
    }
    return sessionStorage.setItem('MockAPIHelper', JSON.stringify(mockAPIList));
  });
};

export function makeServer() {
  let mockAPIList = sessionStorage.getItem('MockAPIHelper');
  const mockAPIKeyList = [];
  if (!mockAPIList) {
    mockAPIList = {};
    Object.keys(mockAPIRouteConfig).map((key) => mockAPIKeyList.push(key));
    updateStorage(mockAPIKeyList, mockAPIList);
    mockAPIList = sessionStorage.getItem('MockAPIHelper');
  }
  mockAPIList = JSON.parse(mockAPIList);

  const mockServer = new Server({
    routes() {
      // eslint-disable-next-line no-unused-expressions
      mockAPIList.config && apiConfigHandler(this);

      // eslint-disable-next-line no-unused-expressions
      mockAPIList.vehicle && vehicleYearsHandler(this);

      // eslint-disable-next-line no-unused-expressions
      mockAPIList.vehicle && vehicleMakesHandler(this);

      // eslint-disable-next-line no-unused-expressions
      mockAPIList.vehicle && vehicleModelsHandler(this);

      // eslint-disable-next-line no-unused-expressions
      mockAPIList.vehicle && vehicleTransmissionsHandler(this);

      // eslint-disable-next-line no-unused-expressions
      mockAPIList.vehicle && vehicleCylindersHandler(this);

      // eslint-disable-next-line no-unused-expressions
      mockAPIList.vehicle && vehicleBodyStylesHandler(this);

      // eslint-disable-next-line no-unused-expressions
      mockAPIList.vehicle && vehicleDetailsByTransmissionHandler(this);

      // eslint-disable-next-line no-unused-expressions
      mockAPIList.vehicle && vehicleDetailsByBodyStyleHandler(this);

      // eslint-disable-next-line no-unused-expressions
      mockAPIList.vehicle && vehicleDetailsBySeqNumberHandler(this);

      createQuoteHandler(this);
      amendQuoteHandler(this);
      emailQuoteHandler(this);
      buyQuoteHandler(this);

      Object.keys(mockAPIRouteConfig).map(
        (key) =>
          !mockAPIList[key] &&
          this.passthrough(mockAPIRouteConfig[key].path, [
            mockAPIRouteConfig[key].verb,
          ]),
      );

      this.passthrough(
        process.env.REACT_APP_MAINTENANCE_WINDOW_API_URL.split(`?`)[0],
      );

      this.passthrough(BASE_ADDRESS_SEARCH_URL);

      this.passthrough(VSS_PASS_THROUGH_URL);

      this.passthrough(
        `${process.env.REACT_APP_QUOTE_URL}/**${process.env.REACT_APP_BUY_QUOTE_API_URL}`,
      );

      this.passthrough(EMAIL_PASS_THROUGH_URL);

      this.passthrough(ANALYTICS_PASS_THROUGH_URL);
      this.passthrough(ANALYTICS_SMETRICS_PASS_THROUGH_URL);
      this.passthrough(`${process.env.REACT_APP_WEBCHAT_REST_API_URL}/**`);
    },
  });

  mockServer.logging = process.env.REACT_APP_MIRAGE_LOGGING;
  return mockServer;
}
