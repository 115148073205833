export const disabledUnitTypes = [
  'GPO BOX',
  'RSD',
  'RMS',
  'PO BOX',
  'RMB',
  'Locked Bag',
  'Mail Service',
  'Private Bag',
];

export const COMMUNICATION_PREFERENCE = {
  EMAIL: 'email',
  POST: 'post',
};

export const MARKETING_CONSENT = {
  YES: 'Contact Ok',
  NO: 'Do Not Contact',
};

export const POSTAL_STATES_AND_TERRITORIES = {
  QLD: 'QLD',
  NSW: 'NSW',
  VIC: 'VIC',
  SA: 'SA',
  WA: 'WA',
  TAS: 'TAS',
  ACT: 'ACT',
  NT: 'NT',
};
