import { ENTHUSIAST_DRIVER_PAGE, VEHICLE_CONDITION_PAGE } from './urlConstants';
import { CONTACT_NUMBER_URL } from './phoneConstants';

export const JEOPARDY_TYPE = {
  HARD: 'HARD',
  REFERRAL_SOFT: 'REFERRAL_SOFT',
  SOFT_JEOPARDY: 'SOFT_JEOPARDY',
  IMMEDIATE_SOFT_JEOPARDY: 'IMMEDIATE_SOFT_JEOPARDY',
  SOFT_JEOPARDY_SUM_INSURED_EXCEED_MAX_VALUE:
    'SOFT_JEOPARDY_SUM_INSURED_EXCEED_MAX_VALUE',
};

export const HARD_JEOPARDY_IRREPARABLE = {
  jeopardyType: JEOPARDY_TYPE.HARD,
  jeopardyReasons: [
    'Your vehicle has pre-existing damage and we are unable to offer cover.',
  ],
  prevLocation: VEHICLE_CONDITION_PAGE,
};

export const IMMEDIATE_SOFT_JEOPARDY_RESTORABLE = {
  jeopardyType: JEOPARDY_TYPE.IMMEDIATE_SOFT_JEOPARDY,
  jeopardyReasons: ['We need more information about you or your vehicle.'],
  prevLocation: VEHICLE_CONDITION_PAGE,
};

export const IMMEDIATE_SOFT_REFERRAL_JEOPARDY_ENTHUSIAST_DRIVER = {
  jeopardyType: JEOPARDY_TYPE.REFERRAL_SOFT,
  jeopardyReasons: ['Shannons only insure Motor Enthusiasts.'],
  prevLocation: ENTHUSIAST_DRIVER_PAGE,
};

export const SOFT_JEOPARDY_REASON_VEHICLE_USAGE = `There are certain type of business use we will not accept (including but not limited to couriers, delivery drivers, ridesharing, rental or hire use). If you are using your vehicle for any other type of business use, please contact us on ${CONTACT_NUMBER_URL} to see if we can assist.`;

export const HARD_JEOPARDY_REASON_DRIVER_HISTORY =
  'We are unable to provide cover due to the disclosed driving or claims history.';

export const SOFT_JEOPARDY_REASON_CLAIMS_HISTORY =
  'We need more information about you or your vehicle.';
export const SOFT_JEOPARDY_REASON_SUM_INSURED_EXCEEDS_MAX_VALUE =
  'Sum Insured Exceeds Max Vehicle Valuation';

export const HARD_JEOPARDY_REASON_KIT_CAR = `The type of vehicle you are trying to insure is not available online - please call us to discuss on ${CONTACT_NUMBER_URL}.`;
export const HARD_JEOPARDY_REASON_PARKING =
  'We are unable to provide cover due to your vehicle’s parking situation.';
export const HARD_JEOPARDY_REASON_BANKRUPTCY =
  'We are unable to provide cover due to the disclosed bankruptcy.';
export const HARD_JEOPARDY_REASON_CRIMINAL =
  'We are unable to provide cover due to the disclosed criminal history.';
export const HARD_JEOPARDY_REASON_INSURANCE_HISTORY =
  'We are unable to provide cover due to the disclosed insurance history.';
