import { Grid, GridCol, GridRow } from 'suncorp-styleguide-react-components';
import PropTypes from 'prop-types';
import { ProgressIndicator } from './ProgressIndicator';
import { ProgressTooltip } from './ProgressTooltip';
import { PROGRESS_BAR_STEPS } from '../../../constants/progressBarConstants';

const ProgressBar = ({ step, title, quoteFlowProgress }) => (
  <Grid className="sg-u-marginTop--x0point5">
    <GridRow
      className="sg-Grid-row--positionMiddle sg-u-marginAuto"
      style={{ maxWidth: '950px' }}
    >
      <GridCol span={{ default: 12, medium: 12, large: 12, xlarge: 12 }}>
        <div>
          <ProgressTooltip step={step} quoteFlowProgress={quoteFlowProgress} />
          <span className="sg-u-marginLeft--x3 sg-u-visuallyhiddenSmall">
            {title}
          </span>
        </div>
        <Grid>
          <GridRow>
            <ProgressIndicator
              thisItemProgress={1}
              enabled={step >= PROGRESS_BAR_STEPS.FIND_YOUR_CAR}
            />
            <ProgressIndicator
              thisItemProgress={2}
              enabled={step >= PROGRESS_BAR_STEPS.MODS_ACCESSORIES}
            />
            <ProgressIndicator
              thisItemProgress={3}
              enabled={step >= PROGRESS_BAR_STEPS.CAR_DETAILS}
            />
            <ProgressIndicator
              thisItemProgress={4}
              enabled={step >= PROGRESS_BAR_STEPS.DRIVER_DETAILS}
            />
            <ProgressIndicator
              thisItemProgress={5}
              enabled={step >= PROGRESS_BAR_STEPS.YOUR_QUOTE}
            />
          </GridRow>
        </Grid>
      </GridCol>
    </GridRow>
  </Grid>
);
ProgressBar.propTypes = {
  step: PropTypes.number,
  title: PropTypes.string,
  quoteFlowProgress: PropTypes.number,
};

export default ProgressBar;
