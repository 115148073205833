import {
  TECH_ERROR_PAGE,
  TIMEOUT_PAGE,
  JEOPARDY_PAGE,
  BUSINESS_ERROR_PAGE,
  RETRIEVE_QUOTE_PAGE,
} from '../constants/urlConstants';
import loadable from '../utils/loadable';

const globalFlowRouteConfig = {
  [TIMEOUT_PAGE]: {
    previousPage: undefined,
    nextPage: undefined,
    Component: loadable(() =>
      import('../pages/HelperPages/SessionTimeOutPage'),
    ),
    description: 'Session Timeout',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [TECH_ERROR_PAGE]: {
    previousPage: undefined,
    nextPage: undefined,
    Component: loadable(() => import('../pages/HelperPages/TechErrorPage')),
    description: 'Tech Error',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
  [JEOPARDY_PAGE]: {
    nextPage: undefined,
    Component: loadable(() => import('../pages/HelperPages/JeopardyPage')),
    description: 'Jeopardy Page',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
  [BUSINESS_ERROR_PAGE]: {
    previousPage: undefined,
    nextPage: undefined,
    Component: loadable(() => import('../pages/HelperPages/BusinessErrorPage')),
    description: 'Business Error Page',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
  [RETRIEVE_QUOTE_PAGE]: {
    previousPage: undefined,
    nextPage: undefined,
    Component: loadable(() => import('../pages/RetrieveQuotePage/index')),
    description: 'Retrieve Quote Page',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
};

export default globalFlowRouteConfig;
