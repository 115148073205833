import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Page } from 'suncorp-styleguide-react-components';
import createRoutes from './utils/createRoutes';
import routeConfig from './routeConfig';
import AppHeader from './components/organisms/Header';
import AppFooter from './components/organisms/Footer';
import { useSetExperienceFunction } from './hooks/experiences';
import TestHelpers from './components/organisms/TestHelpers';
import AnalyticsPageHandler from './components/organisms/AnalyticsPageHandler';
import JeopardyProvider from './utils/jeopardyProvider';
import { useMaintenanceWindow } from './hooks/maintenanceWindow';
import { useSessionTimeout } from './hooks/sessionTimeout';
import { TIMEOUT_PAGE } from './constants/urlConstants';
import WebChat from './components/molecules/Webchat';

const App = () => {
  useMaintenanceWindow();
  useSetExperienceFunction();
  useSessionTimeout();

  const dispatch = useDispatch();
  const { sessionId } = useSelector((state) => state.sessionDetails);

  useEffect(() => {
    if (window.location.pathname !== TIMEOUT_PAGE) {
      dispatch.configurationData.retrieveConfigurationAsync({
        sessionId,
        pathname: window.location.pathname,
      });
      dispatch.configurationData.retrieveWebchatAvailabilityAsync();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <BrowserRouter>
      <React.StrictMode>
        <Page>
          {process.env.REACT_APP_ANALYTICS_SWITCH === 'on' ? (
            <AnalyticsPageHandler />
          ) : (
            <></>
          )}
          <AppHeader />
          <JeopardyProvider>
            <Switch>{createRoutes(routeConfig)}</Switch>
            {process.env.REACT_APP_TEST_HELPERS === 'true' ? (
              <TestHelpers />
            ) : (
              <></>
            )}
            <WebChat />
          </JeopardyProvider>
          <AppFooter />
        </Page>
      </React.StrictMode>
    </BrowserRouter>
  );
};

export default App;
