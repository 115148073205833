import { REGISTRATION_NUMBER_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const registrationNumberPageAnalytics = {
  urlPattern: REGISTRATION_NUMBER_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      { pageName: 'buy_started', flowType: 'buy' },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    if (store.vehicleDetails.registrationNumber === 'ADVISE') {
      dataLayer.dataModel.isCarRegistrationNotKnown = 'Yes';
    } else {
      delete dataLayer.dataModel.isCarRegistrationNotKnown;
    }
  },
};

export default registrationNumberPageAnalytics;
