import {
  GridRow,
  FlexCell,
  Flextable,
} from 'suncorp-styleguide-react-components';
import PropTypes from 'prop-types';

const PolicyStartDateSection = ({ policyStartDate }) => {
  const psd = new Date(policyStartDate);
  return (
    <GridRow spacing="between" className="sg-u-marginTop--x3">
      <Flextable>
        <FlexCell className="sg-u-noPadding">
          <div className="sg-Type--heading">Policy start date</div>
          <div style={{ marginTop: '5px' }} className="sg-Type--muted">
            {`0${psd.getDate()}`.slice(-2)}/{`0${psd.getMonth() + 1}`.slice(-2)}
            /{psd.getFullYear()}
          </div>
        </FlexCell>
      </Flextable>
    </GridRow>
  );
};

PolicyStartDateSection.propTypes = {
  policyStartDate: PropTypes.string,
};

export default PolicyStartDateSection;
