import { amendQuoteMapper } from './amendQuoteMapper';
import createApiInstance from '../../utils/createApiInstance';

export const callAmendQuoteApi = async (state, token) => {
  const requestBody = amendQuoteMapper(state);
  const { sessionId } = state.sessionDetails;
  // TODO: temp code until the quoteID and real mapper are developed
  const { quoteId } = requestBody.data.attributes.quoteDetails;
  const response = await createApiInstance({
    baseURL: `${process.env.REACT_APP_QUOTE_URL}/${quoteId}`,
    method: 'PUT',
    additionalHeaders: {
      'X-Session-ID': sessionId,
      ...(token !== undefined && { 'X-ReCaptcha-Token': token }),
    },
    requestBody,
  });
  return response;
};
