import { addressSearchInfoInitialState } from '../models/addressSearchInfo';
import { configurationInitialState } from '../models/configurationData';
import { enthusiastDriverInitialState } from '../models/enthusiastDriver';
import { driverDetailsInitialState } from '../models/driverDetails';
import { jeopardyInfoInitialState } from '../models/jeopardyDetails';
import { policyHolderDetailsInitalState } from '../models/policyHolderDetails';
import { quoteDetailsInitialState } from '../models/quoteDetails';
import { vehicleDetailsInitialState } from '../models/vehicleDetails';
import { vehicleSearchInfoInitialState } from '../models/vehicleSearchInfo';
import { coverDetailsState } from '../models/coverDetails';

export const resetReduxStore = (dispatch) => {
  dispatch?.addressSearchInfo?.setAddressSearchInfo(
    addressSearchInfoInitialState,
  );
  dispatch?.configurationData?.setConfigurationData(configurationInitialState);
  dispatch?.enthusiastDriver?.setEnthusiastDriver(enthusiastDriverInitialState);
  dispatch?.driverDetails?.setDriverDetails(driverDetailsInitialState);
  dispatch?.jeopardyDetails?.updateJeopardyDetails(jeopardyInfoInitialState);
  dispatch?.policyHolderDetails?.setPolicyHolderDetails(
    policyHolderDetailsInitalState,
  );
  dispatch?.coverDetails?.setCoverDetails(coverDetailsState);
  dispatch?.quoteDetails?.setQuoteDetails(quoteDetailsInitialState);
  dispatch?.vehicleDetails?.setVehicleDetails(vehicleDetailsInitialState);
  dispatch?.vehicleSearchInfo?.setVehicleSearchInfo(
    vehicleSearchInfoInitialState,
  );
};
