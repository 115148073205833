import {
  Grid,
  GridRow,
  GridCol,
  Icon,
  Link,
} from 'suncorp-styleguide-react-components';
import { useSelector } from 'react-redux';
import { CONTACT_URL } from '../../../../constants/urlConstants';
import {
  CONTACT_NUMBER_URL,
  MOBILE_URL,
} from '../../../../constants/phoneConstants';
import useDeviceDetect from '../../../../hooks/useDeviceDetect';
import {
  getWebAnalytics,
  getDataLayer,
} from '../../../../analytics/dataLayerUtils';
import { launchWebChat } from '../../../molecules/Webchat';
import './index.css';

const HelpPanel = () => {
  const { isMobile } = useDeviceDetect();
  const { isChatAvailable } = useSelector((state) => state.configurationData);
  return (
    <div>
      <hr className="sg-Type--horizontalRule sg-u-noMargin" />
      <Grid>
        <GridRow>
          {isChatAvailable ? (
            <GridCol span={12}>
              <Link
                {...(!isMobile && { target: '_blank' })}
                className="sg-Nav-link sg-u-block sg-u-noBorder sg-Type--colourDark sg-u-flex--middle"
                data-testid="help-panel-webchat"
                id="help-panel-webchat"
                onClick={() => {
                  getWebAnalytics().publish(
                    'live_chat_tracking',
                    getDataLayer(),
                  );
                  launchWebChat();
                }}
              >
                <Icon
                  className="Icon--xlarge sg-u-marginRight--x0point5"
                  id="speechIcon"
                  name="speech"
                  color="dark"
                />
                <div>
                  <span className="sg-Type--size18 sg-u-block sg-Type--bold">
                    Live Chat
                  </span>
                  <span className="sg-Type--size13">Talk to us online</span>
                </div>
              </Link>
            </GridCol>
          ) : (
            <GridCol span={12}>
              <span className="sg-Nav-link sg-u-block sg-u-noBorder shannons-webchat-muted sg-u-flex--middle">
                <Icon
                  className="Icon--xlarge sg-u-marginRight--x0point5"
                  id="speechIcon"
                  name="speech"
                  color="dark"
                />
                <div className="sg-Type--colourDark">
                  <span className="sg-Type--size18 sg-u-block sg-Type--bold">
                    Live Chat
                  </span>
                  <span className="sg_Type--size13">Currently unavailable</span>
                </div>
              </span>
            </GridCol>
          )}
        </GridRow>
        <GridRow>
          <GridCol span={12}>
            <Link
              href={isMobile ? MOBILE_URL : CONTACT_URL}
              {...(!isMobile && { target: '_blank' })}
              className="sg-Nav-link sg-u-block sg-u-noBorder sg-Type--colourDark sg-u-flex--middle"
              data-testid="help-panel-link"
              id="help-panel-link"
              onClick={() => {
                getWebAnalytics().publish(
                  'click_to_call_tracking',
                  getDataLayer(),
                );
              }}
            >
              <Icon
                className="Icon--xlarge sg-u-marginRight--x0point5"
                id="phoneIcon"
                name="phone"
                color="dark"
              />
              <div>
                <span className="sg-Type--size18 sg-u-block sg-Type--bold">
                  Call {CONTACT_NUMBER_URL}
                </span>
                <span className="sg-Type--size13">
                  Speak to us over the phone
                </span>
              </div>
            </Link>
          </GridCol>
        </GridRow>
      </Grid>
    </div>
  );
};

export default HelpPanel;
