import { SPECIFIED_MODIFICATIONS_ACCESSORIES_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const specAccModPageAnalytics = {
  urlPattern: SPECIFIED_MODIFICATIONS_ACCESSORIES_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      { pageName: 'car_details_specaccmod', flowType: 'quote' },
      store,
    );
  },
};

export default specAccModPageAnalytics;
