import {
  Grid,
  GridRow,
  GridCol,
  Text,
  Dialog,
} from 'suncorp-styleguide-react-components';
import { useSelector } from 'react-redux';
import { COMPREHENSIVE_CAR_INSURANCE } from '../../../constants/coverDetailsConstants';
import { getDisplayPremium } from '../../../utils/quoteSchedulePageUtils';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import QuoteNumberSection from './quoteNumberSection';
import InsuranceTypeSection from './insuranceTypeSection';
import CarModelSection from './carModelSection';
import PremiumAndFrequencySection from './premiumAndFrequencySection';
import PolicyStartDateSection from './policyStartDateSection';
import AddressSection from './addressSection';
import ListedDriversSection from './listedDriversSection';
import CoverageSection from './coverageSection';

const BuyFlowQuoteSummaryPanel = () => {
  const { isMobile } = useDeviceDetect();
  const { quoteNumber, premium, paymentFrequency, policyStartDate } =
    useSelector((state) => state.quoteDetails);
  const { oneLineStreetAddress, postcodeSuburbState } = useSelector(
    (state) => state.addressSearchInfo,
  );
  const {
    year,
    make,
    model,
    transmissionDesc,
    cylinders,
    bodyStyle,
    description,
  } = useSelector(
    (state) => state.vehicleSearchInfo.searchResults.vehicleResult,
  );

  const vehicleYearMakeModel = `${year} ${make} ${model}`;
  const vehicleDescription = `${vehicleYearMakeModel} ${transmissionDesc} ${cylinders} ${bodyStyle}`;

  const { annualPremium, monthlyPremium } = premium;
  const coverDetails = useSelector((state) => state.coverDetails);
  const { coverType } = coverDetails;
  const { additionalDrivers } = useSelector((state) => state.driverDetails);
  const isComprehensive = /CAR/.test(coverType);

  return (
    <Grid className="sg-u-bgNeutral--light sg-u-boxShadow--medium">
      <GridRow
        className="sg-u-paddingTop--x3 sg-Grid-row--positionMiddle sg-u-padding sg-u-marginAuto"
        style={{ maxWidth: '1050px' }}
      >
        <GridCol
          className="sg-Grid-col--alignCenter sg-u-marginAuto"
          span={{
            default: 12,
            small: 12,
            medium: 12,
            large: 4,
            xlarge: 4,
          }}
        >
          {!isMobile && isComprehensive ? COMPREHENSIVE_CAR_INSURANCE : ''}
          <div>Quote number: {quoteNumber}</div>
        </GridCol>
        <GridCol
          className="sg-Grid-col--alignCenter sg-u-marginAuto sg-u-paddingTop sg-u-paddingBottom"
          span={{
            default: 12,
            small: 12,
            medium: 12,
            large: 4,
            xlarge: 4,
          }}
        >
          <span>
            <span
              className={`${
                isMobile ? 'sg-Type--heading2' : 'sg-Type--heading1'
              }`}
            >
              {getDisplayPremium(
                paymentFrequency,
                annualPremium,
                monthlyPremium,
              )}
            </span>
            <span>
              {' '}
              {/Annual/.test(paymentFrequency) ? 'yearly' : 'monthly'}
            </span>
          </span>
          <Text className="sg-u-block sg-Type--size12 sg-u-noWrap">
            incl GST and gov’t charges
          </Text>
        </GridCol>
        <GridCol
          className="sg-Grid-col--alignCenter sg-u-marginAuto"
          span={{
            default: 12,
            small: 12,
            medium: 12,
            large: 4,
            xlarge: 4,
          }}
        >
          <Dialog
            triggerText="View quote details"
            triggerAppearance="textOnly"
            triggerClassName="sg-Type--colourSecondary"
            appearance="lite"
            type="modal"
            width="500px"
            bodyClassName="sg-u-padding"
          >
            <QuoteNumberSection quoteNumber={quoteNumber} />
            <Grid className="sg-u-noPaddingTop sg-u-paddingLeft--x4 sg-u-paddingRight--x4">
              <GridRow
                spacing="between"
                className="sg-u-marginTop--x2 sg-u-paddingBottom sg-u-borderSolid sg-u-borderWidth2 sg-u-borderNeutral sg-u-borderRightNone sg-u-borderLeftNone sg-u-borderTopNone"
              >
                <InsuranceTypeSection isComprehensive={isComprehensive} />
                <CarModelSection
                  vehicleDescription={vehicleDescription}
                  vehicleSecondLineDesc={description}
                />
                <PremiumAndFrequencySection
                  annualPremium={annualPremium}
                  monthlyPremium={monthlyPremium}
                  paymentFrequency={paymentFrequency}
                />
              </GridRow>
              <PolicyStartDateSection policyStartDate={policyStartDate} />
              <AddressSection
                oneLineStreetAddress={oneLineStreetAddress}
                postcodeSuburbState={postcodeSuburbState}
              />
              <ListedDriversSection additionalDrivers={additionalDrivers} />
              <CoverageSection coverDetails={coverDetails} />
            </Grid>
          </Dialog>
        </GridCol>
      </GridRow>
    </Grid>
  );
};
export default BuyFlowQuoteSummaryPanel;
