import {
  misapiMappings,
  MISAPI_PREEXISTING_DAMAGE_CONDITION,
} from '../../constants/misapiMappings';
import { isRustAndPreExistingDamage } from '../../utils/productUtils';

export const regoNumberSelector = (state) => {
  const { vehicleDetails } = state;
  return vehicleDetails.registrationNumber;
};

export const vehicleDetailsSelectorForCreateAndAmendQuote = (state) => {
  const {
    vehicleDetails,
    driverDetails,
    vehicleSearchInfo: {
      searchResults: { vehicleResult: { id } = {} } = {},
    } = {},
  } = state;

  const modificationDetails = [];
  if (vehicleDetails.modificationsAccessoriesList.length > 0) {
    vehicleDetails.modificationsAccessoriesList.forEach((modification) => {
      modificationDetails.push({
        modificationType: misapiMappings[modification.value],
        description: modification.details,
      });
    });
  }

  return {
    uniqueVehicleIdentifier: id,
    financed: /true/.test(vehicleDetails.isCarFinanced),
    noClaimBonus: misapiMappings[driverDetails.mainDriver.excessClaims],
    usage: {
      primaryUsage: misapiMappings[vehicleDetails.vehicleUsage],
    },
    dayParking: misapiMappings[vehicleDetails.parkingType],
    kmPerYear: misapiMappings[vehicleDetails.vehicleUsageFrequency],
    vehicleCondition: isRustAndPreExistingDamage(
      vehicleDetails.hasRustOrDamage,
      vehicleDetails.vehicleCondition,
    )
      ? MISAPI_PREEXISTING_DAMAGE_CONDITION
      : misapiMappings[vehicleDetails.vehicleCondition],
    hasRustOrDamage: /true/.test(vehicleDetails.hasRustOrDamage),
    modifications: modificationDetails,
  };
};
