import PropTypes from 'prop-types';

const InsuranceTypeSection = ({ isComprehensive }) => (
  <div className="sg-Type--heading6 sg-u-marginBottom sg-u-textCenter sg-u-width10of10">
    {isComprehensive ? 'COMPREHENSIVE CAR INSURANCE' : ''}
  </div>
);

InsuranceTypeSection.propTypes = {
  isComprehensive: PropTypes.bool,
};

export default InsuranceTypeSection;
