import { isEligibleForExcessOption } from '../../utils/vehicleUtils';

export const coverDetailsForBuyQuote = (state) => ({
  interestedParties: state.vehicleDetails.isCarFinanced
    ? {
        financeProvider: state.vehicleDetails.financeProvider,
      }
    : undefined,
});

export const coverDetailsSelectorForCreateAndAmendQuote = (state) => {
  const { coverDetails } = state;
  return {
    coverType: 'CAR',
    voluntaryExcess: isEligibleForExcessOption(
      state.vehicleDetails.vehicleUsageFrequency,
    )
      ? coverDetails.voluntaryExcess
      : '0',
    sumInsured: {
      agreedValue: coverDetails.sumInsured.agreedValue,
    },
    options: {
      roadsideAssist: coverDetails.options.roadsideAssist,
      hirecar: coverDetails.options.hirecar,
    },
  };
};
