import { v4 as uuidV4 } from 'uuid';

const getHeaders = (additionalHeaders) => ({
  // We do not send correlation ID - since it gets overridden by Axway for its own use
  'Content-Type': 'application/json',
  'X-Client-Version': '1.0',
  'X-Client-ID': 'a-004590-motor-online-shannons',
  'X-Request-ID': uuidV4(),
  'Cache-Control': 'no-cache',
  ...additionalHeaders,
});

const createApiInstance = async ({
  baseURL,
  timeout = process.env.REACT_APP_BASE_API_TIMEOUT,
  method,
  additionalHeaders,
  requestBody = {},
} = {}) => {
  const headers = getHeaders(additionalHeaders);
  const abortController = new AbortController();
  const id = setTimeout(() => abortController.abort(), timeout);
  let options = { headers, signal: abortController.signal };
  if (method !== 'GET') {
    options = {
      ...options,
      method,
      body: JSON.stringify(requestBody),
    };
  }

  let response = await fetch(baseURL, options);
  clearTimeout(id);
  if (!response.ok) {
    throw response;
  }
  response = await response.json();
  return response;
};

export default createApiInstance;
