import { MAIN_DRIVER_SUSPENSIONS_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const mainDriverSuspensionsPageAnalytics = {
  urlPattern: MAIN_DRIVER_SUSPENSIONS_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'driver_details_suspensions',
        flowType: 'buy',
      },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    dataLayer.dataModel.mainDriver = {
      ...dataLayer.dataModel.mainDriver,
      hasSuspensions:
        store.driverDetails?.mainDriver?.hasSuspensionOccurrences === 'true'
          ? 'Yes'
          : 'No',
      numberOfSuspensions:
        store.driverDetails?.mainDriver?.numberOfLicenceOccurrences,
    };
  },
};

export default mainDriverSuspensionsPageAnalytics;
