import {
  Grid,
  GridRow,
  GridCol,
  Main,
} from 'suncorp-styleguide-react-components';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TECH_ERROR_PAGE,
  QSP_PAGE,
  CONFIRMATION_PAGE,
} from '../../constants/urlConstants';
import ProgressBar from '../organisms/ProgressBar';
import { findProgressBarStep } from '../../utils/progressBarUtils';
import BuyFlowQuoteSummaryPanel from '../molecules/BuyFlowQuoteSummaryPanel';
import { isBuyFlowPage } from '../../utils/isBuyFlowPage';

const Layout = (props) => {
  const { history } = props;
  const { pathname } = history.location;
  const dispatch = useDispatch();
  const { quoteFlowProgress } = useSelector((state) => state.quoteDetails);

  // Tech Error logic
  const techErrorInfo = useSelector(
    (state) => state.sessionDetails?.techErrorInfo,
  );

  useEffect(() => {
    if (window.location.pathname !== TECH_ERROR_PAGE && techErrorInfo.errorCode)
      history.push(TECH_ERROR_PAGE);
  });

  useEffect(() => {
    if (quoteFlowProgress && quoteFlowProgress < progressBarStep)
      dispatch.quoteDetails.setQuoteFlowProgress(progressBarStep);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isQSP = pathname === QSP_PAGE;
  const isBuyFlow = isBuyFlowPage(window.location.pathname);
  const isConfirmation = pathname === CONFIRMATION_PAGE;
  const { step: progressBarStep, title: progressBarTitle } =
    findProgressBarStep(pathname);
  const gridSpan = { default: 12, medium: 12, large: 11, xlarge: 8 };

  return (
    <>
      {isBuyFlow && !isConfirmation && <BuyFlowQuoteSummaryPanel />}
      {isConfirmation ? (
        <Main appearance="fixed">
          {/* eslint-disable-next-line react/prop-types */}
          {props.children}
        </Main>
      ) : (
        <Main wide={false} className="sg-u-paddingTop--x0point5">
          {progressBarStep > 0 && (
            <ProgressBar
              history={history}
              step={progressBarStep}
              title={progressBarTitle}
              quoteFlowProgress={quoteFlowProgress}
            />
          )}
          {isQSP ? (
            <Grid className="sg-Main-column sg-u-marginTop--x2 sg-u-marginBottom--x8">
              {props.children}
            </Grid>
          ) : (
            <Grid className="sg-Main-column sg-u-marginTop--x6 sg-u-marginBottom--x6">
              <GridRow spacing="around">
                <GridCol span={gridSpan}>
                  {/* eslint-disable-next-line react/prop-types */}
                  {props.children}
                </GridCol>
              </GridRow>
            </Grid>
          )}
        </Main>
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  history: PropTypes.object,
};

export default Layout;
