import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const additionalDriverExtraDetailsPageAnalytics = {
  urlPattern: new RegExp('^/driver-(\\d)-extraDetails$', 'i'),
  pageViewHandler: ({ store }) => {
    const driverIndex = store.driverDetails.additionalDriverCurrentId + 2;
    updatePageNameWithProductInfo(
      { pageName: `driver_details_driver${driverIndex}`, flowType: 'buy' },
      store,
    );
  },
};

export default additionalDriverExtraDetailsPageAnalytics;
