import createApiInstance from '../../utils/createApiInstance';

export const callMaintenanceWindowAPI = async (requestUrl) => {
  const TIME_OUT = 5000;

  const response = await createApiInstance({
    baseURL: requestUrl,
    method: 'GET',
    timeout: TIME_OUT,
  });
  return response;
};
