export const PSD_CHANGED = 'CHANGED';
export const RATING_PROTECTED = 'Protected (65%)';
export const RATING_01 = 'Rating 01 (65%)';
export const RATING_03 = 'Rating 03 (45%)';

export const NCD_45_PERCENT = '45% No Claim Discount';
export const NCD_65_PERCENT = '65% No Claim Discount';
export const NCD_PROTECTED_PERCENT = '65% No Claim Discount Protected';

export const NCD_TO_RATING_MAPPING = {
  [NCD_PROTECTED_PERCENT]: RATING_PROTECTED,
  [NCD_65_PERCENT]: RATING_01,
  [NCD_45_PERCENT]: RATING_03,
};

export const QUOTE_UPDATED_REASONS = {
  AGREED_VALUE_CHANGED: 'agreedValueStatus',
  POLICY_START_DATE_PASSED: 'policyStartDateStatus',
  VEHICLE_CONDITION_CHANGED: 'vehicleCondition',
  VEHICLE_USAGE_FREQUENCY_CHANGED: 'vehicleUsageFrequency',
};

export const quoteUpdatedDetails = [
  {
    key: QUOTE_UPDATED_REASONS.AGREED_VALUE_CHANGED,
    value:
      "There has been a change in your vehicle's value since saving your quote and we have updated your amount covered.",
  },
  {
    key: QUOTE_UPDATED_REASONS.POLICY_START_DATE_PASSED,
    value:
      'Your originally chosen policy start date has now passed.\n' +
      "We have updated your policy's start date to today's date but you can choose a new date. We will recalculate your insurance price" +
      ' based on your new start date.',
  },
  {
    key: QUOTE_UPDATED_REASONS.VEHICLE_CONDITION_CHANGED,
    value:
      " We've detected a change in your vehicle details and have updated your amount covered.",
  },
  {
    key: QUOTE_UPDATED_REASONS.VEHICLE_USAGE_FREQUENCY_CHANGED,
    value:
      'Some of your details have changed and this may mean your quote as well as details such as your Excess, Amount Covered and Optional' +
      ' Extras have been updated.',
  },
];
