import { useEffect } from 'react';
import { TIMEOUT_PAGE } from '../constants/urlConstants';
import { callMaintenanceWindowAPI } from '../apis/maintenanceWindow';

export function useMaintenanceWindow() {
  function checkMaintenanceWindow() {
    callMaintenanceWindowAPI(process.env.REACT_APP_MAINTENANCE_WINDOW_API_URL)
      .then((response) => {
        const { maintenanceWindows } = response;
        if (
          maintenanceWindows &&
          maintenanceWindows[0].inMaintenance &&
          maintenanceWindows[0].urlToForward
        ) {
          window.location.href = maintenanceWindows[0].urlToForward;
        }
      })
      .catch(() => {});
  }

  useEffect(() => {
    const isOnTimeoutPage = window.location.pathname === TIMEOUT_PAGE;
    const isBetaEnv = window.location.host.startsWith('beta');
    if (!isOnTimeoutPage && !isBetaEnv) checkMaintenanceWindow();
  }, []);
}
