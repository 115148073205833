export const BASE_QUOTE_API_URL = 'https://api-uat.test.suncorp.com.au/stable7';
export const BASE_ADDRESS_SEARCH_URL = `https://api-uat.test.suncorp.com.au/stable1/address-search-service/**`;
export const VSS_PASS_THROUGH_URL = `${process.env.REACT_APP_MOTOR_API_BASE_URL}/**`;
export const VSS_YEARS_URL = `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years`;
export const VSS_MAKES_URL = `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years/:year/makes`;
export const VSS_MODELS_URL = `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years/:year/makes/:make/models`;
export const VSS_TRANSMISSIONS_URL = `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years/:year/makes/:make/models/:model/transmissions`;
export const VSS_BODYSTYLES_URL = `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years/:year/makes/:make/models/:model/bodystyles`;
export const VSS_CYLINDERS_URL = `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/years/:year/makes/:make/models/:model/cylinders`;
export const VSS_FIND_BY_BODY_URL = `${VSS_YEARS_URL}/:year/makes/:make/models/:model`;
export const VSS_FIND_BY_TRANSMISSION_URL = `${VSS_YEARS_URL}/:year/makes/:make/models/:model/transmissions/:transmission/details`;
export const VSS_FIND_BY_SEQNO_URL = `${process.env.REACT_APP_MOTOR_API_BASE_URL}/products/CAR/vehicles/:sequenceNo`;
export const EMAIL_PASS_THROUGH_URL = `${process.env.REACT_APP_QUOTE_URL}/**${process.env.REACT_APP_EMAIL_QUOTE_API_URL}`;
export const ANALYTICS_PASS_THROUGH_URL = `https://assets2.shannons.com.au/**`;
export const ANALYTICS_SMETRICS_PASS_THROUGH_URL = `https://smetrics.shannons.com.au/**`;

const mockAPIRouteConfig = {
  config: {
    path: process.env.REACT_APP_CONFIGURATION_DATA_URL,
    verb: 'get',
    defaultMock: false,
  },
  vehicle: {
    path: VSS_PASS_THROUGH_URL,
    verb: 'get',
    defaultMock: false,
  },
  createquote: {
    path: `${process.env.REACT_APP_QUOTE_URL}`,
    verb: 'post',
    defaultMock: false,
  },
  amendquote: {
    path: `${process.env.REACT_APP_QUOTE_URL}/**`,
    verb: 'put',
    defaultMock: false,
  },
  emailquote: {
    path: `${process.env.REACT_APP_QUOTE_URL}/**${process.env.REACT_APP_EMAIL_QUOTE_API_URL}`,
    verb: 'post',
    defaultMock: false,
  },
  buyquote: {
    path: `${process.env.REACT_APP_QUOTE_URL}/**${process.env.REACT_APP_BUY_QUOTE_API_URL}`,
    verb: 'post',
    defaultMock: false,
  },
  retrievequote: {
    path: `${process.env.REACT_APP_QUOTE_URL}${process.env.REACT_APP_RETRIEVE_QUOTE_API_URL}`,
    verb: 'post',
    defaultMock: false,
  },
};

export default mockAPIRouteConfig;
