import { useState, useEffect } from 'react';

export default function useDeviceDetect() {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const mobile = () => /Mobi|Android/i.test(navigator.userAgent);
    setMobile(mobile());
  }, []);

  return { isMobile };
}
