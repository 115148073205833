import { retrieveQuoteResponseMappings } from '../constants/retrieveQuoteResponseMappings';
import {
  MISAPI_PARKING_TYPE_ON_PROPERTY,
  MISAPI_PREEXISTING_DAMAGE_CONDITION,
  MISAPI_VEHICLE_USAGE_FREQUENCY_LAID_UP,
} from '../constants/misapiMappings';
import { PARKING_TYPE_ON_PROPERTY } from '../constants/productConstants';

export const vehicleDetailsInitialState = {
  isCarFinanced: '',
  vehicleCondition: '',
  isVehicleRegistered: '',
  hasRustOrDamage: '',
  hasModificationsAccessories: '',
  vehicleUsage: '',
  vehicleUsageFrequency: '',
  vehicleIdentificationNumber: '',
  registrationNumber: '',
  financeProvider: '',
  modificationsAccessoriesList: [],
  parkingType: '',
  kmPerYear: '',
  uniqueVehicleIdentifier: '',
};

export const vehicleDetails = {
  state: vehicleDetailsInitialState,
  reducers: {
    setVehicleDetails(state, payload) {
      return { ...state, ...payload };
    },
    setIsCarFinanced(state, payload) {
      return { ...state, isCarFinanced: payload };
    },
    setVehicleCondition(state, payload) {
      return { ...state, vehicleCondition: payload };
    },
    setIsVehicleRegistered(state, payload) {
      return { ...state, isVehicleRegistered: payload };
    },
    setHasModificationsAccessories(state, payload) {
      return { ...state, hasModificationsAccessories: payload };
    },
    setHasRustOrDamage(state, payload) {
      return { ...state, hasRustOrDamage: payload };
    },
    setVehicleUsage(state, payload) {
      return { ...state, vehicleUsage: payload };
    },
    setVehicleUsageFrequency(state, payload) {
      return { ...state, vehicleUsageFrequency: payload };
    },
    setVehicleIdentificationNumber(state, payload) {
      return { ...state, vehicleIdentificationNumber: payload };
    },
    setRegistrationNumber(state, payload) {
      return { ...state, registrationNumber: payload };
    },
    setFinanceProvider(state, payload) {
      return { ...state, financeProvider: payload };
    },
    setUniqueVehicleIdentifier(state, payload) {
      return { ...state, uniqueVehicleIdentifier: payload };
    },
    setModificationsAccessoriesList(state, payload) {
      return {
        ...state,
        modificationsAccessoriesList: payload,
      };
    },
    setParkingType(state, payload) {
      return {
        ...state,
        parkingType: payload,
      };
    },
    setKmPerYear(state, payload) {
      return {
        ...state,
        kmPerYear: payload,
      };
    },
    updateVehicleDetailsOnQuoteRetrieve(store, retrieveVehicleDetails) {
      const {
        kmPerYear,
        dayParking,
        vehicleCondition,
        modifications,
        financed,
        usage,
        uniqueVehicleIdentifier,
      } = retrieveVehicleDetails;

      const modificationsList = [];

      modifications.forEach((item) => {
        modificationsList.push({
          details: item.description,
          value: retrieveQuoteResponseMappings[item.modificationType],
        });
      });

      const vehicleUsageFreqVal = retrieveQuoteResponseMappings[kmPerYear];

      // isVehicleRegisteredVal is not returned from Protect, so the logic below is to set it to blank if it's a laid up cover (kmPerYear = "Laid Up").
      // When this value is blank, the Vehicle Registration page will be skipped.
      // For all other kmPerYear values, the Vehicle Registration page is displayed with Yes option preselected (as isVehicleRegisteredVal = 'true');
      let isVehicleRegisteredVal = 'true';
      if (kmPerYear === MISAPI_VEHICLE_USAGE_FREQUENCY_LAID_UP) {
        isVehicleRegisteredVal = '';
      }

      return {
        ...store,
        kmPerYear,
        uniqueVehicleIdentifier,
        isCarFinanced: financed?.toString(),
        modificationsAccessoriesList: modificationsList,
        vehicleUsage: retrieveQuoteResponseMappings[usage.primaryUsage],
        parkingType:
          dayParking === MISAPI_PARKING_TYPE_ON_PROPERTY
            ? PARKING_TYPE_ON_PROPERTY
            : dayParking,
        vehicleUsageFrequency: vehicleUsageFreqVal,
        isVehicleRegistered: isVehicleRegisteredVal,
        vehicleCondition: retrieveQuoteResponseMappings[vehicleCondition],
        hasModificationsAccessories:
          modifications.length > 0 ? 'true' : 'false',
        hasRustOrDamage:
          vehicleCondition === MISAPI_PREEXISTING_DAMAGE_CONDITION
            ? 'true'
            : 'false',
      };
    },
    resetVehicleDetails() {
      return { ...vehicleDetailsInitialState };
    },
  },
};
