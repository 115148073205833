import { emailQuoteMapper } from './emailQuoteMapper';
import createApiInstance from '../../utils/createApiInstance';

export const callEmailQuoteApi = async (state) => {
  const requestBody = emailQuoteMapper(state);
  const { quoteId } = state.quoteDetails;
  const response = await createApiInstance({
    baseURL: `${process.env.REACT_APP_QUOTE_URL}/${quoteId}${process.env.REACT_APP_EMAIL_QUOTE_API_URL}`,
    method: 'POST',
    additionalHeaders: {
      Accept: 'application/vnd.api+json',
    },
    requestBody,
  });
  return response;
};
