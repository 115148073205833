// TODO: once more files are using this, remove from code coverage ignore list

import _ from 'lodash';
import { loadDataLayer, storeDataLayer } from './dataLayerUtils';
import getEnsightenConfig from '../config/ensighten';
import { load } from '../utils/loader';

export const loadAndConfigureEnsightenBootstrap = () => {
  // Don't load the analytics if switched from configuration
  if (process.env.REACT_APP_ANALYTICS_SWITCH === 'off') {
    return Promise.resolve();
  }

  const { bootstrapUrl, dataLayer, analyticsSessionStorageKey } =
    getEnsightenConfig(process.env.REACT_APP_ENV);
  const ensightenDataLayer =
    loadDataLayer(analyticsSessionStorageKey) || dataLayer;

  // Set the dataLayer from session storage or config if not already present
  window.dataLayer = !_.isEmpty(window.dataLayer)
    ? window.dataLayer
    : ensightenDataLayer;

  // We store the analytics dataLayer object to session storage to
  // enable retrieval after refresh or after coming back from sun-pay
  storeDataLayer(window.dataLayer);

  return load([bootstrapUrl]);
};
