import { POLICY_HOLDER_BANKRUPTCY_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const policyHolderBankruptcyPageAnalytics = {
  urlPattern: POLICY_HOLDER_BANKRUPTCY_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'policy_bankruptcy',
        flowType: 'quote',
      },
      store,
    );
  },
};

export default policyHolderBankruptcyPageAnalytics;
