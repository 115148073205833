import _ from 'lodash';
import {
  COMP_CLASSIC,
  CAR,
  CAR_NPS,
  NON_PRODUCT_SPECIFIC,
  COMP_EMERGING_CLASSIC,
} from '../constants/coverConstants';
import { QSP_PAGE } from '../constants/urlConstants';
import { BASE_PAGE_NAME, AVAILABLE_PRODUCT_NAMES } from '../config/ensighten';
import { getProductNameBasedOnStartDateAndVehicleAge } from '../utils/vehicleUtils';

export const getDataLayer = () => window.dataLayer;

export const getWebAnalytics = () => {
  // Prevent from breaking the app when analytics is missing
  if (!window.webAnalytics)
    return {
      publish: () => {},
    };
  return window.webAnalytics;
};

export const updateDataLayer = (data) => {
  if (!data) {
    return;
  }
  _.merge(getDataLayer(), data);
  storeDataLayer(getDataLayer());
};

export const storeDataLayer = (dataLayer) => {
  try {
    sessionStorage.setItem(
      'shannons-motor-analytics',
      JSON.stringify(dataLayer),
    );
  } catch (err) {
    // TODO: Add some error logging
  }
};

const updatePageNameandPageTypeWithFlow = (flowType) => ({
  basePageName: `${BASE_PAGE_NAME}:${flowType}`,
});

export const updatePageNameWithProductInfo = (pathname, store) => {
  resetDataLayerOnLocationChange();
  const existingInfo = getDataLayer().pageName.split(':');
  const newPageName = pathname?.pageName;
  const flowType = pathname?.flowType;
  let productName = CAR;
  if (
    store.quoteDetails.policyStartDate &&
    store.vehicleSearchInfo.searchResults.year
  ) {
    productName = getProductNameBasedOnStartDateAndVehicleAge(
      store.quoteDetails.policyStartDate,
      store.vehicleSearchInfo.searchResults.year,
    );
  }
  const product =
    newPageName === 'quote_retrieve' ? NON_PRODUCT_SPECIFIC : productName;
  const { basePageName } = updatePageNameandPageTypeWithFlow(flowType);
  if (newPageName && newPageName.indexOf('jeopardy_') === -1) {
    updateDataLayer({
      pageName: `${basePageName}:${newPageName}:${
        existingInfo[existingInfo.length - 1]
      }`,
    });
    if (product) {
      updateAnalyticsProduct(product);
    }
    updateDataLayerEvents(pathname, store);
    getWebAnalytics().publish('pageview', getDataLayer());
  }
};

export const loadDataLayer = (analyticsSessionStorageKey) => {
  try {
    const serialisedData = sessionStorage.getItem(analyticsSessionStorageKey);
    if (serialisedData === null) {
      return undefined;
    }
    return JSON.parse(serialisedData);
  } catch (err) {
    return undefined;
  }
};

export const resetDataLayerOnLocationChange = () => {
  const existingDataLayer = getDataLayer();
  const newDataLayer = {
    ...existingDataLayer,
    dataModel: {
      ...existingDataLayer.dataModel,
      intraPageElement: {},
    },
    errors: [],
    events: [],
    jeopardyMessages: [],
  };
  window.dataLayer = newDataLayer;
  storeDataLayer(newDataLayer);
};

const updateAnalyticsProduct = (product) => {
  const productName = getProductMapping(product);
  const modifiedPageName = getDataLayer().pageName.split(':');
  modifiedPageName[modifiedPageName.length - 1] = productName;
  updateDataLayer({
    pageName: modifiedPageName.join(':'),
    dataModel: {
      product: productName,
    },
    productSet: [{ name: productName }],
  });
};

export const updateDataLayerEvents = (pathname, store) => {
  let newEvents = [];
  const dataLayer = getDataLayer();
  if (
    pathname.pageName === 'dutyofdisclosure' ||
    pathname.pageName === 'quote_started'
  ) {
    newEvents = ['QUOTE_STARTED'];
  } else if (pathname.pageName === 'quote_completed') {
    newEvents = ['QUOTE_COMPLETED', 'QUOTE_PREMIUM'];
  } else if (pathname.pageName === 'quote_retrieved') {
    newEvents = ['QUOTE_RETRIEVED'];
  } else if (pathname.pageName === 'buy_started') {
    newEvents = ['BUY_STARTED'];
  } else if (pathname.pageName === 'buy_completed') {
    newEvents = ['BUY_COMPLETED', 'BUY_PREMIUM'];
    if (store.quoteDetails?.registrationOutcome === 'NEW_CUSTOMER') {
      newEvents.push('REGISTRATION_STARTED');
    }
  }
  if (newEvents.length) {
    dataLayer.events = newEvents;
    storeDataLayer(dataLayer);
  }
};

const getProductMapping = (product) => {
  const productNameMap = {
    [CAR_NPS]: AVAILABLE_PRODUCT_NAMES[CAR_NPS],
    [CAR]: AVAILABLE_PRODUCT_NAMES[CAR],
    [COMP_CLASSIC]: AVAILABLE_PRODUCT_NAMES[COMP_CLASSIC],
    [COMP_EMERGING_CLASSIC]: AVAILABLE_PRODUCT_NAMES[COMP_EMERGING_CLASSIC],
    [NON_PRODUCT_SPECIFIC]: AVAILABLE_PRODUCT_NAMES[NON_PRODUCT_SPECIFIC],
  };
  return productNameMap[product];
};

export const updatePageNameWithJeopardy = (
  pathname,
  jeopardyMessage,
  store,
) => {
  resetDataLayerOnLocationChange();
  const dataLayer = getDataLayer();
  const webAnalytics = getWebAnalytics();
  const newPageName = pathname?.pageName;
  if (newPageName === 'jeopardy_technical') {
    updateTechnicalJeopardyEvents(store, jeopardyMessage);
  } else if (newPageName === 'jeopardy_business') {
    jeopardyAnalyticsEvent(dataLayer, webAnalytics, jeopardyMessage);
  }
};

const updateTechnicalJeopardyEvents = (store, jeopardyMessage) => {
  const dataLayer = getDataLayer();
  const webAnalytics = getWebAnalytics();
  dataLayer.events = ['JEOPARDY_TECHNICAL'];
  dataLayer.pageName = convertToJeopardyPageName(
    getDataLayer().pageName,
    'technical',
  );
  dataLayer.jeopardyMessages = [jeopardyMessage];
  webAnalytics.publish('pageview', getDataLayer());
};

export const jeopardyAnalyticsEvent = (
  dataLayer,
  webAnalytics,
  message = [],
) => {
  dataLayer.events = ['JEOPARDY_BUSINESS'];
  dataLayer.jeopardyMessages = message;
  dataLayer.pageName = convertToJeopardyPageName(
    getDataLayer().pageName,
    'business',
  );
  webAnalytics.publish('pageview', getDataLayer());
};

const convertToJeopardyPageName = (pageName, jeopardyType) => {
  if (pageName && pageName.indexOf(':jeopardy_') !== -1) {
    return pageName;
  }
  return `${pageName}:jeopardy_${jeopardyType}`;
};

export const updateDataLayerOnAction = (action, store) => {
  const dataLayer = getDataLayer();
  const webAnalytics = getWebAnalytics();
  const newVal = getActionMapping(action.type, action.payload, store);
  if (newVal)
    updateDataLayer({
      dataModel: {
        ...newVal,
      },
    });
  if (
    action.type === 'quoteDetails/setQuoteDetails' &&
    store.getState().quoteDetails?.quoteNumber &&
    window.location.pathname === QSP_PAGE
  ) {
    webAnalytics.publish('motor-secapp | quote-changed', getDataLayer());
  }

  if (
    action.type === 'coverDetails/setVoluntaryExcess' &&
    action.payload !== store.getState().coverDetails?.voluntaryExcess &&
    window.location.pathname === QSP_PAGE
  ) {
    webAnalytics.publish('motor-secapp | excess-toggle', dataLayer);
  }
};

export const getActionMapping = (actionType, payload, store) => {
  switch (actionType) {
    case 'quoteDetails/setQuoteDetails':
      return {
        quoteNumber: payload.quoteNumber,
        payment: {
          term: payload.paymentFrequency === 'Annual' ? 'annually' : 'monthly',
        },
        premiumAmount: payload.premium?.annualPremium,
      };
    case 'coverDetails/setCoverDetails':
      return {
        insuredAmount: payload.sumInsured.agreedValue,
      };
    case 'vehicleDetails/setVehicleDetails':
      return {
        kmPerYear: payload.kmPerYear,
      };
    case 'coverDetails/setVoluntaryExcess':
      if (payload)
        return {
          excessAmount:
            Number(payload) +
            Number(store.getState().coverDetails?.standardExcess),
        };
      return null;
    default:
      return null;
  }
};
