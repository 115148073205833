export const vehicleInfoSelectorForPaymentAmendQuote = (state) => {
  const {
    vehicleSearchInfo: { searchResults },
  } = state;
  return {
    year: searchResults.year,
    make: searchResults.make,
    family: searchResults.model,
    variant: null,
  };
};
