export const OPTIONAL_EXTRAS_ROADSIDE_ASSIST =
  'Roadside assist - Enthusiast cover';
export const OPTIONAL_EXTRAS_ROADSIDE_ASSIST_ENTHUSIAST = 'Enthusiast';
export const OPTIONAL_EXTRAS_ROADSIDE_ASSIST_NOT_TAKEN =
  'Not Taken/Not Applied';
export const OPTIONAL_EXTRAS_HIRE_CAR =
  'Hire car after an event (unlimited days)';

export const COMPREHENSIVE_CAR_INSURANCE = 'Comprehensive Car Insurance';

export const AGREED_VALUE_STATUS_CHANGED = 'CHANGED';

export const roadsideCoverDetails = [
  { benefit: 'Flat battery', enthusiast: 'yes', type: 'tick' },
  {
    benefit: 'Emergency Fuel (Up to $10) ',
    enthusiast: 'yes',
    type: 'tick',
  },
  {
    benefit: 'Lost or locked keys ',
    enthusiast: '',
    type: 'tick',
  },
  {
    benefit: 'Towing excluding at a motor sport event ',
    enthusiast: 'Up to 150km',
    type: '',
  },
  {
    benefit: 'Towing at a motor sport event ',
    enthusiast: 'No cover',
    type: '',
  },
  {
    benefit: 'Caravan and Trailer Assistance ',
    enthusiast: 'yes',
    type: 'tick',
  },
  {
    benefit: 'Passenger Transportation ',
    enthusiast: 'Up to $60 per call out',
    type: '',
  },
  {
    benefit: 'Accommodation and/or Hire car ',
    details: '(If the breakdown is more than 100km from home)',
    enthusiast: 'yes',
    type: 'tick',
  },
  {
    benefit: 'Maximum number of Call Outs ',
    enthusiast: 'Unlimited',
    type: '',
  },
  {
    benefit: 'Number of Vehicles Covered ',
    enthusiast: 'One vehicle on your Shannons Motor Insurance policy',
    type: '',
  },
];
