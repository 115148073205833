import { VEHICLE_CONDITION_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const vehicleConditionPageAnalytics = {
  urlPattern: VEHICLE_CONDITION_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'car_details_vehiclecondition',
        flowType: 'quote',
      },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    dataLayer.dataModel.vehicleConditionOptionSelected =
      store.vehicleDetails?.vehicleCondition;
  },
};

export default vehicleConditionPageAnalytics;
