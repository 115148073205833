import { CONFIRMATION_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const confirmationPageAnalytics = {
  urlPattern: CONFIRMATION_PAGE,
  bindEventHandlers: [
    {
      eventName: 'click',
      selector: 'button[class*="sg-Btn"]',
      eventHandler: ({ dataLayer, webAnalytics, event }) => {
        const buttonTextContents = [
          'Login or Register',
          'Get a NSW Quote',
          'Leave your feedback',
        ];
        if (buttonTextContents.includes(event.target?.textContent)) {
          dataLayer.dataModel = {
            ...dataLayer.dataModel,
            linkName: `${event.target?.textContent} button`,
          };
          webAnalytics.publish('motor-quote | link-click', dataLayer);
        }
      },
    },
  ],
  pageViewHandler: ({ store, dataLayer }) => {
    const { policyNumber } = store.quoteDetails?.policyDetails;
    if (policyNumber) {
      const paymentType = store.quoteDetails?.paymentDetails?.paymentType;
      const { paymentFrequency } = store.quoteDetails;
      dataLayer.dataModel = {
        ...dataLayer.dataModel,
        policyNumber,
        payment: {
          term: paymentFrequency,
          type: paymentType,
        },
      };
      if (store.quoteDetails?.registrationOutcome === 'NEW_CUSTOMER') {
        dataLayer.dataModel.registration_loginType =
          'In_selfservice__registration_autorego';
      }
      updatePageNameWithProductInfo(
        { pageName: 'buy_completed', flowType: 'buy' },
        store,
      );
    }
  },
};

export default confirmationPageAnalytics;
