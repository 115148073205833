import { OPTIONAL_EXTRAS_ROADSIDE_ASSIST_NOT_TAKEN } from '../constants/coverDetailsConstants';

export const coverDetailsState = {
  coverType: 'CAR',
  standardExcess: '',
  voluntaryExcess: '0',
  agreedValueStatus: '',
  sumInsured: {
    agreedValue: 0,
    agreedValueMin: 0,
    agreedValueMax: 0,
  },
  options: {
    roadsideAssist: OPTIONAL_EXTRAS_ROADSIDE_ASSIST_NOT_TAKEN,
    hirecar: false,
  },
};

export const coverDetails = {
  state: coverDetailsState,
  reducers: {
    setCoverDetails(state, payload) {
      return { ...state, ...payload };
    },
    setCoverType(state, payload) {
      return { ...state, coverType: payload };
    },
    setStandardExcess(state, payload) {
      return { ...state, standardExcess: payload };
    },
    setVoluntaryExcess(state, payload) {
      return { ...state, voluntaryExcess: payload };
    },
    setSumInsured(state, payload) {
      return { ...state, sumInsured: payload };
    },
    setOptions(state, payload) {
      return { ...state, options: payload };
    },
    setAgreedValue(state, payload) {
      return {
        ...state,
        sumInsured: { ...state.sumInsured, agreedValue: payload },
      };
    },
    setAgreedValueStatus(state, payload) {
      return { ...state, agreedValueStatus: payload };
    },
    setHasHireCar(state, payload) {
      return { ...state, options: { ...state.options, hirecar: payload } };
    },
    setHasRoadSideAssist(state, payload) {
      return {
        ...state,
        options: { ...state.options, roadsideAssist: payload },
      };
    },
    resetSumInsured(state) {
      return {
        ...state,
        sumInsured: {
          agreedValue: null,
          agreedValueMin: 0,
          agreedValueMax: 0,
        },
      };
    },
  },
};
