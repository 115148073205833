export const TECH_ERROR = {
  BIND_ERROR_CODE: '100',
  CLIENT_ERROR_CODE: '400',
  SERVER_ERROR_CODE: '500',
};

export const MISAPI_ERRORS = {
  UNKNOWN: 'REQUEST_FAILED_FOR_AN_UNKNOWN_REASON',
};
export const REQUEST_SUCCESS = 200;

export const QUOTE_ALREADY_PAID = 'QUOTE_ALREADY_PAID';
export const QUOTE_NOT_FOUND = 'QUOTE_NOT_FOUND';
export const QUOTE_HAS_EXPIRED = 'QUOTE_HAS_EXPIRED';
export const QUOTE_NO_LONGER_AVAILABLE_ONLINE =
  'QUOTE_NO_LONGER_AVAILABLE_ONLINE';
