export const addressSearchInfoInitialState = {
  oneLineStreetAddress: '',
  postcodeSuburbState: '',
  addressInfo: {},
};

export const addressSearchInfo = {
  state: addressSearchInfoInitialState,
  reducers: {
    setAddressSearchInfo(state, payload) {
      return { ...state, ...payload };
    },
    updateOneLineStreetAddress(state, payload) {
      return { ...state, oneLineStreetAddress: payload };
    },
    updatePostcodeSuburbState(state, payload) {
      return { ...state, postcodeSuburbState: payload };
    },
    updateAddressInfo(state, payload) {
      return { ...state, addressInfo: payload };
    },
    updateAddressInfoOnQuoteRetrieve(store, retrieveLocationDetails) {
      const { structuredAddress, suburb, postcode, state } =
        retrieveLocationDetails.riskAddress;
      const {
        buildingName,
        subdwelling,
        streetNumber,
        streetSuffix,
        streetName,
      } = structuredAddress;
      const { floorLevelType, floorLevelNumber, flatUnitType, flatUnitNumber } =
        subdwelling;
      return {
        ...store,
        addressInfo: {
          matchedAddress: {
            suburb,
            postcode,
            state,
            addressId: retrieveLocationDetails.locationReference.lurn,
            addressQualityLevel:
              retrieveLocationDetails.locationReference.lurnScale,
            addressInBrokenDownForm: {
              buildingName,
              unitType: flatUnitType,
              unitNumber: flatUnitNumber,
              levelType: floorLevelType,
              levelNumber: floorLevelNumber,
              streetNumber1: streetNumber.houseNumber1,
              streetNumber2: streetNumber.houseNumber2,
              streetSuffixCode: streetSuffix,
              streetName,
            },
          },
        },
      };
    },
    updateAddressDetailsOnQuoteRetrieve(store, retrieveLocationDetails) {
      const { structuredAddress, suburb, postcode, state } =
        retrieveLocationDetails.riskAddress;
      const {
        buildingName,
        subdwelling,
        streetNumber,
        streetSuffix,
        streetName,
      } = structuredAddress;
      const { floorLevelType, floorLevelNumber, flatUnitType, flatUnitNumber } =
        subdwelling;

      const postcodeSuburbStateVal = `${suburb}, ${postcode}, ${state}`;
      const findOneLineAddress = () => {
        const levelType = floorLevelType || '';
        const levelNumber = floorLevelNumber || '';
        const unitType = flatUnitType || '';
        const unitNumber = flatUnitNumber || '';
        const typeNumberFormatter = (type, number) => {
          if (type !== '' && number === '') return `${type}, `;
          if (type !== '' && number !== '') return `${type} ${number}, `;
          return '';
        };
        const number = streetNumber.houseNumber1
          ? `${streetNumber.houseNumber1} `
          : '';
        const building = buildingName ? `${buildingName}, ` : '';
        const suffix = streetSuffix ? ` ${streetSuffix}` : '';

        return `${building}${typeNumberFormatter(
          levelType,
          levelNumber,
        )}${typeNumberFormatter(
          unitType,
          unitNumber,
        )}${number}${streetName}${suffix}`;
      };

      return {
        ...store,
        postcodeSuburbState: postcodeSuburbStateVal,
        oneLineStreetAddress: findOneLineAddress(),
      };
    },
  },
};
