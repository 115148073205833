import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, GridRow, GridCol } from 'suncorp-styleguide-react-components';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import 'suncorp-verint-chat-2/dist/chat-application.css';
import 'suncorp-verint-chat-2/dist/chat-config';
import WebChatTooltip from './WebChatTooltip';

export const launchWebChat = () => {
  window.prompt2 = true;
  window.logger = {
    log: () => {},
  };
  window.chatLaunch(
    { brand: 'shannons', iframeUrl: '/static/chat/login.html' },
    'CHAT_ONLY',
    window.chatConfiguration_serverId,
    true,
    window.location.href,
    'Guest',
    process.env.REACT_APP_WEBCHAT_LAUNCH_IDENTIFIER,
    null,
    false,
    process.env.REACT_APP_WEBCHAT_LAUNCH_IDENTIFIER,
  );
};

const WebChat = () => {
  const { isChatAvailable } = useSelector((state) => state.configurationData);
  const { isMobile } = useDeviceDetect();
  const { chatConfig } = window;
  window.chatConfig = {
    ...chatConfig,
    launchIdentifier: process.env.REACT_APP_WEBCHAT_LAUNCH_IDENTIFIER,
    restUrl: process.env.REACT_APP_WEBCHAT_REST_API_URL,
    mappingServiceHost: process.env.REACT_APP_WEBCHAT_API_HOST,
  };
  const [showWebChatTooltip, setShowWebChatTooltip] = useState(true);

  useEffect(() => {
    const scriptVerintChat = document.createElement('script');
    scriptVerintChat.src = 'static/chat/chat-application.js';
    document.body.appendChild(scriptVerintChat);
    setShowWebChatTooltip(false);
    setTimeout(() => {
      setShowWebChatTooltip(true);
    }, 3000);
    setTimeout(() => {
      setShowWebChatTooltip(false);
    }, 5000);
  }, []);

  return (
    <>
      {!isMobile && isChatAvailable && (
        <Grid
          className={`chat-launch-button-container ${
            showWebChatTooltip ? `sg-u-paddingRight--x1point5` : ``
          }`}
        >
          <GridRow>
            {showWebChatTooltip && (
              <GridCol>
                <WebChatTooltip />
              </GridCol>
            )}
            <GridCol span={3} className="sg-u-marginRight--x0point5">
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/mouse-events-have-key-events */}
              <button
                type="button"
                className="chat-launch-button chat-button-shannons"
                data-event-name="live-chat"
                data-testid="live-chat-testid"
                onClick={launchWebChat}
                // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                onMouseOver={() => {
                  setShowWebChatTooltip(true);
                }}
                // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                onMouseOut={() => {
                  setShowWebChatTooltip(false);
                }}
              />
            </GridCol>
          </GridRow>
        </Grid>
      )}
    </>
  );
};

export default WebChat;
