import { VEHICLE_FINANCE_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const vehicleFinancePageAnalytics = {
  urlPattern: VEHICLE_FINANCE_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'car_details_finance',
        flowType: 'quote',
      },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    dataLayer.dataModel.isVehicleFinanced =
      store.vehicleDetails.isCarFinanced === 'true' ? 'Yes' : 'No';
  },
};

export default vehicleFinancePageAnalytics;
