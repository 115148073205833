import createApiInstance from '../../utils/createApiInstance';

export const isChatAvailableApi = async () => {
  const requestUrl = process.env.REACT_APP_WEBCHAT_AVAILABILITY_URL;
  try {
    const response = await createApiInstance({
      baseURL: requestUrl,
      method: 'GET',
    });
    return response;
  } catch (error) {
    return error.response;
  }
};
