import { QSP_PAGE } from '../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../analytics/dataLayerUtils';
import { OPTIONAL_EXTRAS_ROADSIDE_ASSIST_ENTHUSIAST } from '../../constants/coverDetailsConstants';

export const getPage = (store) => {
  if (store.quoteDetails?.isRetrievedQuote) return 'quote_retrieved';
  return 'quote_completed';
};

export const getQuoteSourceType = (store) => {
  if (store.quoteDetails.isRetrievedQuote) {
    if (store.quoteDetails.isRetrievedQuoteByEmail) {
      return 'Quote_Retrieved_By_Email';
    }
    return 'Quote_Retrieved_By_Screen';
  }

  return 'QuoteCompleted';
};

export const quoteSchedulePageAnalytics = {
  urlPattern: QSP_PAGE,
  bindEventHandlers: [
    {
      eventName: 'click',
      selector: '#save-quote-btn',
      eventHandler: ({ dataLayer, webAnalytics }) => {
        webAnalytics.publish('motor-secapp | quote-emailed', dataLayer);
      },
    },
    {
      eventName: 'click',
      selector: 'input[type="radio"][name="frequency-radioBtns-qsp"]',
      eventHandler: ({ dataLayer, event, webAnalytics }) => {
        dataLayer.dataModel.payment.term =
          event.target.value === 'Annual' ? 'yearly' : 'monthly';
        webAnalytics.publish('motor-secapp | payment-term', dataLayer);
      },
    },
    {
      eventName: 'click',
      selector: '#hireCarSection, #roadSideAssistSection',
      eventHandler: ({ dataLayer, event, webAnalytics }) => {
        if (event.target.checked) {
          dataLayer.dataModel = {
            ...dataLayer.dataModel,
            extraAdded: event.target.getAttribute('data-analytics'),
          };
          webAnalytics.publish('motor-secapp | extras-added', dataLayer);
        } else {
          webAnalytics.publish('motor-secapp | toggle-off', dataLayer);
        }
      },
    },
  ],
  pageViewHandler: ({ store, dataLayer }) => {
    const quoteSourceType = getQuoteSourceType(store);
    const page = getPage(store);
    dataLayer.dataModel = {
      ...dataLayer.dataModel,
      quoteSourceType,
    };
    updatePageNameWithProductInfo({ pageName: page, flowType: 'quote' }, store);
  },

  onCaptureHandler: ({ dataLayer, store }) => {
    const extras = [];
    const { hirecar, roadsideAssist } = store.coverDetails.options;
    if (hirecar) {
      extras.push('hirecar');
    }
    if (roadsideAssist === OPTIONAL_EXTRAS_ROADSIDE_ASSIST_ENTHUSIAST) {
      extras.push('roadsideAssist');
    }
    dataLayer.dataModel.extras = extras;
  },
};
