import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const JeopardyContext = createContext();

const JeopardyProvider = ({ children }) => {
  // this state will be shared with all components
  const [openJeopardyModal, setOpenJeopardyModal] = useState(false);

  return (
    // this is the provider providing state
    <JeopardyContext.Provider
      value={{ openJeopardyModal, setOpenJeopardyModal }}
    >
      {children}
    </JeopardyContext.Provider>
  );
};

JeopardyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default JeopardyProvider;
