import { Route } from 'react-router-dom';
import Layout from '../components/templates/Layout';

export const createRoutes = (routeConfig) =>
  Object.keys(routeConfig).map((key) => {
    const path = key;
    const {
      Component,
      from,
      to,
      exact,
      pathPattern,
      hasProgressBar = true,
      hasQuoteNumber = false,
      hasCenterColumn = true,
      ...rest
    } = routeConfig[key];

    return (
      <Route
        key={key}
        exact={exact}
        path={pathPattern || path}
        render={(routeProps) => (
          <Layout
            hasProgressBar={hasProgressBar}
            hasQuoteNumber={hasQuoteNumber}
            hasCenterColumn={hasCenterColumn}
            {...routeProps}
          >
            <Component {...rest} {...routeProps} />
          </Layout>
        )}
      />
    );
  });

export default createRoutes;
