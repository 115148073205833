import { quoteDetailsSelectorForAmendQuote } from '../selectors/quoteDetailsSelector';
import { locationDetailsSelectorForCreateAndAmendQuote } from '../selectors/locationDetailsSelector';
import { vehicleDetailsSelectorForCreateAndAmendQuote } from '../selectors/vehicleDetailsSelector';
import { driverDetailsSelectorForPaymentAmendQuote } from '../selectors/driverDetailsSelector';
import { coverDetailsSelectorForCreateAndAmendQuote } from '../selectors/coverDetailsSelector';
import { policyHolderDetailsSelectorForPaymentAmendQuote } from '../selectors/policyHolderDetailsSelector';
import { vehicleInfoSelectorForPaymentAmendQuote } from '../selectors/vehicleInfoSelector';
import {
  COMMUNICATION_PREFERENCE_PAGE,
  SUNPAY_CALLBACK_PAGE,
} from '../../constants/urlConstants';
import { NEW_REQUEST } from '../../constants/paymentConstants';
import getEnsightenConfig from '../../config/ensighten';

export const amendQuoteForPaymentMapper = (state) => {
  const {
    vehicleDetails: {
      registrationNumber: regoNo,
      vehicleIdentificationNumber,
      financeProvider,
    } = {},
    quoteDetails: { id } = {},
    sessionDetails: { serialisationDatetime } = {},
  } = state;

  const { bootstrapUrl } = getEnsightenConfig(process.env.REACT_APP_ENV) || {};

  const {
    environment,
    pageName: unfilteredPageName,
    dataModel: { product: dataModelProduct } = {},
    metricsServer,
    siteID,
    rsID,
    brand,
    sessionSerialisationID,
  } = window.dataLayer || { dataLayer: {} };

  const pageName = unfilteredPageName?.replace(
    'policy_communicationtype',
    'payment',
  );
  const pageType = pageName?.split(':')?.splice(2)?.join(':');

  return {
    data: {
      type: 'CarQuote',
      id,
      attributes: {
        quoteDetails: quoteDetailsSelectorForAmendQuote(state),
        locationDetails: locationDetailsSelectorForCreateAndAmendQuote(state),
        vehicleDetails: {
          ...vehicleDetailsSelectorForCreateAndAmendQuote(state),
          ...{
            regoNo,
            vehicleIdentificationNumber,
          },
        },
        driverDetails: driverDetailsSelectorForPaymentAmendQuote(state),
        coverDetails: {
          ...coverDetailsSelectorForCreateAndAmendQuote(state),
          ...{
            interestedParties: {
              financeProvider,
            },
          },
        },
        policyHolderDetails:
          policyHolderDetailsSelectorForPaymentAmendQuote(state),
        callbackUrls: {
          successUrl: `${process.env.REACT_APP_BASE_URL}${SUNPAY_CALLBACK_PAGE}`,
          cancelUrl: `${process.env.REACT_APP_BASE_URL}${COMMUNICATION_PREFERENCE_PAGE}`,
          failureUrl: `${process.env.REACT_APP_BASE_URL}${COMMUNICATION_PREFERENCE_PAGE}`,
        },
        paymentContext: {
          requestMode:
            state.quoteDetails?.policyDetails?.paymentStatus === undefined
              ? NEW_REQUEST
              : state.quoteDetails?.policyDetails?.paymentStatus,
        },
        analyticsConfiguration: {
          sessionInfo: {
            serialisationDatetime,
            sessionSerialisationID,
          },
          ensightenConfiguration: {
            channel: 'web',
            bootstrapUrl,
            environment,
            pageType,
            dataModelProduct,
            metricsServer,
            siteID,
            rsID,
            pageName,
            brand,
          },
        },
        vehicleInfo: vehicleInfoSelectorForPaymentAmendQuote(state),
      },
    },
  };
};
