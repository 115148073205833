import {
  FORTYFIVE_PERCENT_NO_CLAIM_DISCOUNT,
  PARKING_TYPE_CARPORT,
  PARKING_TYPE_GARAGE,
  PARKING_TYPE_ON_PROPERTY,
  SIXTYFIVE_PERCENT_NO_CLAIM_DISCOUNT,
} from './productConstants';
import {
  VEHICLE_USAGE_FREQUENCY_CLUB_PLATE_USE,
  VEHICLE_USAGE_FREQUENCY_EXTREME_LIMITED_USE,
  VEHICLE_USAGE_FREQUENCY_LIMITED_USAGE,
  VEHICLE_USAGE_FREQUENCY_NOT_DRIVEN_LAID_UP,
  VEHICLE_USAGE_FREQUENCY_REGULAR_DRIVER,
  MOTOR_VEHICLE_USAGE_BUSINESS,
  MOTOR_VEHICLE_USAGE_HIRE,
  MOTOR_VEHICLE_USAGE_PRIVATE,
} from './vehicleConstants';

import {
  EXCESS_CLAIMS_NONE,
  EXCESS_CLAIMS_ONE,
  EXCESS_CLAIMS_THREE,
  EXCESS_CLAIMS_TWO,
} from './driverConstants';
import {
  EXCELLENT_CONDITION,
  FAIR_CONDITION,
  GOOD_CONDITION,
  RESTORABLE_CONDITION,
} from './vehicleCondition';
import {
  MODIFICATION_ACCESSORIES,
  MODIFICATION_BODY,
  MODIFICATION_HOT_ROD,
  MODIFICATION_MAJOR_PAINT,
  MODIFICATION_MAJOR_PERF,
  MODIFICATION_MINOR_PAINT,
  MODIFICATION_MINOR_PERF,
  MODIFICATION_SAFETY,
  MODIFICATION_SUSPENSION,
  MODIFICATION_WHEELS,
} from './modificationAccessoriesConstants';

export const PERMITTED_USE = ' - Permitted Use';

// Vehicle usage
export const MISAPI_VEHICLE_USAGE_FREQUENCY_LAID_UP = 'Laid Up';
export const MISAPI_VEHICLE_USAGE_FREQUENCY_LIMITED_USAGE =
  'Limited Use - Avg Maximum Twice per Week';
export const MISAPI_VEHICLE_USAGE_FREQUENCY_EXTREME_LIMITED_USE =
  'Extreme Limited Use - Avg Max. Twice per Month';
export const MISAPI_VEHICLE_USAGE_FREQUENCY_CLUB_PLATE_USE =
  'Club Permit Registration';
export const MISAPI_VEHICLE_USAGE_FREQUENCY_REGULAR_DRIVER = 'Regular Driver';
export const MISAPI_MOTOR_VEHICLE_USAGE_WEDDING =
  'Wedding Hire (Hobby/Occasional use)';

// Parking Type
export const MISAPI_PARKING_TYPE_ON_PROPERTY = 'On Your Property';

// Vehicle Condition
export const MISAPI_EXCELLENT_CONDITION_NO_RUST = 'Excellent - No Damage';
export const MISAPI_GOOD_CONDITION_NO_RUST = 'Good - No Damage';
export const MISAPI_FAIR_CONDITION_NO_RUST = 'Fair - No Damage';
export const MISAPI_RESTORABLE_CONDITION = 'Restorable';
export const MISAPI_PREEXISTING_DAMAGE_CONDITION = 'Pre-Existing Damage';

// Vehicle Modifications
export const MISAPI_MODIFICATION_ACCESSORIES = 'Other Accessories';
export const MISAPI_MODIFICATION_WHEELS = 'Wheel Modifications';
export const MISAPI_MODIFICATION_MINOR_PERF =
  'Engine Modifications Less Than $2000';
export const MISAPI_MODIFICATION_MAJOR_PERF =
  'Engine Modifications Greater Than $5000';
export const MISAPI_MODIFICATION_SUSPENSION = 'Suspension Modifications';
export const MISAPI_MODIFICATION_BODY = 'Body Modifications';
export const MISAPI_MODIFICATION_SAFTEY =
  'Safety/Non Performance Enhancing Mechanical/Electrical Mods';
export const MISAPI_MODIFICATION_MINOR_PAINT = 'Minor Custom Paint';
export const MISAPI_MODIFICATION_MAJOR_PAINT = 'Significant Custom Paint';
export const MISAPI_MODIFICATION_HOT_ROD = 'Hot Rod';

export const misapiMappings = {
  [MOTOR_VEHICLE_USAGE_BUSINESS]: MOTOR_VEHICLE_USAGE_BUSINESS + PERMITTED_USE,
  [MOTOR_VEHICLE_USAGE_HIRE]: MISAPI_MOTOR_VEHICLE_USAGE_WEDDING,
  [MOTOR_VEHICLE_USAGE_PRIVATE]: MOTOR_VEHICLE_USAGE_PRIVATE + PERMITTED_USE,
  [EXCESS_CLAIMS_NONE]: SIXTYFIVE_PERCENT_NO_CLAIM_DISCOUNT,
  [EXCESS_CLAIMS_ONE]: SIXTYFIVE_PERCENT_NO_CLAIM_DISCOUNT,
  [EXCESS_CLAIMS_TWO]: SIXTYFIVE_PERCENT_NO_CLAIM_DISCOUNT,
  [EXCESS_CLAIMS_THREE]: FORTYFIVE_PERCENT_NO_CLAIM_DISCOUNT,
  [VEHICLE_USAGE_FREQUENCY_NOT_DRIVEN_LAID_UP]:
    MISAPI_VEHICLE_USAGE_FREQUENCY_LAID_UP,
  [VEHICLE_USAGE_FREQUENCY_LIMITED_USAGE]:
    MISAPI_VEHICLE_USAGE_FREQUENCY_LIMITED_USAGE,
  [VEHICLE_USAGE_FREQUENCY_EXTREME_LIMITED_USE]:
    MISAPI_VEHICLE_USAGE_FREQUENCY_EXTREME_LIMITED_USE,
  [VEHICLE_USAGE_FREQUENCY_CLUB_PLATE_USE]:
    MISAPI_VEHICLE_USAGE_FREQUENCY_CLUB_PLATE_USE,
  [VEHICLE_USAGE_FREQUENCY_REGULAR_DRIVER]:
    MISAPI_VEHICLE_USAGE_FREQUENCY_REGULAR_DRIVER,
  [PARKING_TYPE_GARAGE]: PARKING_TYPE_GARAGE,
  [PARKING_TYPE_ON_PROPERTY]: MISAPI_PARKING_TYPE_ON_PROPERTY,
  [PARKING_TYPE_CARPORT]: PARKING_TYPE_CARPORT,
  [EXCELLENT_CONDITION]: MISAPI_EXCELLENT_CONDITION_NO_RUST,
  [GOOD_CONDITION]: MISAPI_GOOD_CONDITION_NO_RUST,
  [FAIR_CONDITION]: MISAPI_FAIR_CONDITION_NO_RUST,
  [RESTORABLE_CONDITION]: MISAPI_RESTORABLE_CONDITION,
  [MODIFICATION_ACCESSORIES]: MISAPI_MODIFICATION_ACCESSORIES,
  [MODIFICATION_WHEELS]: MISAPI_MODIFICATION_WHEELS,
  [MODIFICATION_MINOR_PERF]: MISAPI_MODIFICATION_MINOR_PERF,
  [MODIFICATION_MAJOR_PERF]: MISAPI_MODIFICATION_MAJOR_PERF,
  [MODIFICATION_SUSPENSION]: MISAPI_MODIFICATION_SUSPENSION,
  [MODIFICATION_BODY]: MISAPI_MODIFICATION_BODY,
  [MODIFICATION_SAFETY]: MISAPI_MODIFICATION_SAFTEY,
  [MODIFICATION_MINOR_PAINT]: MISAPI_MODIFICATION_MINOR_PAINT,
  [MODIFICATION_MAJOR_PAINT]: MISAPI_MODIFICATION_MAJOR_PAINT,
  [MODIFICATION_HOT_ROD]: MISAPI_MODIFICATION_HOT_ROD,
};
