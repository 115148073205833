import {
  Grid,
  GridRow,
  GridCol,
  Icon,
} from 'suncorp-styleguide-react-components';

const WebChatTooltip = () => (
  <div id="webchat-tooltip-box">
    <div
      className="sg-Dialog-tooltipArrow sg-u-borderWidth1 js-Arrow sg-u-bgSHANNONS18LightGreen sg-u-borderNeutral"
      style={{ left: '65%', top: '30%' }}
    />
    <Grid className="sg-Cell sg-u-bgSHANNONS18LightGreen sg-u-width-medium sg-u-borderWidth0 sg-u-radiusLarge  sg-u-paddingTop sg-u-paddingBottom sg-u-boxShadow--small">
      <GridRow>
        <Icon
          className="Icon--large sg-u-marginRight--x0point5 sg-Grid-col-2 sg-u-marginTop--x0point5"
          id="consultantIcon"
          name="consultant"
          color="text"
        />
        <GridCol id="webchat-tooltip-text">
          <span className="sg-Type--size18 sg-Type--colourText sg-u-noMargin sg-Type--bold sg-u-block">
            Need help?
          </span>
          <span className="sg-Type--size14 sg-Type--colourText sg-u-noMarginBottom">
            Chat with us online
          </span>
        </GridCol>
      </GridRow>
    </Grid>
  </div>
);

export default WebChatTooltip;
