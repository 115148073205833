export const jeopardyInfoInitialState = {
  jeopardyType: '',
  jeopardyReasons: [],
  prevLocation: '',
  delayedSoftJeopardyReasons: [],
  agreedValueMax: '',
};

export const createJeopardy = (
  jeopardyType,
  jeopardyReasons,
  prevLocation,
  delayedSoftJeopardyReasons,
  agreedValueMax,
) => ({
  jeopardyType,
  jeopardyReasons,
  prevLocation,
  delayedSoftJeopardyReasons,
  agreedValueMax,
});

export const jeopardyDetails = {
  state: jeopardyInfoInitialState,
  reducers: {
    updateJeopardyDetails: (state, payload) => ({ ...state, ...payload }),
    resetJeopardyDetails() {
      return { ...jeopardyInfoInitialState };
    },
  },
};
