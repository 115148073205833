import {
  Dialog,
  FlexCell,
  Flextable,
  Icon,
  Divider,
  Nav,
  NavWrapper,
  Button,
} from 'suncorp-styleguide-react-components';
import PropTypes from 'prop-types';
import { ProgressTooltipItem } from './ProgressTooltipItem';
import { PROGRESS_BAR_STEPS } from '../../../constants/progressBarConstants';

export const ProgressTooltip = ({ step, quoteFlowProgress }) => (
  <Dialog
    appearance="lite"
    type="tooltip"
    width={350}
    triggerNode={
      <Button
        id="progressTooltipTrigger"
        className="sg-u-paddingRight sg-u-paddingLeft sg-u-paddingTop--x0point5 sg-u-paddingBottom--x0point5"
      >
        <div>
          <Icon
            color="secondary"
            name="arrowBottom"
            size="xsmall"
            className="sg-u-marginRight--x0point5"
          />
          <span className="sg-Type--normal">STEP {step} OF 5</span>
        </div>
      </Button>
    }
  >
    <Flextable className="sg-u-marginTop--x1point5 sg-u-marginBottom--x2">
      <FlexCell className="sg-u-noPadding">
        <Icon
          color="text"
          name="quote"
          size="medium"
          className="sg-u-marginRight"
        />
      </FlexCell>
      <FlexCell className="sg-u-noPadding sg-u-flexGrow sg-Flextable-cell--alignMiddle">
        <div>MY QUOTE DETAILS</div>
      </FlexCell>
    </Flextable>
    <Divider color="default" className="sg-u-noMarginBottom" />
    <NavWrapper appearance="none">
      <Nav type="list">
        <ProgressTooltipItem
          thisItemStep={PROGRESS_BAR_STEPS.FIND_YOUR_CAR}
          currentStep={step}
          quoteFlowProgress={quoteFlowProgress}
        />
        <ProgressTooltipItem
          thisItemStep={PROGRESS_BAR_STEPS.MODS_ACCESSORIES}
          currentStep={step}
          quoteFlowProgress={quoteFlowProgress}
        />
        <ProgressTooltipItem
          thisItemStep={PROGRESS_BAR_STEPS.CAR_DETAILS}
          currentStep={step}
          quoteFlowProgress={quoteFlowProgress}
        />
        <ProgressTooltipItem
          thisItemStep={PROGRESS_BAR_STEPS.DRIVER_DETAILS}
          currentStep={step}
          quoteFlowProgress={quoteFlowProgress}
        />
        <ProgressTooltipItem
          thisItemStep={PROGRESS_BAR_STEPS.YOUR_QUOTE}
          currentStep={step}
          quoteFlowProgress={quoteFlowProgress}
        />
      </Nav>
    </NavWrapper>
  </Dialog>
);

ProgressTooltip.propTypes = {
  step: PropTypes.number,
  quoteFlowProgress: PropTypes.number,
};
