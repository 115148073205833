import { MARKETING_CONSENT_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const marketingConsentPageAnalytics = {
  urlPattern: MARKETING_CONSENT_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      { pageName: 'marketing_consent', flowType: 'buy' },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    dataLayer.dataModel.marketingConsent =
      store.policyHolderDetails.marketConsent === 'true' ? 'Yes' : 'No';
  },
};

export default marketingConsentPageAnalytics;
