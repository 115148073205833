import {
  Dialog,
  Header,
  Icon,
  Link,
} from 'suncorp-styleguide-react-components';
import './index.css';
import HelpPanel from './HelpPanel/index';
import { SHANNONS_LOGO_LINK } from '../../../constants/urlConstants';

const headerToolTip = () => (
  <Link href="#!" appearance="inverse" data-testid="header-tooltip">
    <div className="sg-Header-column">
      <ul className="sg-List-horizontal">
        <li className="sg-Type--size14 sg-u-uppercase sg-u-mediumAndSmallHidden">
          <a className="sg-Type--colourSecondary" href="#!">
            Need help?
          </a>
        </li>
        <Icon
          id="consultantIcon"
          name="consultant"
          color="secondary"
          size="small"
          className="sg-u-floatRight sg-u-marginLeft--x0point5 sg-u-marginRight--x0point5"
        />
      </ul>
    </div>
    <ul className="sg-List-horizontal">
      <li className="sg-Type--size12 sg-u-uppercase sg-u-clearfix sg-u-noPaddingBottom sg-u-noMargin sg-u-largeHidden">
        <a className="sg-Type--colourSecondary" href="#!">
          Help
        </a>
      </li>
    </ul>
  </Link>
);

const AppHeader = () => (
  <Header className="sg-Header">
    <nav className="sg-Header-content sg-u-noPaddingBottom">
      <div className="sg-Header-column">
        <img
          src={SHANNONS_LOGO_LINK}
          alt="Shannons Logo"
          className="sg-Logo sg-Logo--medium sg-u-marginRight"
          title="Shannons"
        />
        <span className="sg-Type--size12 sg-Type--colourText sg-u-uppercase sg-u-textLeft sg-u-largeHidden">
          Car Insurance
        </span>
      </div>
      <div className="sg-Header-column sg-u-mediumAndSmallHidden sg-u-width3of10">
        <span className="sg-u-textLeft sg-Type--size12 sg-Type--colourText sg-u-uppercase">
          Car Insurance
        </span>
      </div>
      <div className="sg-Header-column">
        <div data-testid="avatar" className="avatar-Header" />
      </div>
      <div className="sg-Header-column sg-u-textRight">
        <Dialog
          id="header-tooltip"
          appearance="lite"
          type="tooltip"
          triggerNode={headerToolTip}
          title="Got a question? We can help."
        >
          <HelpPanel />
        </Dialog>
      </div>
    </nav>
  </Header>
);

export default AppHeader;
