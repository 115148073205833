export const MODIFICATION_ACCESSORIES = 'Accessories';
export const MODIFICATION_WHEELS = 'Wheels';
export const MODIFICATION_MINOR_PERF = 'Minor performance modifications';
export const MODIFICATION_MAJOR_PERF = 'Major performance modifications';
export const MODIFICATION_SUSPENSION = 'Suspension';
export const MODIFICATION_BODY = 'Body modifications';
export const MODIFICATION_SAFETY =
  'Safety / Non-performance enhancing modification';
export const MODIFICATION_MINOR_PAINT =
  'Paint alterations / colour change (using OEM paint)';
export const MODIFICATION_MINOR_PAINT_DETAILS = (
  <p>
    Colour change or respray (using OEM paint codes ie factory colours)
    <br />
    OEM = Original Equipment Manufacturer
  </p>
);
export const MODIFICATION_MAJOR_PAINT = 'Significant custom paint';
export const MODIFICATION_MAJOR_PAINT_DETAILS = (
  <p>
    Non-OEM coded paint finishes or airbrushing e.g. pearl, flake, chameleon,
    candy or custom mix paints
    <br />
    OEM = Original Equipment Manufacturer
  </p>
);
export const MODIFICATION_HOT_ROD = 'Hot rod conversion or Replica vehicle';

export const modificationsAccessoriesHelpData = [
  {
    id: 'idAccessories',
    value: MODIFICATION_ACCESSORIES,
    details: `Stereo system, interior trim, CB radio, seats, snorkel, bull bar, tow bar etc`,
    classValue: `L`,
  },
  {
    id: 'idWheels',
    value: MODIFICATION_WHEELS,
    details: `Non standard wheel brand, size, colour`,
    classValue: `L`,
  },
  {
    id: 'idMinorPerformance',
    value: MODIFICATION_MINOR_PERF,
    details: `Modifications such as: Air filter, camshaft, carburettor, ECU, cylinder head, extractors/headers, exhaust etc`,
    classValue: `L`,
  },
  {
    id: 'idMajorPerformance',
    value: MODIFICATION_MAJOR_PERF,
    details: `Modifications such as: Chassis alterations, engine/gearbox conversions, turbo/super charger upgrades, extensive engine modifications or airbag/hydraulic suspension (excluding 4wd airbag suspension) etc`,
    classValue: `H`,
  },
  {
    id: 'idSuspension',
    value: MODIFICATION_SUSPENSION,
    details: `Lowered/raised, suspension kits, shock absorbers, coil overs, sway bars etc`,
    classValue: `L`,
  },
  {
    id: 'idBodyModifications',
    value: MODIFICATION_BODY,
    details: `Modifications such as: Body kit, body customising, spoiler, side skirts, bonnet scoop etc`,
    classValue: `L`,
  },
  {
    id: 'idSafety',
    value: MODIFICATION_SAFETY,
    details: `Sunroof, seat belts, upgraded brakes, LPG, upgraded cooling system or 4wd airbag suspension`,
    classValue: `S`,
  },
  {
    id: 'idPaintAlter',
    value: MODIFICATION_MINOR_PAINT,
    details: MODIFICATION_MINOR_PAINT_DETAILS,
    classValue: `L`,
  },
  {
    id: 'idSignificantCustomerPaint',
    value: MODIFICATION_MAJOR_PAINT,
    details: MODIFICATION_MAJOR_PAINT_DETAILS,
    classValue: `H`,
  },
  {
    id: 'idHotRodConversion',
    value: MODIFICATION_HOT_ROD,
    details: `Pre-1949 vehicle converted to a hot rod or a replica vehicle`,
    classValue: `E`,
  },
];
