import loadable from '../utils/loadable';
import {
  REGISTRATION_NUMBER_PAGE,
  VEHICLE_IDENTIFICATION_PAGE,
  MAIN_DRIVER_EXTRA_DETAILS_PAGE,
  MAIN_DRIVER_SUSPENSIONS_PAGE,
  POLICY_COMPANY_DETAILS_PAGE,
  MARKETING_CONSENT_PAGE,
  CAR_FINANCE_PROVIDER,
  POLICYHOLDER_ADDRESS_PAGE,
  CONTACT_DETAILS_PAGE,
  COMMUNICATION_PREFERENCE_PAGE,
  QSP_PAGE,
  CONFIRMATION_PAGE,
  ADDITIONAL_DRIVER_EXTRA_PAGES_ROUTE,
  SUNPAY_CALLBACK_PAGE,
  ADDITIONAL_DRIVER_CLAIMS_PAGES_ROUTE,
  ADDITIONAL_DRIVER_SUSPENSIONS_PAGES_ROUTE,
  MAIN_DRIVER_CLAIMS_PAGE,
} from '../constants/urlConstants';

const buyFlowRouteConfig = {
  [REGISTRATION_NUMBER_PAGE]: {
    previousPage: QSP_PAGE,
    nextPage: {
      HAS_LAID_UP_COVER: VEHICLE_IDENTIFICATION_PAGE,
      NOT_LAID_UP_COVER: MAIN_DRIVER_EXTRA_DETAILS_PAGE,
    },
    Component: loadable(() =>
      import('../pages/BuyFlowPages/RegistrationNumberPage'),
    ),
    description: 'Registration Number',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
  [VEHICLE_IDENTIFICATION_PAGE]: {
    previousPage: REGISTRATION_NUMBER_PAGE,
    nextPage: MAIN_DRIVER_EXTRA_DETAILS_PAGE,
    Component: loadable(() =>
      import('../pages/BuyFlowPages/VehicleIdentificationPage'),
    ),
    description: 'Vehicle Identification',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
  [MAIN_DRIVER_EXTRA_DETAILS_PAGE]: {
    previousPage: {
      VEHICLE_UNREGISTERED: VEHICLE_IDENTIFICATION_PAGE,
      VEHICLE_REGISTERED: REGISTRATION_NUMBER_PAGE,
    },
    nextPage: MAIN_DRIVER_CLAIMS_PAGE,

    Component: loadable(() =>
      import('../pages/BuyFlowPages/MainDriverDetailsExtraDetailsPage'),
    ),
    description: 'Main Driver Extra Details',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
  [MAIN_DRIVER_CLAIMS_PAGE]: {
    previousPage: MAIN_DRIVER_EXTRA_DETAILS_PAGE,
    nextPage: MAIN_DRIVER_SUSPENSIONS_PAGE,
    Component: loadable(() =>
      import('../pages/BuyFlowPages/MainDriverClaimsPage'),
    ),
    description: 'Main Driver Claims',
    exact: true,
    hasProgressBar: true,
    hasQuoteNumber: false,
  },
  [MAIN_DRIVER_SUSPENSIONS_PAGE]: {
    previousPage: MAIN_DRIVER_CLAIMS_PAGE,
    nextPage: ADDITIONAL_DRIVER_EXTRA_PAGES_ROUTE,
    Component: loadable(() =>
      import('../pages/BuyFlowPages/MainDriverSuspensionsPage'),
    ),
    description: 'Main Driver Suspensions',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
  [ADDITIONAL_DRIVER_EXTRA_PAGES_ROUTE]: {
    previousPage: MAIN_DRIVER_SUSPENSIONS_PAGE,
    nextPage: ADDITIONAL_DRIVER_CLAIMS_PAGES_ROUTE,
    Component: loadable(() =>
      import('../pages/BuyFlowPages/AdditionalDriverExtraDetailsPage'),
    ),
    description: 'Additional Driver Extra Details',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
  [ADDITIONAL_DRIVER_CLAIMS_PAGES_ROUTE]: {
    previousPage: ADDITIONAL_DRIVER_EXTRA_PAGES_ROUTE,
    nextPage: ADDITIONAL_DRIVER_SUSPENSIONS_PAGES_ROUTE,
    Component: loadable(() =>
      import('../pages/BuyFlowPages/AdditionalDriverClaimsPage'),
    ),
    description: 'Additional Driver Claims',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
  [ADDITIONAL_DRIVER_SUSPENSIONS_PAGES_ROUTE]: {
    previousPage: ADDITIONAL_DRIVER_CLAIMS_PAGES_ROUTE,
    nextPage: POLICY_COMPANY_DETAILS_PAGE,
    Component: loadable(() =>
      import('../pages/BuyFlowPages/AdditionalDriverSuspensionsPage'),
    ),
    description: 'Additional Driver Suspensions',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
  [POLICY_COMPANY_DETAILS_PAGE]: {
    previousPage: MAIN_DRIVER_EXTRA_DETAILS_PAGE,
    nextPage: MARKETING_CONSENT_PAGE,
    Component: loadable(() =>
      import('../pages/BuyFlowPages/PolicyCompanyDetailsPage'),
    ),
    description: 'Policy Company Details',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
  [MARKETING_CONSENT_PAGE]: {
    previousPage: POLICY_COMPANY_DETAILS_PAGE,
    nextPage: {
      CAR_FINANCED: CAR_FINANCE_PROVIDER,
      CAR_NOT_FINANCED: POLICYHOLDER_ADDRESS_PAGE,
    },
    Component: loadable(() =>
      import('../pages/BuyFlowPages/MarketingConsentPage'),
    ),
    description: 'Marketing Consent',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
  [CAR_FINANCE_PROVIDER]: {
    previousPage: MARKETING_CONSENT_PAGE,
    nextPage: POLICYHOLDER_ADDRESS_PAGE,
    Component: loadable(() =>
      import('../pages/BuyFlowPages/CarFinanceProviderPage'),
    ),
    description: 'Finance Providers',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
  [POLICYHOLDER_ADDRESS_PAGE]: {
    previousPage: {
      CAR_FINANCED: CAR_FINANCE_PROVIDER,
      CAR_NOT_FINANCED: MARKETING_CONSENT_PAGE,
    },
    nextPage: CONTACT_DETAILS_PAGE,
    Component: loadable(() =>
      import('../pages/BuyFlowPages/PolicyHolderAddressPage'),
    ),
    description: 'Policy Holder Address',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
  [CONTACT_DETAILS_PAGE]: {
    previousPage: POLICYHOLDER_ADDRESS_PAGE,
    nextPage: COMMUNICATION_PREFERENCE_PAGE,
    Component: loadable(() =>
      import('../pages/BuyFlowPages/ContactDetailsPage'),
    ),
    description: 'Contact Details',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
  [COMMUNICATION_PREFERENCE_PAGE]: {
    previousPage: CONTACT_DETAILS_PAGE,
    nextPage: SUNPAY_CALLBACK_PAGE,
    Component: loadable(() =>
      import('../pages/BuyFlowPages/CommunicationPreferencePage'),
    ),
    description: 'Communication Preference',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
  [SUNPAY_CALLBACK_PAGE]: {
    previousPage: COMMUNICATION_PREFERENCE_PAGE,
    nextPage: CONFIRMATION_PAGE,
    Component: loadable(() =>
      import('../pages/BuyFlowPages/SunpayCallbackPage'),
    ),
    description: 'Sunpay Callback',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
  [CONFIRMATION_PAGE]: {
    previousPage: COMMUNICATION_PREFERENCE_PAGE,
    nextPage: undefined,
    Component: loadable(() => import('../pages/BuyFlowPages/ConfirmationPage')),
    description: 'Confirmation Page',
    exact: true,
    hasProgressBar: false,
    hasQuoteNumber: false,
  },
};

export default buyFlowRouteConfig;
