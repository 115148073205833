import {
  updateDataLayer,
  updateDataLayerOnAction,
} from '../analytics/dataLayerUtils';

export const analyticsMiddleware = (store) => (next) => (action) => {
  const { sessionId } = store.getState().sessionDetails;
  updateDataLayer({
    sessionSerialisationID: sessionId,
  });
  updateDataLayerOnAction(action, store);
  /*
   * Publish the manual search analytics for every section make, model...
   */
  // publishVehicleManualSearchAnalytics(action, store);
  return next(action);
};
