import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const additionalDriverClaimsPageAnalytics = {
  urlPattern: new RegExp('^/driver-(\\d)-claimDetails$', 'i'),
  pageViewHandler: ({ store }) => {
    const driverIndex = store.driverDetails.additionalDriverCurrentId + 2;
    updatePageNameWithProductInfo(
      {
        pageName: `driver_details_claims_driver${driverIndex}`,
        flowType: 'buy',
      },
      store,
    );
  },
};

export default additionalDriverClaimsPageAnalytics;
