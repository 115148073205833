import {
  PRE_EXISTING_DAMAGE,
  RESTORABLE_CONDITION,
} from '../constants/vehicleCondition';
import {
  VEHICLE_USAGE_FREQUENCY_NOT_DRIVEN_LAID_UP,
  VEHICLE_USAGE_FREQUENCY_CLUB_PLATE_USE,
  VEHICLE_USAGE_FREQUENCY_EXTREME_LIMITED_USE,
  VEHICLE_USAGE_FREQUENCY_LIMITED_USAGE,
} from '../constants/vehicleConstants';
import { MISAPI_VEHICLE_USAGE_FREQUENCY_LAID_UP } from '../constants/misapiMappings';

const isRestorableVehicle = (vehicleCondition) =>
  vehicleCondition === RESTORABLE_CONDITION;

export const shouldSetVehicleUsageFrequencyToLaidUp = (vehicleDetails) => {
  const { isVehicleRegistered, vehicleCondition } = vehicleDetails;
  if (isRestorableVehicle(vehicleCondition)) return true;
  return (
    !isRestorableVehicle(vehicleCondition) && isVehicleRegistered === 'false'
  );
};

export const isLaidUpCoverQuote = (vehicleDetails) =>
  vehicleDetails.vehicleUsageFrequency ===
    VEHICLE_USAGE_FREQUENCY_NOT_DRIVEN_LAID_UP ||
  vehicleDetails.kmPerYear === MISAPI_VEHICLE_USAGE_FREQUENCY_LAID_UP;

export const vehicleUsageFrequencyListToHideHireCar = [
  VEHICLE_USAGE_FREQUENCY_NOT_DRIVEN_LAID_UP,
  VEHICLE_USAGE_FREQUENCY_CLUB_PLATE_USE,
  VEHICLE_USAGE_FREQUENCY_EXTREME_LIMITED_USE,
  VEHICLE_USAGE_FREQUENCY_LIMITED_USAGE,
];

export const shouldShowHireCarOptionalExtras = (vehicleDetails) =>
  !!vehicleDetails.vehicleUsageFrequency &&
  !vehicleUsageFrequencyListToHideHireCar.includes(
    vehicleDetails.vehicleUsageFrequency,
  );

export const isRustAndPreExistingDamage = (hasRust, vehicleConditon) =>
  /true/.test(hasRust) && vehicleConditon === PRE_EXISTING_DAMAGE;

export const isRetrieveQuoteLaidUpCover = (isRetrieveQuote, kmPerYear) =>
  isRetrieveQuote && kmPerYear === MISAPI_VEHICLE_USAGE_FREQUENCY_LAID_UP;
