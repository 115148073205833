import { MAIN_DRIVER_CLAIMS_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const mainDriverClaimsPageAnalytics = {
  urlPattern: MAIN_DRIVER_CLAIMS_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      { pageName: 'driver_details_claims', flowType: 'buy' },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    dataLayer.dataModel.mainDriver = {
      ...dataLayer.dataModel.mainDriver,
      hasClaimOccurrences:
        store.driverDetails?.mainDriver?.hasClaimOccurrences === 'true'
          ? 'Yes'
          : 'No',
      numberOfAccidentsTheftsClaims:
        store.driverDetails?.mainDriver?.claimOccurrences.length,
    };
  },
};

export default mainDriverClaimsPageAnalytics;
