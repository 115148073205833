import {
  ADDRESS_SEARCH_FLOW,
  ADDRESS_SEARCH_PARTIAL_MATCH_URL,
  ADDRESS_SEARCH_STRUCTURED_ADDRESS_URL,
} from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

const addressSearchFlowAnalytics = {
  urlPattern: ADDRESS_SEARCH_FLOW,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'car_details_parking',
        flowType: 'quote',
      },
      store,
    );
  },
  bindEventHandlers: [
    {
      eventName: 'click',
      selector: '.search-options--container li',
      eventHandler: ({ event, dataLayer, webAnalytics, store }) => {
        if (event.target.innerText === "Can't find my address") {
          dataLayer.dataModel.intraPageElement = {
            actionName: 'parking_details',
            actionValue: 'address not found',
          };
          webAnalytics.publish('motor-quote | page-element-click', dataLayer);
          updatePageNameWithProductInfo(
            {
              pageName: 'car_details_parking_addressnotfound',
              flowType: 'quote',
            },
            store,
          );
        }
      },
    },
  ],
  onCaptureHandler: ({ dataLayer, store }) => {
    let riskPostcode = '';
    if (store.addressSearchInfo?.addressInfo?.matchedAddress?.postcode) {
      riskPostcode =
        store.addressSearchInfo?.addressInfo?.matchedAddress?.postcode;
    }

    dataLayer.dataModel.riskPostcode = riskPostcode;
  },
};

const addressSearchPartialMatchAnalytics = {
  urlPattern: ADDRESS_SEARCH_PARTIAL_MATCH_URL,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'car_details_parking_partialmatch',
        flowType: 'quote',
      },
      store,
    );
  },
};

const addressSearchStructuredAddressAnalytics = {
  urlPattern: ADDRESS_SEARCH_STRUCTURED_ADDRESS_URL,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'car_details_parking_address_fullform',
        flowType: 'quote',
      },
      store,
    );
  },
};

export {
  addressSearchFlowAnalytics,
  addressSearchPartialMatchAnalytics,
  addressSearchStructuredAddressAnalytics,
};
