import { COMMUNICATION_PREFERENCE_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const communicationPreferencePageAnalytics = {
  urlPattern: COMMUNICATION_PREFERENCE_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      { pageName: 'policy_communicationtype', flowType: 'buy' },
      store,
    );
  },
  bindEventHandlers: [
    {
      eventName: 'click',
      selector: 'input[type="radio"][name="comms-preference-radioBtns"]',
      eventHandler: ({ event, dataLayer, webAnalytics }) => {
        dataLayer.dataModel.intraPageElement = {
          actionName: 'communication-type',
          actionValue: event.target.value,
        };
        webAnalytics.publish('motor-quote | page-element-click', dataLayer);
      },
    },
  ],
};

export default communicationPreferencePageAnalytics;
