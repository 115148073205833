import {
  FlexCell,
  Flextable,
  GridRow,
} from 'suncorp-styleguide-react-components';
import PropTypes from 'prop-types';

const QuoteNumberSection = ({ quoteNumber }) => (
  <GridRow
    spacing="between"
    className="sg-u-borderSolid sg-u-borderWidth1 sg-u-borderNeutral sg-u-borderRightNone sg-u-borderLeftNone sg-u-borderTopNone"
  >
    <Flextable>
      <FlexCell className="sg-u-noPadding sg-u-flexGrow sg-u-flex--centre">
        <p className="sg-u-paddingTop">QUOTE NUMBER {quoteNumber}</p>
      </FlexCell>
    </Flextable>
  </GridRow>
);

QuoteNumberSection.propTypes = {
  quoteNumber: PropTypes.string,
};

export default QuoteNumberSection;
