import { NO_CLAIM_BONUS_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const noClaimsBonusPageAnalytics = {
  urlPattern: NO_CLAIM_BONUS_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'driver_details_no_claim_bonus',
        flowType: 'quote',
      },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    dataLayer.dataModel.noclaimdiscount =
      store.driverDetails?.mainDriver?.excessClaims;
  },
};

export default noClaimsBonusPageAnalytics;
