import { PARKING_TYPE_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const parkingTypePageAnalytics = {
  urlPattern: PARKING_TYPE_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'car_details_parking_type',
        flowType: 'quote',
      },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    dataLayer.dataModel.parkingType = store.vehicleDetails?.parkingType;
  },
};

export default parkingTypePageAnalytics;
