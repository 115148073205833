import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { DISPLAY_DATE_FORMAT } from '../constants/dateConstants';

export const formatNumberIntoCurrency = (
  number,
  decimalPlaces = 2,
  useGrouping = true,
) => {
  const formatter = new Intl.NumberFormat('en', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    useGrouping,
  });
  return '$'.concat(formatter.format(number));
};

export const formatNumberBasic = (number) =>
  number === 0 ? '' : new Intl.NumberFormat('en-US').format(number);

export const formatISODateToDisplayDate = (isoFormattedDate) => {
  if (!isoFormattedDate) return '';
  const parsedISODate = parseISO(isoFormattedDate);
  return format(parsedISODate, DISPLAY_DATE_FORMAT);
};

export const policyHolderNames = (policyHolderDetails, driverDetails) => {
  if (policyHolderDetails?.policyInNameOfCompany === 'true')
    return policyHolderDetails.companyName;
  const firstNames = [
    driverDetails.mainDriver,
    ...driverDetails.additionalDrivers,
  ]
    .filter((driver) => driver.isPolicyHolder)
    .map((driver) => (driver?.firstname ? driver.firstname : driver.firstName));

  if (firstNames.length === 1) return firstNames[0];

  const lastFirstName = firstNames.pop();
  return firstNames.join(', ').concat(` and ${lastFirstName}`);
};
