import homePageAnalytics from '../pages/QuoteFlowPages/DutyOfDisclosurePage/homePageAnalytics';
import dutyOfDisclosurePageAnalytics from '../pages/QuoteFlowPages/DutyOfDisclosurePage/dutyOfDisclosurePageAnalytics';
import policyStartDatePageAnalytics from '../pages/QuoteFlowPages/PolicyStartDatePage/policyStartDatePageAnalytics';
import vehicleSearchPageAnalytics from '../pages/QuoteFlowPages/VehicleSearchPage/vehicleSearchPageAnalytics';
import enthusiastDriverPageAnalytics from '../pages/QuoteFlowPages/EnthusiastDriverPage/enthusiastDriverPageAnalytics';
import jeopardyPageAnalytics from '../pages/HelperPages/JeopardyPage/jeopardyPageAnalytics';
import modificationsAccessoriesPageAnalytics from '../pages/QuoteFlowPages/ModificationsAccessoriesPage/modificationsAccessoriesPageAnalytics';
import specAccModPageAnalytics from '../pages/QuoteFlowPages/SpecifiedModificationsPage/specAccModPageAnalytics';
import rustAndDamagePageAnalytics from '../pages/QuoteFlowPages/RustAndDamagePage/rustAndDamagePageAnalytics';
import vehicleConditionPageAnalytics from '../pages/QuoteFlowPages/VehicleConditionPage/vehicleConditionPageAnalytics';
import sumInsuredPageAnalytics from '../pages/QuoteFlowPages/SumInsuredPage/sumInsuredPageAnalytics';
import vehicleFinancePageAnalytics from '../pages/QuoteFlowPages/VehicleFinancePage/vehicleFinancePageAnalytics';
import vehicleRegistrationPageAnalytics from '../pages/QuoteFlowPages/VehicleRegistrationPage/vehicleRegistrationPageAnalytics';
import vehicleUsagePageAnalytics from '../pages/QuoteFlowPages/VehicleUsagePage/vehicleUsagePageAnalytics';
import vehicleUsageFreqPageAnalytics from '../pages/QuoteFlowPages/VehicleUsageFrequencyPage/vehicleUsageFreqPageAnalytics';
import parkingTypePageAnalytics from '../pages/QuoteFlowPages/ParkingTypePage/parkingTypePageAnalytics';
import {
  addressSearchFlowAnalytics,
  addressSearchPartialMatchAnalytics,
  addressSearchStructuredAddressAnalytics,
} from '../pages/QuoteFlowPages/AddressSearchFlow/addressSearchFlowAnalytics';
import mainDriverDetailsPageAnalytics from '../pages/QuoteFlowPages/MainDriverDetailsPage/mainDriverDetailsPageAnalytics';
import driverSummaryPageAnalytics from '../pages/QuoteFlowPages/DriverSummaryPage/driverSummaryPageAnalytics';
import additionalDriverDetailsPageAnalytics from '../pages/QuoteFlowPages/AdditionalDriverDetailsPage/additionalDriverDetailsPageAnalytics';
import policyHolderInsuranceCancellationPageAnalytics from '../pages/QuoteFlowPages/PolicyHolderInsuranceCancellationPage/policyHolderInsuranceCancellationPageAnalytics';
import policyHolderCriminalStatusPageAnalytics from '../pages/QuoteFlowPages/PolicyHolderCriminalStatusPage/policyHolderCriminalStatusPageAnalytics';
import policyHolderBankruptcyPageAnalytics from '../pages/QuoteFlowPages/PolicyHolderBankruptcyPage/policyHolderBankruptcyPageAnalytics';
import noClaimsBonusPageAnalytics from '../pages/QuoteFlowPages/NoClaimBonusPage/noClaimsBonusPageAnalytics';
import captureContactDetailsPageAnalytics from '../pages/QuoteFlowPages/CaptureContactDetailsPage/captureContactDetailsPageAnalytics';

// buy flow
import registrationNumberPageAnalytics from '../pages/BuyFlowPages/RegistrationNumberPage/registrationNumberPageAnalytics';
import vehicleIdentificationPageAnalytics from '../pages/BuyFlowPages/VehicleIdentificationPage/vehicleIdentificationPageAnalytics';
import mainDriverExtraDetailsPageAnalytics from '../pages/BuyFlowPages/MainDriverDetailsExtraDetailsPage/mainDriverExtraDetailsPageAnalytics';
import additionalDriverExtraDetailsPageAnalytics from '../pages/BuyFlowPages/AdditionalDriverExtraDetailsPage/additionalDriverExtraDetailsPageAnalytics';
import mainDriverClaimsPageAnalytics from '../pages/BuyFlowPages/MainDriverClaimsPage/mainDriverClaimsPageAnalytics';
import mainDriverSuspensionsPageAnalytics from '../pages/BuyFlowPages/MainDriverSuspensionsPage/mainDriverSuspensionsPageAnalytics';
import policyCompanyDetailsPageAnalytics from '../pages/BuyFlowPages/PolicyCompanyDetailsPage/policyCompanyDetailsPageAnalytics';
import marketingConsentPageAnalytics from '../pages/BuyFlowPages/MarketingConsentPage/marketingConsentPageAnalytics';
import policyHolderAddressPageAnalytics from '../pages/BuyFlowPages/PolicyHolderAddressPage/policyHolderAddressPageAnalytics';
import contactDetailsPageAnalytics from '../pages/BuyFlowPages/ContactDetailsPage/contactDetailsPageAnalytics';
import communicationPreferencePageAnalytics from '../pages/BuyFlowPages/CommunicationPreferencePage/communicationPreferencePageAnalytics';
import carFinanceProviderPageAnalytics from '../pages/BuyFlowPages/CarFinanceProviderPage/carFinanceProviderPageAnalytics';
import confirmationPageAnalytics from '../pages/BuyFlowPages/ConfirmationPage/confirmationPageAnalytics';
import additionalDriverClaimsPageAnalytics from '../pages/BuyFlowPages/AdditionalDriverClaimsPage/additionalDriverClaimsPageAnalytics';
import additionalDriverSuspensionsPageAnalytics from '../pages/BuyFlowPages/AdditionalDriverSuspensionsPage/additionalDriverSuspensionsPageAnalytics';

// QSP
import { quoteSchedulePageAnalytics } from '../pages/QuoteSchedulePage/quoteSchedulePageAnalytics';

// Retrieve quote page
import { retrieveQuotePageAnalytics } from '../pages/RetrieveQuotePage/retrieveQuotePageAnalytics';

// helper pages analytics
import techErrorPageAnalytics from '../pages/HelperPages/TechErrorPage/techErrorPageAnalytics';

const pageAnalytics = [
  homePageAnalytics,
  dutyOfDisclosurePageAnalytics,
  policyStartDatePageAnalytics,
  vehicleSearchPageAnalytics,
  enthusiastDriverPageAnalytics,
  jeopardyPageAnalytics,
  modificationsAccessoriesPageAnalytics,
  specAccModPageAnalytics,
  rustAndDamagePageAnalytics,
  vehicleConditionPageAnalytics,
  sumInsuredPageAnalytics,
  vehicleFinancePageAnalytics,
  vehicleRegistrationPageAnalytics,
  vehicleUsagePageAnalytics,
  vehicleUsageFreqPageAnalytics,
  parkingTypePageAnalytics,
  addressSearchFlowAnalytics,
  addressSearchPartialMatchAnalytics,
  addressSearchStructuredAddressAnalytics,
  mainDriverDetailsPageAnalytics,
  driverSummaryPageAnalytics,
  additionalDriverDetailsPageAnalytics,
  policyHolderCriminalStatusPageAnalytics,
  policyHolderInsuranceCancellationPageAnalytics,
  policyHolderBankruptcyPageAnalytics,
  noClaimsBonusPageAnalytics,
  quoteSchedulePageAnalytics,
  registrationNumberPageAnalytics,
  vehicleIdentificationPageAnalytics,
  mainDriverExtraDetailsPageAnalytics,
  additionalDriverExtraDetailsPageAnalytics,
  mainDriverClaimsPageAnalytics,
  mainDriverSuspensionsPageAnalytics,
  policyCompanyDetailsPageAnalytics,
  marketingConsentPageAnalytics,
  policyHolderAddressPageAnalytics,
  contactDetailsPageAnalytics,
  communicationPreferencePageAnalytics,
  carFinanceProviderPageAnalytics,
  confirmationPageAnalytics,
  retrieveQuotePageAnalytics,
  additionalDriverClaimsPageAnalytics,
  additionalDriverSuspensionsPageAnalytics,
  techErrorPageAnalytics,
  captureContactDetailsPageAnalytics,
];
export default pageAnalytics;
