import { JEOPARDY_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithJeopardy } from '../../../analytics/dataLayerUtils';
import { JEOPARDY_TYPE } from '../../../constants/jeopardyConstants';

export const jeopardyPageAnalytics = {
  urlPattern: JEOPARDY_PAGE,
  bindEventHandlers: [
    {
      eventName: 'click',
      selector: '#call-us-button',
      eventHandler: ({ dataLayer, webAnalytics }) => {
        webAnalytics.publish('click_to_call_tracking', dataLayer);
      },
    },

    {
      eventName: 'click',
      selector: '#call-us-link',
      eventHandler: ({ dataLayer, webAnalytics }) => {
        webAnalytics.publish('click_to_call_tracking', dataLayer);
      },
    },

    {
      eventName: 'click',
      selector: '#live-chat-button',
      eventHandler: ({ dataLayer, webAnalytics }) => {
        webAnalytics.publish('live_chat_tracking', dataLayer);
      },
    },

    {
      eventName: 'click',
      selector: '#live-chat-link',
      eventHandler: ({ dataLayer, webAnalytics }) => {
        webAnalytics.publish('live_chat_tracking', dataLayer);
      },
    },
  ],
  pageViewHandler: ({ store }) => {
    const { jeopardyDetails } = store;
    const { jeopardyReasons } = store.jeopardyDetails;
    const jeopardyMessage = jeopardyReasons;

    if (
      jeopardyDetails.jeopardyType === JEOPARDY_TYPE.SOFT_JEOPARDY &&
      jeopardyDetails.delayedSoftJeopardyReasons.length > 0
    ) {
      const jeopardyMessagesList = [];
      jeopardyDetails.delayedSoftJeopardyReasons.forEach((reason) =>
        jeopardyMessagesList.push(reason.code),
      );
      updatePageNameWithJeopardy(
        { pageName: 'jeopardy_business' },
        jeopardyMessagesList,
        store,
      );
    } else {
      updatePageNameWithJeopardy(
        { pageName: 'jeopardy_business' },
        jeopardyMessage,
        store,
      );
    }
  },
};

export default jeopardyPageAnalytics;
