import PropTypes from 'prop-types';

const CarModelSection = ({ vehicleDescription, vehicleSecondLineDesc }) => (
  <>
    <div className="sg-u-textCenter sg-u-width10of10">
      <p>{vehicleDescription}</p>
      <p>{vehicleSecondLineDesc}</p>
    </div>
  </>
);

CarModelSection.propTypes = {
  vehicleDescription: PropTypes.string,
  vehicleSecondLineDesc: PropTypes.string,
};

export default CarModelSection;
