/* eslint-disable */
import {
  YEAR,
  MAKE,
  MODEL,
  TRANSMISSION,
  CYLINDER,
  BODY_TYPE,
  VEHICLE_CATEGORY,
  VEHICLE_CATEGORY_RESTRICTED_DRIVER,
} from '../constants/vehicleSearchConstants';
import {
  EMERGING_CLASSIC_VEHICLE_MAX_AGE,
  MODERN_VEHICLE_MAX_AGE,
  MOTOR_VEHICLE_COVERAGE_MAX_AGE,
  RACING_OPTION_START_YEAR_MANUFACTURED, VEHICLE_USAGE_FREQUENCY_CLUB_PLATE_USE,
  VEHICLE_USAGE_FREQUENCY_EXTREME_LIMITED_USE,
  VEHICLE_USAGE_FREQUENCY_NOT_DRIVEN_LAID_UP,
  VEHICLE_USAGE_FREQUENCY_REGULAR_DRIVER,
} from '../constants/vehicleConstants';
import {
  PARKING_TYPE_CARPORT,
  PARKING_TYPE_ON_PROPERTY,
} from '../constants/productConstants';
import {CAR, COMP_CLASSIC, COMP_EMERGING_CLASSIC} from "../constants/coverConstants";

export const hasBlankTransmissionValue = (transmissions) => {
  if (!transmissions) return false;
  return transmissions.map((transmission) => transmission.code).includes('');
};

export const hasBlankCylinderValue = (cylinders) => {
  if (!cylinders) return false;
  return cylinders.map((cylinder) => cylinder.code).includes('');
};

export const hasBlankBodyStyleValue = (input) => {
  if (!input) return false;
  return input.map((bodyStyle) => bodyStyle.code).includes('');
};

const vehicleStateData = {
  [YEAR]: {
    year: '',
    make: '',
    model: '',
    transmission: '',
    cylinder: '',
    bodyType: '',
    vehicleResult: {},
  },
  [MAKE]: {
    make: '',
    model: '',
    transmission: '',
    cylinder: '',
    bodyType: '',
    vehicleResult: {},
  },
  [MODEL]: {
    model: '',
    transmission: '',
    cylinder: '',
    bodyType: '',
    vehicleResult: {},
  },
  [TRANSMISSION]: {
    transmission: '',
    cylinder: '',
    bodyType: '',
    vehicleResult: {},
  },
  [CYLINDER]: {
    cylinder: '',
    bodyType: '',
    vehicleResult: {},
  },
  [BODY_TYPE]: {
    bodyType: '',
    vehicleResult: {},
  },
};

export const resetVehicleStateData = (key, value) => {
  const vehicleState = vehicleStateData[key];
  Object.keys(vehicleState).forEach((item) => {
    vehicleState[item] = value;
  });
  return vehicleState;
};

export const resetFetchVehicleData = {
  [YEAR]: {
    makes: [],
    models: [],
    transmissions: [],
    cylinders: [],
    bodyTypes: [],
    details: [],
  },
  [MAKE]: {
    models: [],
    transmissions: [],
    cylinders: [],
    bodyTypes: [],
    details: [],
  },
  [MODEL]: {
    transmissions: [],
    cylinders: [],
    bodyTypes: [],
    details: [],
  },
  [TRANSMISSION]: {
    cylinders: [],
    bodyTypes: [],
    details: [],
  },
  [CYLINDER]: {
    bodyTypes: [],
    details: [],
  },
  [BODY_TYPE]: {
    details: [],
  },
};

export const isClassicVehicleCoverage = (year) => {
  const currentYear = new Date().getFullYear();
  return currentYear - Number(year) > MOTOR_VEHICLE_COVERAGE_MAX_AGE;
};

export const getVehicleDescription = (fetchedData = [], selected, key) => {
  const selectedItem = fetchedData.filter(
    (value) => value[key] === selected,
  )[0];
  return selectedItem ? selectedItem.desc : undefined;
};

export const vehicleCategoryToSkipEnthusiastDriver = [
  VEHICLE_CATEGORY.HIGH_RISK,
  VEHICLE_CATEGORY.NISSAN_HIGH_RISK1,
  VEHICLE_CATEGORY.NISSAN_HIGH_RISK2,
  VEHICLE_CATEGORY.PERFORMANCE,
  VEHICLE_CATEGORY.EXOTIC,
  VEHICLE_CATEGORY.CLASSIC,
];
export const getVehicleCategoriesAndVehicleUsageFrequencyCombinationThatSkipsEnthusiastDriver = () => {
  const vehicleCategory = [VEHICLE_CATEGORY.PRESTIGE, VEHICLE_CATEGORY.VANILLA];
  const vehicleUsageFrequency = [
  VEHICLE_USAGE_FREQUENCY_EXTREME_LIMITED_USE,
  VEHICLE_USAGE_FREQUENCY_CLUB_PLATE_USE,
  VEHICLE_USAGE_FREQUENCY_NOT_DRIVEN_LAID_UP]

  return {vehicleCategory, vehicleUsageFrequency}
}


export const shouldSkipEnthusiastDriverPage = (vehicleCategory, vehicleUsageFrequency) => {
  if(vehicleCategoryToSkipEnthusiastDriver.includes(vehicleCategory)) {
    return true
  }else{
    const vehicleCategoryAndFrequencyCombinationThatSkipsEnthusiastDriver =
      getVehicleCategoriesAndVehicleUsageFrequencyCombinationThatSkipsEnthusiastDriver();
    return vehicleCategoryAndFrequencyCombinationThatSkipsEnthusiastDriver.vehicleCategory.includes(vehicleCategory)
      && vehicleCategoryAndFrequencyCombinationThatSkipsEnthusiastDriver.vehicleUsageFrequency.includes(vehicleUsageFrequency)
  }

}

export const shouldShowRacingCover = (yearOfManufacture) =>
  yearOfManufacture < RACING_OPTION_START_YEAR_MANUFACTURED;

export const isEligibleForExcessOption = (vehicleUsageFrequency) =>
  vehicleUsageFrequency === VEHICLE_USAGE_FREQUENCY_REGULAR_DRIVER;

export const getVehicleAge = (policyStartDate, yearOfManufacture) => {
  const today = new Date(policyStartDate);
  return today.getFullYear() - yearOfManufacture;
};

export const isSameVehicle = (vehicleState, searchResults) =>
  vehicleState?.vehicleResult?.id === searchResults?.vehicleResult?.id;

export const shouldShowRestrictedDriverText = (vehicleCategory) =>
  Object.values(VEHICLE_CATEGORY_RESTRICTED_DRIVER).includes(vehicleCategory);

export const isModernVehicle = (policyStartDate, yearOfManufacture) =>
  getVehicleAge(policyStartDate, yearOfManufacture) <= MODERN_VEHICLE_MAX_AGE;

export const isEmergingVehicle = (policyStartDate, yearOfManufacture) =>
   getVehicleAge(policyStartDate, yearOfManufacture) > MODERN_VEHICLE_MAX_AGE &&
  getVehicleAge(policyStartDate, yearOfManufacture) <= EMERGING_CLASSIC_VEHICLE_MAX_AGE;

export const getProductNameBasedOnStartDateAndVehicleAge = (policyStartDate, yearOfManufacture) => {
  if (isEmergingVehicle(policyStartDate, yearOfManufacture)) {
    return COMP_EMERGING_CLASSIC;
  }
  if (!isModernVehicle(policyStartDate, yearOfManufacture)) {
    return COMP_CLASSIC;
  }
  return CAR;
}

export const isModernVehicleSumInsuredTooLargeForParkingType = (
  sumInsured,
  parkingType,
  vehicleAge,
) =>
  vehicleAge < MODERN_VEHICLE_MAX_AGE &&
  [PARKING_TYPE_CARPORT, PARKING_TYPE_ON_PROPERTY].includes(parkingType) &&
  sumInsured > 100000;

export const isModernVehicleSumInsuredTooLargeForDriverAge = (
  sumInsured,
  driverIsUnderTwentyFive,
  vehicleAge,
) =>
  vehicleAge < MODERN_VEHICLE_MAX_AGE &&
  driverIsUnderTwentyFive &&
  sumInsured > 50000;
