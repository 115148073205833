import { VEHICLE_USAGE_FREQUENCY } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const vehicleUsageFreqPageAnalytics = {
  urlPattern: VEHICLE_USAGE_FREQUENCY,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'car_details_frequency',
        flowType: 'quote',
      },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    dataLayer.dataModel.vehicleFrequency =
      store.vehicleDetails?.vehicleUsageFrequency;
  },
};

export default vehicleUsageFreqPageAnalytics;
