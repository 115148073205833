import { quoteDetailsSelectorForCreateQuote } from '../selectors/quoteDetailsSelector';
import { locationDetailsSelectorForCreateAndAmendQuote } from '../selectors/locationDetailsSelector';
import { driverDetailsSelectorForCreateAndAmendQuote } from '../selectors/driverDetailsSelector';
import { vehicleDetailsSelectorForCreateAndAmendQuote } from '../selectors/vehicleDetailsSelector';
import { policyHolderDetailsSelectorForCreateAndAmendQuote } from '../selectors/policyHolderDetailsSelector';
import { coverDetailsSelectorForCreateAndAmendQuote } from '../selectors/coverDetailsSelector';

export const createQuoteMapper = (state) => ({
  data: {
    type: 'CarQuote',
    attributes: {
      quoteDetails: quoteDetailsSelectorForCreateQuote(state),
      locationDetails: locationDetailsSelectorForCreateAndAmendQuote(state),
      vehicleDetails: vehicleDetailsSelectorForCreateAndAmendQuote(state),
      driverDetails: driverDetailsSelectorForCreateAndAmendQuote(state),
      coverDetails: { ...coverDetailsSelectorForCreateAndAmendQuote(state) },
      policyHolderDetails:
        policyHolderDetailsSelectorForCreateAndAmendQuote(state),
    },
  },
});
