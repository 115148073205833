import { NavItem } from 'suncorp-styleguide-react-components';
import PropTypes from 'prop-types';
import { stepTitle } from '../../../utils/progressBarUtils';

export const ProgressTooltipItem = ({
  thisItemStep,
  currentStep,
  quoteFlowProgress,
  handleOnClick,
}) => {
  const shouldNotDisplayMuted = currentStep > thisItemStep || currentStep === 5;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      id={`progressBar-tooltipItem-${thisItemStep}`}
      onClick={quoteFlowProgress >= thisItemStep ? handleOnClick : null}
    >
      <NavItem
        icon={{
          color:
            currentStep > thisItemStep || currentStep === 5
              ? 'correct'
              : 'neutral',
          name: 'tick',
          size: 'small',
          className: 'sg-u-marginRight',
        }}
        title={`${thisItemStep} - ${stepTitle[thisItemStep]}`}
        current={currentStep === thisItemStep}
        style={{
          cursor: 'default',
          pointerEvents: 'none',
        }}
        anchorClassName={
          shouldNotDisplayMuted ? '' : 'sg-Type--colourText sg-Type--muted'
        }
      />
    </div>
  );
};
ProgressTooltipItem.propTypes = {
  thisItemStep: PropTypes.number,
  currentStep: PropTypes.number,
  quoteFlowProgress: PropTypes.number,
  handleOnClick: PropTypes.func,
};
