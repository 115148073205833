import { marketingEmailMapper } from './marketingEmailMapper';
import createApiInstance from '../../utils/createApiInstance';

export const callMarketingEmail = async (state) => {
  const requestBody = marketingEmailMapper(state);
  const { quoteId } = state.quoteDetails;
  const response = await createApiInstance({
    baseURL: `${process.env.REACT_APP_QUOTE_URL}/${quoteId}${process.env.REACT_APP_MARKETING_EMAIL_API_URL}`,
    method: 'POST',
    additionalHeaders: {
      Accept: 'application/vnd.api+json',
    },
    requestBody,
  });
  return response;
};
