import createApiInstance from '../../utils/createApiInstance';

export const retrieveConfigurationApi = async (sessionId) => {
  const requestUrl = process.env.REACT_APP_CONFIGURATION_DATA_URL;
  const response = await createApiInstance({
    baseURL: requestUrl,
    method: 'GET',
    additionalHeaders: {
      'X-Session-ID': sessionId,
    },
  });
  return response.data.attributes.configurationEntries;
};
