import { retrieveConfigurationApi } from '../apis/configurationData';
import { isChatAvailableApi } from '../apis/chatAvailability';

export const configurationInitialState = {
  configurationEntries: [],
  isChatAvailable: false,
};

export const configurationData = {
  state: configurationInitialState,
  reducers: {
    setConfigurationData(state, payload) {
      return { ...state, ...payload };
    },
    updateConfigurationData: (state, payload) => ({
      ...state,
      configurationEntries: payload,
    }),
    updateIsChatAvailable: (state, payload) => ({
      ...state,
      isChatAvailable: payload,
    }),
  },
  effects: (dispatch) => ({
    async retrieveConfigurationAsync({ sessionId, pathname }) {
      try {
        const response = await retrieveConfigurationApi(sessionId);
        const mappedConfigData =
          response &&
          response.reduce((configEntries, element) => {
            configEntries[element.entryType] = {
              properties: element.properties,
              values: element.values,
            };
            return configEntries;
          }, {});

        dispatch.configurationData.updateConfigurationData(mappedConfigData);
      } catch (error) {
        dispatch.quoteDetails.handleTechError({
          error,
          prevLocation: pathname,
        });
      }
    },
    async retrieveWebchatAvailabilityAsync() {
      const isChatAvailableResponse = await isChatAvailableApi();
      const mappedIsChatAvailable =
        isChatAvailableResponse?.isChatAvailable === true;
      dispatch.configurationData.updateIsChatAvailable(mappedIsChatAvailable);
    },
  }),
};
