import { quoteDetailsSelectorForAmendQuote } from '../selectors/quoteDetailsSelector';
import { locationDetailsSelectorForCreateAndAmendQuote } from '../selectors/locationDetailsSelector';
import { vehicleDetailsSelectorForCreateAndAmendQuote } from '../selectors/vehicleDetailsSelector';
import { driverDetailsSelectorForCreateAndAmendQuote } from '../selectors/driverDetailsSelector';
import { coverDetailsSelectorForCreateAndAmendQuote } from '../selectors/coverDetailsSelector';
import { policyHolderDetailsSelectorForCreateAndAmendQuote } from '../selectors/policyHolderDetailsSelector';

export const amendQuoteMapper = (state) => ({
  // Request Body for create quote
  // Add redux-reselect if needed for this mapping.

  data: {
    type: 'CarQuote',
    id: state.quoteDetails.id,
    attributes: {
      quoteDetails: quoteDetailsSelectorForAmendQuote(state),
      locationDetails: locationDetailsSelectorForCreateAndAmendQuote(state),
      vehicleDetails: vehicleDetailsSelectorForCreateAndAmendQuote(state),
      driverDetails: driverDetailsSelectorForCreateAndAmendQuote(state),
      coverDetails: { ...coverDetailsSelectorForCreateAndAmendQuote(state) },
      policyHolderDetails:
        policyHolderDetailsSelectorForCreateAndAmendQuote(state),
    },
  },
});
