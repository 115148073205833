import {
  GridRow,
  FlexCell,
  Flextable,
  Icon,
} from 'suncorp-styleguide-react-components';
import PropTypes from 'prop-types';
import { formatNumberIntoCurrency } from '../../../utils/formatters';

const CoverageSection = ({ coverDetails }) => {
  const { hirecar, roadsideAssist } = coverDetails.options;
  const hasRoadsideAssist = /Enthusiast/.test(roadsideAssist);
  const { agreedValue } = coverDetails.sumInsured;

  return (
    <GridRow spacing="between">
      <Flextable>
        <FlexCell className="sg-u-noPadding">
          <div className="sg-Type--heading">Coverage</div>
          <div className="sg-Type--regular sg-Type--muted  sg-u-marginTop--x2">
            <Flextable>
              <FlexCell className="sg-u-noPadding sg-Flextable-cell--alignTop">
                <Icon
                  color="correct"
                  name="tick"
                  size="small"
                  className="sg-u-marginRight"
                  style={{ marginTop: '2px' }}
                />
              </FlexCell>
              <FlexCell className="sg-u-noPadding sg-u-flexGrow">
                <div className="sg-u-marginBottom--x0point5">
                  AMOUNT COVERED
                </div>
                <div>{formatNumberIntoCurrency(agreedValue, 0)}</div>
              </FlexCell>
            </Flextable>
          </div>
          <div className="sg-Type--regular sg-Type--muted sg-u-marginTop--x2">
            <Flextable>
              <FlexCell className="sg-u-noPadding sg-Flextable-cell--alignTop">
                <Icon
                  color="correct"
                  name="tick"
                  size="small"
                  className="sg-u-marginRight"
                  style={{ marginTop: '2px' }}
                />
              </FlexCell>
              <FlexCell className="sg-u-noPadding sg-u-flexGrow">
                <div className="sg-u-marginBottom--x0point5">
                  OPTIONAL COVERS
                </div>
                {hasRoadsideAssist && <div>Shannons Roadside Assist</div>}
                {hirecar && (
                  <div>Hire car after an event for unlimited days</div>
                )}
                {!hasRoadsideAssist && !hirecar && (
                  <div>You have not selected any optional covers.</div>
                )}
              </FlexCell>
            </Flextable>
          </div>
        </FlexCell>
      </Flextable>
    </GridRow>
  );
};

CoverageSection.propTypes = {
  coverDetails: PropTypes.object,
};

export default CoverageSection;
