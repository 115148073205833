import { DRIVER_SUMMARY_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const driverSummaryPageAnalytics = {
  urlPattern: DRIVER_SUMMARY_PAGE,
  pageViewHandler: ({ dataLayer, store }) => {
    dataLayer.dataModel.numberOfAdditionalDrivers =
      store.driverDetails.additionalDrivers.length;
    updatePageNameWithProductInfo(
      {
        pageName: 'driver_details_additionaldriver',
        flowType: 'quote',
      },
      store,
    );
  },
  bindEventHandlers: [
    {
      eventName: 'click',
      selector: '#addDriverButton',
      eventHandler: ({ dataLayer, webAnalytics }) => {
        dataLayer.dataModel.intraPageElement = {
          actionName: 'driver_details',
          actionValue: 'add-additional-driver',
        };
        webAnalytics.publish('motor-quote | page-element-click', dataLayer);
      },
    },
  ],
};

export default driverSummaryPageAnalytics;
