import { ENTHUSIAST_DRIVER_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const enthusiastDriverPageAnalytics = {
  urlPattern: ENTHUSIAST_DRIVER_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      { pageName: 'enthusiastdriver', flowType: 'quote' },
      store,
    );
  },
};

export default enthusiastDriverPageAnalytics;
