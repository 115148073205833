export const PAYMENT_FREQUENCY = {
  ANNUAL: 'Annual',
  MONTHLY: 'Monthly',
};

export const PAYMENT_STATUS = {
  SUCCESS: 'Success',
  PAYMENT_DECLINED: 'Payment Declined',
  INVALID_CARD_DETAILS: 'Invalid Card Details',
  UNEXPECTED_ERROR: 'Unexpected Error',
  PAY_LATER: 'Pay Later',
};

// No Claims Discount
export const FORTYFIVE_PERCENT_NO_CLAIM_DISCOUNT = '45% No Claim Discount';
export const SIXTYFIVE_PERCENT_NO_CLAIM_DISCOUNT = '65% No Claim Discount';
export const PROTECTED_NO_CLAIM_DISCOUNT = `${SIXTYFIVE_PERCENT_NO_CLAIM_DISCOUNT} Protected`;

// Parking
export const PARKING_TYPE_GARAGE = 'Garage';
export const PARKING_TYPE_ON_STREET = 'On the street';
export const PARKING_TYPE_ON_PROPERTY = 'On your property';
export const PARKING_TYPE_CARPORT = 'Carport';

// export const PAYMENT_REQUEST_MODE = {
//   NEW: 'New Request',
//   DECLINED: 'Payment Declined',
//   INVALID_CARD: 'Invalid Card Details',
//   INVALID_BSB: 'Invalid BSB',
//   ERROR: 'Unexpected Error',
//   INVALID_BANK: 'Invalid Bank Account Details',
// };
