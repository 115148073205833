import { sessionDetails } from './sessionDetails';
import { quoteDetails } from './quoteDetails';
import { vehicleDetails } from './vehicleDetails';
import testAndTarget from './testAndTarget';
import { configurationData } from './configurationData';
import { addressSearchInfo } from './addressSearchInfo';
import { policyHolderDetails } from './policyHolderDetails';
import { jeopardyDetails } from './jeopardyDetails';
import { enthusiastDriver } from './enthusiastDriver';
import { driverDetails } from './driverDetails';
import { vehicleSearchInfo } from './vehicleSearchInfo';
import { coverDetails } from './coverDetails';

const combinedModels = {
  sessionDetails,
  quoteDetails,
  vehicleDetails,
  testAndTarget,
  configurationData,
  addressSearchInfo,
  policyHolderDetails,
  jeopardyDetails,
  enthusiastDriver,
  driverDetails,
  vehicleSearchInfo,
  coverDetails,
};

export default combinedModels;
