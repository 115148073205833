import { RETRIEVE_QUOTE_PAGE } from '../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../analytics/dataLayerUtils';

export const retrieveQuotePageAnalytics = {
  urlPattern: RETRIEVE_QUOTE_PAGE,
  pageViewHandler: ({ store }) => {
    const page = 'quote_retrieve';
    updatePageNameWithProductInfo({ pageName: page, flowType: 'quote' }, store);
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    const todaysDate = new Date();
    const mainDriverDOB = new Date(store.driverDetails.mainDriver.dob);
    const mainDriverAge = Math.floor(
      (todaysDate - mainDriverDOB) / (1000 * 60 * 60 * 24 * 365), // 1000ms 60secs 60mins 24hrs 365days
    );

    dataLayer.dataModel.quoteSourceType = store.quoteDetails
      ?.isRetrievedQuoteByEmail
      ? 'Quote_Retrieved_By_Email'
      : 'Quote_Retrieved_By_Screen';
    dataLayer.dataModel.vehicleYearManufacture =
      store.vehicleSearchInfo?.searchResults?.year;
    dataLayer.dataModel.vehicleMake =
      store.vehicleSearchInfo?.searchResults?.make;
    dataLayer.dataModel.vehicleModel =
      store.vehicleSearchInfo?.searchResults?.model;
    dataLayer.dataModel.mainDriver.age = mainDriverAge;
    dataLayer.dataModel.mainDriver.gender =
      store.driverDetails?.mainDriver?.gender;
    dataLayer.dataModel.riskPostcode =
      store.addressSearchInfo?.addressInfo?.matchedAddress?.postcode;
    dataLayer.dataModel.quoteNumber = store.quoteDetails?.quoteNumber;
    dataLayer.dataModel.mainDriver.hasClaimOccurrences =
      store.driverDetails?.mainDriver?.hasClaimOccurrences === 'true'
        ? 'Yes'
        : 'No';
  },
};
