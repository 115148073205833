import { HOME_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const homePageAnalytics = {
  urlPattern: HOME_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      { pageName: 'quote_started', flowType: 'quote' },
      store,
    );
  },
};

export default homePageAnalytics;
