import { POLICY_HOLDER_CRIMINAL_STATUS_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const policyHolderCriminalStatusPageAnalytics = {
  urlPattern: POLICY_HOLDER_CRIMINAL_STATUS_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'policy_criminalact',
        flowType: 'quote',
      },
      store,
    );
  },
};

export default policyHolderCriminalStatusPageAnalytics;
