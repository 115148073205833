import { differenceInYears } from 'date-fns';
import { MAIN_DRIVER_DETAILS_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const mainDriverDetailsPageAnalytics = {
  urlPattern: MAIN_DRIVER_DETAILS_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'driver_details_demographics',
        flowType: 'quote',
      },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    dataLayer.dataModel.mainDriver = {
      ...dataLayer.dataModel.mainDriver,
      age: differenceInYears(
        new Date(),
        new Date(store.driverDetails?.mainDriver?.dob),
      ),
      gender: store.driverDetails?.mainDriver?.gender,
    };
  },
};

export default mainDriverDetailsPageAnalytics;
