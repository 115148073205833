import { MAIN_DRIVER_EXTRA_DETAILS_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const mainDriverExtraDetailsPageAnalytics = {
  urlPattern: MAIN_DRIVER_EXTRA_DETAILS_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      { pageName: 'driver_details_main', flowType: 'buy' },
      store,
    );
  },
};

export default mainDriverExtraDetailsPageAnalytics;
