import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export function useSetExperienceFunction() {
  const dispatch = useDispatch();
  const { setExperience } = dispatch.testAndTarget;

  useEffect(() => {
    window.experience = { setExperience };
  });
}
