import { FlexCell, Flextable } from 'suncorp-styleguide-react-components';
import PropTypes from 'prop-types';
import { getDisplayPremium } from '../../../utils/quoteSchedulePageUtils';
import { PAYMENT_FREQUENCY } from '../../../constants/productConstants';

const PremiumAndFrequencySection = ({
  annualPremium,
  monthlyPremium,
  paymentFrequency,
}) => (
  <>
    <Flextable
      style={{ fontSize: '48px' }}
      className="sg-Type--bold sg-u-block sg-u-marginTop sg-u-noMargin"
    >
      <FlexCell className="sg-u-noPadding sg-u-flexGrow sg-u-flex--centre">
        {getDisplayPremium(paymentFrequency, annualPremium, monthlyPremium)}
        &nbsp;
        <span
          style={{ marginLeft: '-8px' }}
          className="sg-u-marginTop--x4 sg-Type--regular sg-Type--size15"
        >
          {paymentFrequency === PAYMENT_FREQUENCY.ANNUAL ? 'yearly' : 'monthly'}
        </span>
      </FlexCell>
    </Flextable>
    <Flextable>
      <FlexCell
        style={{ marginTop: '-5px' }}
        className="sg-u-noPadding sg-u-flexGrow sg-u-flex--centre sg-Type--size13"
      >
        incl GST and gov’t charges
      </FlexCell>
    </Flextable>
  </>
);

PremiumAndFrequencySection.propTypes = {
  annualPremium: PropTypes.number,
  monthlyPremium: PropTypes.number,
  paymentFrequency: PropTypes.string,
};

export default PremiumAndFrequencySection;
