export const MODERN_VEHICLE_MAX_AGE = 15;
export const EMERGING_CLASSIC_VEHICLE_MAX_AGE = 34;
export const CAP_AMOUNT_COVERED = 150000;
export const MIN_SI_CLASSIC_VEHICLE = 1000;
export const MAX_SI_CLASSIC_VEHICLE = 10000000;
export const MOTOR_VEHICLE_COVERAGE_MAX_AGE = 34;
export const RACING_OPTION_START_YEAR_MANUFACTURED = 1961;
export const MOTOR_VEHICLE_COMPREHENSIVE_COVER = 'Motor Vehicle Comprehensive';
export const MOTOR_VEHICLE_CLASSIC_COVER = 'Classic Motor Comprehensive';
export const MOTOR_VEHICLE_USAGE_PRIVATE = 'Private';
export const MOTOR_VEHICLE_USAGE_BUSINESS = 'Business';
export const MOTOR_VEHICLE_USAGE_HIRE = 'Wedding hire';
export const VEHICLE_USAGE_FREQUENCY_NOT_DRIVEN_LAID_UP =
  'Not driven / Laid up';

export const VEHICLE_USAGE_FREQUENCY_LIMITED_USAGE = 'Limited use';
export const VEHICLE_USAGE_FREQUENCY_EXTREME_LIMITED_USE =
  'Extreme limited use';
export const VEHICLE_USAGE_FREQUENCY_CLUB_PLATE_USE = 'Club plate use';
export const VEHICLE_USAGE_FREQUENCY_REGULAR_DRIVER = 'Regular use';
