import { VEHICLE_REGISTRATION_PAGE } from '../../../constants/urlConstants';
import { updatePageNameWithProductInfo } from '../../../analytics/dataLayerUtils';

export const vehicleRegistrationPageAnalytics = {
  urlPattern: VEHICLE_REGISTRATION_PAGE,
  pageViewHandler: ({ store }) => {
    updatePageNameWithProductInfo(
      {
        pageName: 'car_details_registrationstatus',
        flowType: 'quote',
      },
      store,
    );
  },
  onCaptureHandler: ({ dataLayer, store }) => {
    dataLayer.dataModel.isVehicleRegistered =
      store.vehicleDetails?.isVehicleRegistered === 'true' ? 'Yes' : 'No';
  },
};

export default vehicleRegistrationPageAnalytics;
