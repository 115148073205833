import quoteFlowRouteConfig from './quoteFlowRoutes';
import buyFlowRouteConfig from './buyFlowRoutes';
import globalFlowRouteConfig from './globalFlowRoutes';

const routeConfig = {
  ...quoteFlowRouteConfig,
  ...buyFlowRouteConfig,
  ...globalFlowRouteConfig,
};

export default routeConfig;
