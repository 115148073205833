export const driverDetailsInitialState = {
  mainDriver: {
    hasClaimOccurrences: undefined,
    hasSuspensionOccurrences: undefined,
    dob: '',
    gender: '',
    title: undefined,
    firstname: undefined,
    surname: undefined,
    claimOccurrences: [],
    suspensionOccurrences: [],
    isPolicyHolder: true,
    excessClaims: undefined,
    numberOfLicenceOccurrences: undefined,
  },
  additionalDrivers: [],
  additionalDriverCurrentId: 0,
};

export const driverDetails = {
  state: driverDetailsInitialState,
  reducers: {
    setDriverDetails(state, payload) {
      return { ...state, ...payload };
    },
    setMainDriverDOB(state, payload) {
      return { ...state, mainDriver: { ...state.mainDriver, dob: payload } };
    },
    setMainDriverGender(state, payload) {
      return { ...state, mainDriver: { ...state.mainDriver, gender: payload } };
    },
    setMainDriverHasClaimOccurrences(state, payload) {
      return {
        ...state,
        mainDriver: { ...state.mainDriver, hasClaimOccurrences: payload },
      };
    },
    setMainDriverClaimOccurrences(state, payload) {
      return {
        ...state,
        mainDriver: { ...state.mainDriver, claimOccurrences: payload },
      };
    },
    setMainDriverHasSuspensionOccurrences(state, payload) {
      return {
        ...state,
        mainDriver: { ...state.mainDriver, hasSuspensionOccurrences: payload },
      };
    },
    setMainDriverSuspensionOccurrences(state, payload) {
      return {
        ...state,
        mainDriver: { ...state.mainDriver, suspensionOccurrences: payload },
      };
    },
    setMainDriverNumberOfLicenceOccurrences(state, payload) {
      return {
        ...state,
        mainDriver: {
          ...state.mainDriver,
          numberOfLicenceOccurrences: payload,
        },
      };
    },
    setAdditionalDriverDOB(state, payload, id) {
      const driverInfo = state.additionalDrivers.find(
        (driver) => driver.id === id,
      );
      return {
        ...state,
        additionalDrivers: [
          ...state.additionalDrivers.filter((driver) => driver !== driverInfo),
          { ...driverInfo, id, dob: payload },
        ].sort((a, b) => a.id - b.id),
      };
    },
    setAdditionalDriverGender(state, payload, id) {
      const driverInfo = state.additionalDrivers.find(
        (driver) => driver.id === id,
      );
      return {
        ...state,
        additionalDrivers: [
          ...state.additionalDrivers.filter((driver) => driver !== driverInfo),
          { ...driverInfo, id, gender: payload },
        ].sort((a, b) => a.id - b.id),
      };
    },
    setAdditionalDriverDetails(state, payload, id) {
      const driverInfo = state.additionalDrivers.find(
        (driver) => driver.id === id,
      );
      return {
        ...state,
        additionalDrivers: [
          ...state.additionalDrivers.filter((driver) => driver !== driverInfo),
          { ...driverInfo, [payload.type]: payload.value },
        ].sort((a, b) => a.id - b.id),
      };
    },
    setAdditionalDriverCurrentId(state, payload) {
      return { ...state, additionalDriverCurrentId: payload };
    },
    removeAdditionalDriver(state, id) {
      const driverInfo = state.additionalDrivers.find(
        (driver) => driver.id === id,
      );
      return {
        ...state,
        additionalDrivers: [
          ...state.additionalDrivers.filter((driver) => driver !== driverInfo),
        ].sort((a, b) => a.id - b.id),
      };
    },
    reMapAdditionalDriverId(state, idToBeUpdated, newId) {
      const driverInfo = state.additionalDrivers.find(
        (driver) => driver.id === idToBeUpdated,
      );
      return {
        ...state,
        additionalDrivers: [
          ...state.additionalDrivers.filter((driver) => driver !== driverInfo),
          { ...driverInfo, id: newId },
        ].sort((a, b) => a.id - b.id),
      };
    },
    setMainDriverFirstname(state, payload) {
      return {
        ...state,
        mainDriver: { ...state.mainDriver, firstname: payload },
      };
    },
    setMainDriverSurname(state, payload) {
      return {
        ...state,
        mainDriver: { ...state.mainDriver, surname: payload },
      };
    },
    setMainDriverTitle(state, payload) {
      return { ...state, mainDriver: { ...state.mainDriver, title: payload } };
    },
    setMainDriverIsPolicyHolder(state, payload) {
      return {
        ...state,
        mainDriver: { ...state.mainDriver, isPolicyHolder: payload },
      };
    },
    setMainDriverExcessClaims(state, payload) {
      return {
        ...state,
        mainDriver: { ...state.mainDriver, excessClaims: payload },
      };
    },
    updateDriverDetailsOnQuoteRetrieve(state, retrieveDriverDetails) {
      const { dateOfBirth, gender, firstName, lastName } =
        retrieveDriverDetails.mainDriver;
      const additionalDrivers = [];
      retrieveDriverDetails.additionalDrivers.forEach((item, id) => {
        additionalDrivers.push({
          id,
          dob: item.dateOfBirth,
          gender: item.gender,
          firstName: item.firstName,
          surname: item.lastName,
        });
      });
      return {
        ...state,
        mainDriver: {
          ...state.mainDriver,
          dob: dateOfBirth,
          gender,
          firstname: firstName,
          surname: lastName,
          isPolicyHolder: true,
          excessClaims: undefined,
        },
        additionalDrivers,
      };
    },
  },
};
